import HomeNavbar from "../../components/HomeNavbar/HomeNavbar";
import HomeFooter from "../../components/HomeFooter/HomeFooter";
import ExploreWidget from "../../components/ExploreWidget/ExploreWidget";

const ExplorePage = () => {
  return (
    <div>
      <HomeNavbar/>
      <main className="py-5">
        <ExploreWidget/>
      </main>
      <HomeFooter/>
    </div>
  );
}

ExplorePage.propTypes = {};
ExplorePage.defaultProps = {};

export default ExplorePage;