import {Button, Card, Col, Image, Row} from "react-bootstrap";

import "./ExploreWidget.css";
import Styles from "./ExploreWidget.module.css";

import IndustryContentEn from "../../assets/pages/explore/industry/content_en.json";

import {useState} from "react";
import ImageModalLayout from "./ImageModalLayout";

const IndustryLayout = () => {
  const [imageShow, setImageShow] = useState(false);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");

  const handleImageShow = (title, image) => {
    setTitle(title);
    setImage(image);
    setImageShow(true);
  }

  const handleClick = (link) => {
    window.open(link);
  };

  const handleImageClose = () => setImageShow(false);

  return (
    <Row className="g-5 industry">
      {IndustryContentEn.map((content, index) => (
        <Col key={index} xs={12} lg={6} xl={4}>
          <Card key={index} className={`${Styles.Card} shadow h-100`}>
            <div className="card-img-top-h-250-px">
              <Image src={content.image.src} className="card-img-top card-img-ratio bg-light" alt={content.title}/>
            </div>
            <Card.Body>
              <Card.Title><h5>{content.title}</h5></Card.Title>
              <Card.Text>{content.text}</Card.Text>
            </Card.Body>
            <Card.Footer className={`${Styles.BackgroundWhite} border-0 pb-3 pt-0`}>
              <Button onClick={() => handleImageShow(content.title, content.image)} variant="outline-dark"
                      className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill ml-p-1`}>
                <i className={`${Styles.BI} bi-eye`}/> View</Button>
              {content.link ? (
                <Button onClick={() => handleClick(content.link)} variant="outline-dark"
                        className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill ml-p-1`}>
                  <i className={`${Styles.BI} bi-github`}/> Github</Button>
              ): ""}
            </Card.Footer>
          </Card>
        </Col>
      ))}

      <ImageModalLayout imageShow={imageShow} imageClose={handleImageClose} title={title} image={image}/>
    </Row>
  );
}

IndustryLayout.propTypes = {};
IndustryLayout.defaultProps = {};

export default IndustryLayout;