import TopLayout from "./TopLayout";
import MiddleLayout from "./MiddleLayout";
import BottomLayout from "./BottomLayout";

import Styles from "./PricingWidget.module.css";

const PricingWidget = () => {
  return (
    <div>
      <TopLayout/>

      <div className={Styles.ThinColorBar}/>

      <MiddleLayout/>

      <BottomLayout/>
    </div>
  );
}

PricingWidget.propTypes = {};
PricingWidget.defaultProps = {};

export default PricingWidget;