import axios from "axios";
import sha1 from "crypto-js/sha1";

const USER_URL = process.env.REACT_APP_API_USER_URL;

export const activateUser = (activationId, userId) => {
  const url = USER_URL + "/activate";

  return axios
    .post(
      url,
      {
        activation_id: activationId,
        user_id: userId,
      },
      {
        withCredentials: false,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const createUser = (payload) => {
  const url = USER_URL + "/create";

  return axios
    .post(url, payload, {
      withCredentials: false,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getUser = () => {
  const url = USER_URL + "/get";

  return axios
    .post(
      url,
      {},
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateUserAddress = (payload) => {
  const url = USER_URL + "/address/update";

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getUserAddress = () => {
  const url = USER_URL + "/address/get";

  return axios
    .post(
      url,
      {},
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const forgetPassword = (email) => {
  const url = USER_URL + "/password/forget";

  return axios
    .post(
      url,
      {
        email: email,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const resetPassword = (token, new_password) => {
  const url = USER_URL + "/password/reset";

  return axios
    .post(
      url,
      {
        token: token,
        password: "sha1:" + sha1(new_password),
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updatePassword = (new_password, old_password) => {
  const url = USER_URL + "/password/update";

  return axios
    .post(
      url,
      {
        new_password: "sha1:" + sha1(new_password),
        old_password: "sha1:" + sha1(old_password),
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getUserPreference = () => {
  const url = USER_URL + "/preference/get";

  return axios
    .post(
      url,
      {},
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateUserPreference = (payload) => {
  const url = USER_URL + "/preference/update";

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const createKey = () => {
  const url = USER_URL + "/key/create";

  return axios
    .post(
      url,
      {},
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getKey = () => {
  const url = USER_URL + "/key/get";

  return axios
    .post(
      url,
      {},
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
