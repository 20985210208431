import { useState } from "react";
import {Trans, useTranslation} from "react-i18next";
import {Button, Card, Col, Container, Form, FormCheck, Image, Row, Spinner} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist";
import { useHistory } from "react-router-dom";
import sha1 from "crypto-js/sha1";

import ErrorDialog from "../../components/ErrorWidget/ErrorWidget";
import {createUser} from "../../api/user";
import { emailValidator, passwordValidator } from "../../utils/validator";

import HomeFooterTransparent from "../../components/HomeFooter/HomeFooterTransparent";

import "./SignupPage.css";
import Styles from "./SignupPage.module.css";
import WerenderLogo from "../../assets/img/brand/werender_logo.svg";

const SignupPage = () => {
  const { t, i18n } = useTranslation(["translation", "error", "signup"]);

  let history = useHistory();

  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [termPrivacy, setTermsPrivacy] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  const [country, setCountry] = useState(null);
  const [emailValidation, setEmailValidation] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [termsPrivacyRequired, setTermsPrivacyRequired] = useState(false);
  const [getNewsUpdate, setGetNewUpdate] = useState(false);

  const [recaptcha, setRecaptcha] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState({show: false});
  const [showPasswordChecklist, setShowPasswordCheckList] = useState(false);

  function recaptchaOnChange(value) {
    setRecaptcha(true);
  }

  const emailErrorClear = () => {
    setEmailRequired(false);
    setEmailValidation(false);
  };

  const passwordErrorClear = () => {
    setPasswordRequired(false);
  };

  const signup = () => {
    setLoadingButton(true);

    const emailError = emailValidator(email);
    const passwordError = passwordValidator(password);

    // Skip the process if the error is true
    if (emailError.isEmpty || emailError.isInvalid) {
      setEmailRequired(emailError.isEmpty);
      setPasswordRequired(passwordError.isEmpty);

      if (!emailError.isEmpty) {
        setEmailValidation(emailError.isInvalid);
      }

      if (!termPrivacy) {
        setTermsPrivacyRequired(true);
      }
      setLoadingButton(false);
      return;
    }

    if (!recaptcha) {
      setShowErrorMessage({
        show: true,
        title: t("error:title.default"),
        message: t("error:captcha.required")
      });
      setLoadingButton(false);
      return;
    }

    // Create new user.
    const payload = {
      address: {
        company: company,
        country: country,
        name: name,
      },
      email: email,
      password: "sha1:" + sha1(password),
      preference: {
        language: i18n.language,
        subscribe_to_mail: getNewsUpdate,
      },
    }
    createUser(payload).then(() => {
      // Redirect to registered page.
      history.push("/signin");
      setLoadingButton(false);
    }).catch(err => {
      setShowErrorMessage({
        show: true,
        title: t("error:title.default"),
        message: t("error:sign_up.default")
      });
      setLoadingButton(false);
    })
  };

  // TODO: Every nested items are aligned to center.
  return (
    <div>
      <ErrorDialog showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage}/>
      <div className="d-flex flex-column h-100">
        <div className={`${Styles.BackgroundMasthead} py-5`}>
          <main className="flex-shrink-0">
            <Container className="col-xxl-8 col-xl-10 px-4 py-4">
              <Row className="align-items-center g-lg-5 py-5">
                <Col md={8} lg={6} className="mx-auto">
                  <p className="lead">
                    <a
                      href="/"
                      className="d-flex align-items-center col-md-3 mb-md-0 text-dark text-decoration-none"
                    >
                      <Image src={WerenderLogo} height="30" className="me-2" />
                      WeRender
                    </a>
                  </p>
                  <Card className="p-5 shadow-lg">
                    <Form id="signup-form">
                      <legend>{t("signup:legend")}</legend>
                      <p className="small text-muted">
                        {t("signup:description")}
                      </p>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="email"
                          id="signup-email"
                          defaultValue={email}
                          placeholder="name@example.com"
                          style={{
                            border:
                              emailValidation || emailRequired
                                ? "2px solid red"
                                : "",
                          }}
                          onClick={emailErrorClear}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <Form.Label htmlFor="signup-email">
                          {t("email")}
                        </Form.Label>
                        {emailRequired ? (
                          <Form.Text
                            className="text-danger"
                            style={{ color: "red" }}
                          >
                            {t("error:email.required")}
                          </Form.Text>
                        ) : (
                          ""
                        )}
                        {emailValidation ? (
                          <Form.Text
                            className="text-danger"
                            style={{ color: "red" }}
                          >
                            {t("error:email.invalid")}
                          </Form.Text>
                        ) : (
                          ""
                        )}
                      </Form.Floating>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          id="signup-name"
                          placeholder="Name"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        <Form.Label htmlFor="signup-name">
                          {t("name")}
                        </Form.Label>
                      </Form.Floating>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          id="signup-company"
                          placeholder="Company"
                          onChange={(e) => {
                            setCompany(e.target.value);
                          }}
                        />
                        <Form.Label htmlFor="signup-company">
                          {t("company")}
                        </Form.Label>
                      </Form.Floating>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          id="signup-country"
                          placeholder="Country"
                          onChange={(e) => {
                            setCountry(e.target.value);
                          }}
                        />
                        <Form.Label htmlFor="signup-country">
                          {t("country")}
                        </Form.Label>
                      </Form.Floating>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="password"
                          id="signup-password"
                          defaultValue={password}
                          placeholder="Password"
                          style={{
                            border: passwordRequired ? "2px solid red" : "",
                          }}
                          onClick={passwordErrorClear}
                          onSelect={() => setShowPasswordCheckList(true)}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <Form.Label htmlFor="signup-password">
                          {t("password")}
                        </Form.Label>
                        <PasswordStrengthBar password={password} minLength={8} />
                        <PasswordChecklist style={{display: showPasswordChecklist ? "block" : "none"}}
                          rules={["minLength", "specialChar", "number", "capital"]}
                          minLength={8}
                          value={password}
                          onChange={(isValid) => {setIsValidPassword(isValid)}}
                        />
                      </Form.Floating>
                      <FormCheck className="form-check mb-1">
                        <FormCheck.Input
                          type="checkbox"
                          value="signup-news-update"
                          onChange={(e) => {
                            setGetNewUpdate(e.target.checked);
                          }}
                        />
                        <FormCheck.Label>
                          <small className="text-muted">
                            {t("signup:get_update")}
                          </small>
                        </FormCheck.Label>
                      </FormCheck>
                      <FormCheck className="form-check mb-3">
                        <FormCheck.Input
                          type="checkbox"
                          value="signup-agree"
                          style={{
                            outline: termsPrivacyRequired
                              ? "2px solid red"
                              : "",
                          }}
                          onChange={(e) => {
                            setTermsPrivacy(e.target.checked);
                            setTermsPrivacyRequired(!e.target.checked);
                          }}
                        />
                        <FormCheck.Label>
                          <small className="text-muted">
                            <Trans
                              i18nKey="signup:term_privacy"
                              components={{
                                1: <a href="/terms">terms</a>,
                                2: <a href="/privacy">privacy</a>,
                              }}
                            />
                          </small>
                        </FormCheck.Label>
                        {termsPrivacyRequired ? (
                          <div>
                            <Form.Text
                              className="text-danger"
                              style={{ color: "red" }}
                            >
                              {t("error:agreement.required")}
                            </Form.Text>
                          </div>
                        ) : (
                          ""
                        )}
                      </FormCheck>
                      <ReCAPTCHA
                        sitekey={
                          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
                        }
                        onChange={recaptchaOnChange}
                        className="mb-3"
                      />
                      <Button
                        onClick={signup}
                        className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonWerender} w-100 rounded-pill mx-auto`}
                        disabled={!(recaptcha && termPrivacy && isValidPassword) || loadingButton}>
                        {loadingButton ? <Spinner style={{marginRight: "5px"}} as="span" size="sm" role="status" animation="border" variant="light"/> : ""}{t("sign_up")}
                      </Button>
                      <hr className="my-4" />
                      <small className="text-muted align-items-center position-absolute start-50 translate-middle">
                        {t("signup:have_account")}{" "}
                        <a href="/signin">{t("sign_in")}</a>.
                      </small>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
          </main>
        </div>
      </div>

      <HomeFooterTransparent />
    </div>
  );
};

SignupPage.propTypes = {};
SignupPage.defaultProps = {};

export default SignupPage;
