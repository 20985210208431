import {Modal} from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";

const CodeModalLayout = (props) => {
  const {codeShow, codeClose, title, language, code} = props;

  return (
    <div>
      <Modal show={codeShow} onHide={codeClose} centered className="fade" size="lg">
        <Modal.Header closeButton>
          <Modal.Title><h5>{title}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <SyntaxHighlighter language={language} showLineNumbers wrapLines className="my-0">
            {code}
          </SyntaxHighlighter>
        </Modal.Body>
      </Modal>
    </div>
  );
}

CodeModalLayout.propTypes = {};
CodeModalLayout.defaultProps = {};

export default CodeModalLayout;