import {Card, Col, Container, Image, Nav, Row} from "react-bootstrap";
import {useTranslation, Trans} from "react-i18next";

import Styles from "./HomeWidget.module.css";

import PythonLogoOut from "../../assets/img/logos/py_logo_out.png";
import CSLogoOut from "../../assets/img/logos/cs_logo_out.png";
import JavascriptLogoOut from "../../assets/img/logos/js_logo_out.png";

const WerenderLayout = () => {
  const { t } = useTranslation(["home.info"]);

  return (
    <div className={Styles.BackgroundLight}>
      <Container className="py-5">
        <Col lg={8} className="mx-auto text-center pb-4">
          <h6 className="display-6 fw-normal">{t('home.info:title')}</h6>
          <p className="fs-6 text-muted">
            {t('home.info:description')}
          </p>
        </Col>
        <Row className="row-cols-1 row-cols-lg-2 row-cols-xl-3 g-5">
          <Col>
            <Card className={`${Styles.Card} h-100 overflow-hidden shadow-lg`}>
              <div className={`d-flex flex-column h-100 p-3 text-shadow-1 border-dark border-top ${Styles.ButtonCard}`}>
                <Nav as="ul" className="d-flex list-unstyled pb-0 mb-0">
                  <Nav.Item as="li" className="me-auto">
                    <h4 className="display-7 my-0 fw-normal">{t('home.info:cloud.title')}</h4>
                  </Nav.Item>
                  <Nav.Item as="li" className="d-flex align-items-center">
                    <i className={`h4 ${Styles.BI} bi-cloud-arrow-up mx-2`}/>
                    <i className={`h4 ${Styles.BI} bi-cloud-arrow-down mx-2`}/>
                  </Nav.Item>
                </Nav>
                <p className="mt-3">
                  {t('home.info:cloud.description')}
                </p>
              </div>
            </Card>
          </Col>
          <Col>
            <Card className={`${Styles.Card} h-100 overflow-hidden shadow-lg`}>
              <div className={`d-flex flex-column h-100 p-3 text-shadow-1 border-dark border-top ${Styles.ButtonCard}`}>
                <Nav as="ul" className="d-flex list-unstyled pb-0 mb-0">
                  <Nav.Item as="li" className="me-auto">
                    <h4 className="display-7 my-0 fw-normal">{t('home.info:scalable.title')}</h4>
                  </Nav.Item>
                  <Nav.Item as="li" className="d-flex align-items-center">
                    <i className={`h4 ${Styles.BI} bi-cpu mx-2`}/>
                    <i className={`h4 ${Styles.BI} bi-hdd-network mx-2`}/>
                  </Nav.Item>
                </Nav>
                <p className="mt-3">
                  {t('home.info:scalable.description')}
                </p>
              </div>
            </Card>
          </Col>
          <Col>
            <Card className={`${Styles.Card} h-100 overflow-hidden shadow-lg`}>
              <div className={`d-flex flex-column h-100 p-3 text-shadow-1 border-dark border-top ${Styles.ButtonCard}`}>
                <Nav as="ul" className="d-flex list-unstyled pb-0 mb-0">
                  <Nav.Item as="li" className="me-auto">
                    <h4 className="display-7 my-0 fw-normal">{t('home.info:easy.title')}</h4>
                  </Nav.Item>
                  <Nav.Item as="li" className="d-flex align-items-center">
                    <i className={`h4 ${Styles.BI} bi-code mx-2`}/>
                    <i className={`h4 ${Styles.BI} bi-speedometer2 mx-2`}/>
                  </Nav.Item>
                </Nav>
                <p className="mt-3">
                  {t('home.info:easy.description')}
                </p>
              </div>
            </Card>
          </Col>
          <Col>
            <Card className={`${Styles.Card} h-100 overflow-hidden shadow-lg`}>
              <div className={`d-flex flex-column h-100 p-3 text-shadow-1 border-dark border-top ${Styles.ButtonCard}`}>
                <Nav as="ul" className="d-flex list-unstyled pb-0 mb-0">
                  <Nav.Item as="li" className="me-auto">
                    <h4 className="display-7 my-0 fw-normal">{t('home.info:lightweight.title')}</h4>
                  </Nav.Item>
                  <Nav.Item as="li" className="d-flex align-items-center">
                    <i className={`h4 ${Styles.BI} bi-laptop mx-2`}/>
                    <i className={`h4 ${Styles.BI} bi-tablet mx-2`}/>
                  </Nav.Item>
                </Nav>
                <p className="mt-3">
                  <Trans i18nKey="home.info:lightweight.description"
                         components={{1: <a href="/docs">docs</a>}}/>
                </p>
              </div>
            </Card>
          </Col>
          <Col>
            <Card className={`${Styles.Card} h-100 overflow-hidden shadow-lg`}>
              <div className={`d-flex flex-column h-100 p-3 text-shadow-1 border-dark border-top ${Styles.ButtonCard}`}>
                <Nav as="ul" className="d-flex list-unstyled pb-0 mb-0">
                  <Nav.Item as="li" className="me-auto">
                    <h4 className="display-7 my-0 fw-normal">{t('home.info:web.title')}</h4>
                  </Nav.Item>
                  <Nav.Item as="li" className="d-flex align-items-center">
                    <i className={`h4 ${Styles.BI} bi-terminal mx-2`}/>
                    <i className={`h4 ${Styles.BI} bi-window mx-2`}/>
                  </Nav.Item>
                </Nav>
                <p className="mt-3">
                  <Trans i18nKey="home.info:web.description"
                         components={{1: <a href="https://werender.io/dashboard">Dashboard</a>}}/>
                </p>
              </div>
            </Card>
          </Col>
          <Col>
            <Card className={`${Styles.Card} h-100 overflow-hidden shadow-lg`}>
              <div className={`d-flex flex-column h-100 p-3 text-shadow-1 border-dark border-top ${Styles.ButtonCard}`}>
                <Nav as="ul" className="d-flex list-unstyled pb-0 mb-0">
                  <Nav.Item as="li" className="me-auto">
                    <h4 className="display-7 my-0 fw-normal">{t('home.info:language.title')}</h4>
                  </Nav.Item>
                  <Nav.Item as="li" className="d-flex align-items-center">
                    <Image src={PythonLogoOut} className="img-fluid px-2" width={40} alt="..."/>
                    <Image src={CSLogoOut} className="img-fluid px-2" width={40} alt="..."/>
                    <Image src={JavascriptLogoOut} className="img-fluid px-2" width={40} alt="..."/>
                  </Nav.Item>
                </Nav>
                <p className="mt-3">
                  <Trans i18nKey="home.info:language.description"
                         components={{1: <a href="/docs">docs</a>}}/>
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

WerenderLayout.propTypes = {};
WerenderLayout.defaultProps = {};

export default WerenderLayout;