import {Col, Container, Row, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import Styles from "./HomeWidget.module.css";
import "./HomeWidget.css";

const APILayout = () => {
  const { t } = useTranslation("home.api");

  return (
    <div className={Styles.BackgroundLight}>
      <Container className="py-5">
        <Row className="row-flex align-items-center g-5">
          <Col xs={12} lg={6}>
            <h1>{t('home.api:title')}</h1>
            <h6 className={`card-subtitle text-muted ${Styles.LineHeight}`}>
              {t('home.api:description')}
            </h6>
            <div className="py-3">
              <h5>{t('home.api:subtitle')}</h5>
              <ol>
                <li>
                  {t('home.api:body.authenticate')}
                </li>
                <li>
                  {t('home.api:body.api')}
                </li>
                <ul>
                  <li>
                    {t('home.api:body.asset')}
                  </li>
                  <li>
                    {t('home.api:body.scene')}
                  </li>
                  <li>
                    {t('home.api:body.render')}
                  </li>
                </ul>
                <li>
                  {t('home.api:body.result')}
                </li>
                <ul>
                  <li>
                    {t('home.api:body.share')}
                  </li>
                  <li>
                    {t('home.api:body.download')}
                  </li>
                </ul>
                <li>
                  {t('home.api:body.monitor')}
                </li>
              </ol>
            </div>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <Button as="a" href="/docs" className={`${Styles.ButtonOutlineWerender} rounded-pill min-w-50 m-auto`}>
                  {t('home.api:docs')}</Button>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="p-3 card thumbnail bg-light">
              <iframe height="315" src="https://www.youtube.com/embed/snRO-gMVZfM"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen/>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

APILayout.propTypes = {};
APILayout.defaultProps = {};

export default APILayout;
