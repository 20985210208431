const KEY = 'werender.io/v1/render';

const KB_BY_BYTES = 1000;
const MB_BY_BYTES = 1000000;
const GB_BY_BYTES = 1000000000;

export const parseRenderFromMetadata = (metadata) => {
  let renderId = '';
  if (metadata) {
    if (metadata.data) {
      if (metadata.data[KEY]) {
        renderId = metadata.data[KEY];
      }
    } else if (metadata[KEY]) {
      renderId = metadata[KEY];
    }
  }
  return renderId;
};

export const paginationOrderConverter = (order) => {
  if (order.toUpperCase() === "ASC") {
    return "ascending";
  }
  return "descending";
}

export const shareIdHeaderParser = (shared_id) => {
  if (shared_id) {
    return {
      withCredentials: false,
      headers: {
        "X-WR-Storage-Node-Share-Id": shared_id,
      }
    };
  }

  return {
    withCredentials: true
  };
}

const round = (num) => {
  return num.toFixed(2);
}

export const parseStorageSize = (size) => {
  if (size >= MB_BY_BYTES && size < GB_BY_BYTES) {
    return round(size/MB_BY_BYTES) + ' MB';
  } else if (size >= GB_BY_BYTES) {
    return round(size/GB_BY_BYTES) + ' GB';
  } else if (size < MB_BY_BYTES) {
    return round(size/KB_BY_BYTES) + ' KB';
  } else {
    return size;
  }
}
