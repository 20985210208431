import {Container, Nav, Tab} from "react-bootstrap";
import {useEffect, useState} from "react";

import FeatureLayout from "./FeatureLayout";
import TopLayout from "./TopLayout";
import UseCaseLayout from "./UseCaseLayout";
import IndustryLayout from "./IndustryLayout";

import Styles from "./ExploreWidget.module.css";
import {useLocation} from "react-router-dom";

const ExploreWidget = () => {
  const location = useLocation();

  const [tabContent, setTabContent] = useState("");

  useEffect(() => {
    switch (location.hash) {
      case "#use-case":
        setTabContent("use-case");
        break;
      case "#by-industry":
        setTabContent("by-industry");
        break;
      case "#by-feature":
        setTabContent("by-feature");
        break;
      default:
        setTabContent("");
    }
  }, [location.hash]);

  return (
    <div>
      <TopLayout/>

      <div className={Styles.ThinColorBar}/>

      <Container className="py-5">
        <Tab.Container activeKey={tabContent ? tabContent : "by-industry"} transition onSelect={(e) => setTabContent(e)}>
          <Nav as="ul" variant="tabs" className="mb-3 justify-content-center">
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="use-case" role="tab" className={Styles.NavLinkTab}>By Use Case</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="by-industry" role="tab" className={Styles.NavLinkTab}>By Industry</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="by-feature" role="tab" className={Styles.NavLinkTab}>By Feature</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="by-industry">
              <IndustryLayout/>
            </Tab.Pane>
            <Tab.Pane eventKey="use-case">
              <UseCaseLayout/>
            </Tab.Pane>
            <Tab.Pane eventKey="by-feature">
              <FeatureLayout/>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </div>
  );
}

ExploreWidget.propTypes = {};
ExploreWidget.defaultProps = {};

export default ExploreWidget;