import HomeFooter from "../../components/HomeFooter/HomeFooter.js";
import HomeNavbar from "../../components/HomeNavbar/HomeNavbar.js";
import HomeWidget from "../../components/HomeWidget/HomeWidget.js";

const HomePage = (props) => {
  return (
    <div id="home-page">
      <HomeNavbar />
      <main className="py-5">
        <HomeWidget />
      </main>
      <HomeFooter />
    </div>
  );
};

HomePage.propTypes = {};
HomePage.defaultProps = {};

export default HomePage;
