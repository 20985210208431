import { Col, Container, Row } from "react-bootstrap";

import HomeNavbar from "../../components/HomeNavbar/HomeNavbar.js";
import HomeFooter from "../../components/HomeFooter/HomeFooter";

const NotFoundPage = () => {
  return (
    <div>
      <HomeNavbar />
      <main className="py-5">
        <Container>
          <Row className="align-items-center">
            <Col>
              <div className="text-center">
                <h3>Not Found</h3>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <HomeFooter />
    </div>
  );
};

NotFoundPage.propTypes = {};
NotFoundPage.defaultProps = {};

export default NotFoundPage;
