import { useState } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";

import ImageModalLayout from "./ImageModalLayout";
import CodeModalLayout from "./CodeModalLayout";
import VideoModalLayout from "./VideoModalLayout";
import ModelModalLayout from "./ModelModalLayout";

import "./ExploreWidget.css";
import Styles from "./ExploreWidget.module.css";

import UseCaseContentEn from "../../assets/pages/explore/usecase/content_en.json";

const UseCaseLayout = () => {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [code, setCode] = useState("");
  const [model, setModel] = useState("");

  const [codeShow, setCodeShow] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [modelShow, setModelShow] = useState(false);

  const handleCodeShow = (title, codeSrc) => {
    fetch(codeSrc)
      .then((res) => res.text())
      .then((text) => setCode(text))
      .catch((err) => alert(err));

    setTitle(title);
    setCodeShow(true);
  };
  const handleCodeClose = () => setCodeShow(false);

  const handleImageShow = (title, image) => {
    setTitle(title);
    setImage(image);
    setImageShow(true);
  };
  const handleImageClose = () => setImageShow(false);

  const handleVideoShow = (title, video) => {
    setTitle(title);
    setVideo(video);
    setVideoShow(true);
  };
  const handleVideoClose = () => setVideoShow(false);

  const handleModelShow = (title, model) => {
    setTitle(title);
    setModel(model);
    setModelShow(true);
  };
  const handleModelClose = () => setModelShow(false);

  const handleClick = (link) => {
    window.open(link);
  };

  const embedLink = (content) => {
    return `${window.location.origin}/model/preview?name=${content.title}&src=${content.model.src}`;
  };

  return (
    <Row className="g-5 use-case industry">
      {UseCaseContentEn.map((content, index) => (
        <Col key={index} xs={12} lg={6} xl={4}>
          <Card className={`${Styles.Card} shadow h-100`}>
            <div className="card-img-top-h-250-px">
              {content.video ? (
                <video
                  src={content.video.src}
                  controls
                  autoPlay
                  loop
                  className="card-img-top card-img-ratio bg-light"
                />
              ) : content.image ? (
                <Image
                  src={content.image.src}
                  className="card-img-top card-img-ratio bg-light"
                  alt={content.title}
                />
              ) : (
                <iframe
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  height="100%"
                  width="100%"
                  title={content.title}
                  src={embedLink(content)}
                />
              )}
              {content.after_beta ? (
                <div className="top-left-label">
                  <em>After βeta</em>
                </div>
              ) : (
                ""
              )}
            </div>
            <Card.Body>
              <Card.Title>
                <h5>{content.title}</h5>
              </Card.Title>
              <Card.Subtitle className="text-muted">
                <h6>{content.subtitle}</h6>
              </Card.Subtitle>
              <Card.Text>{content.text}</Card.Text>
            </Card.Body>
            <Card.Footer
              className={`${Styles.BackgroundWhite} border-0 pb-3 pt-0`}
            >
              {content.video ? (
                <Button
                  onClick={() => handleVideoShow(content.title, content.video)}
                  variant="outline-dark"
                  className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill`}
                >
                  <i className={`${Styles.BI} bi-eye`} /> View
                </Button>
              ) : content.image ? (
                <Button
                  onClick={() => handleImageShow(content.title, content.image)}
                  variant="outline-dark"
                  className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill`}
                >
                  <i className={`${Styles.BI} bi-eye`} /> View
                </Button>
              ) : (
                <Button
                  onClick={() => handleModelShow(content.title, content.model)}
                  variant="outline-dark"
                  className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill`}
                >
                  <i className={`${Styles.BI} bi-eye`} /> View
                </Button>
              )}
              {content.code_src ? (
                <Button
                  onClick={() =>
                    handleCodeShow(content.title, content.code_src)
                  }
                  variant="outline-dark"
                  className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill ml-p-1`}
                >
                  <i className={`${Styles.BI} bi-code`} /> Code
                </Button>
              ) : (
                ""
              )}
              {content.link ? (
                <Button
                  onClick={() => handleClick(content.link)}
                  variant="outline-dark"
                  className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill ml-p-1`}
                >
                  <i className={`${Styles.BI} bi-github`} /> Github
                </Button>
              ) : (
                ""
              )}
            </Card.Footer>
          </Card>
        </Col>
      ))}

      <ImageModalLayout
        imageShow={imageShow}
        imageClose={handleImageClose}
        title={title}
        image={image}
      />
      <VideoModalLayout
        videoShow={videoShow}
        videoClose={handleVideoClose}
        title={title}
        video={video}
      />
      <CodeModalLayout
        codeShow={codeShow}
        codeClose={handleCodeClose}
        title={title}
        language="python"
        code={code}
      />
      <ModelModalLayout
        modelShow={modelShow}
        modelClose={handleModelClose}
        title={title}
        model={model}
      />
    </Row>
  );
};

UseCaseLayout.propTypes = {};
UseCaseLayout.defaultProps = {};

export default UseCaseLayout;
