import axios from "axios";
import { shareIdHeaderParser } from "../utils/parser";

const STORAGE_URL = process.env.REACT_APP_API_STORAGE_URL;

export const getStorage = () => {
  const url = STORAGE_URL + "/get";

  return axios
    .post(
      url,
      {},
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const createFolder = (path) => {
  const url = STORAGE_URL + "/folder/create";

  return axios
    .post(
      url,
      {
        path: path,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getFolder = (payload) => {
  const url = STORAGE_URL + "/folder/get";

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getFolderList = (payload) => {
  const url = STORAGE_URL + "/folder/list";

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const createFile = (payload) => {
  const url = STORAGE_URL + "/file/create";

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getFile = (payload, shareId) => {
  const url = STORAGE_URL + "/file/get";

  return axios
    .post(url, payload, shareIdHeaderParser(shareId))
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getFiles = (ids) => {
  const url = STORAGE_URL + "/files/get";

  return axios
    .post(url, ids, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return [];
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getFileBlob = (id, shareId) => {
  const url = STORAGE_URL + "/file/blob/get";

  return axios
    .post(
      url,
      {
        id: id,
      },
      shareIdHeaderParser(shareId)
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteAllFiles = (payload) => {
  const url = STORAGE_URL + "/files/delete";

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return [];
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteFolder = (id) => {
  const url = STORAGE_URL + "/folder/delete";

  return axios
    .post(
      url,
      { id: id },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const uploadChunk = (setLoaded, url, file, start, size) => {
  const data = file.slice(start, start + size);
  let last_chunk_sent = 0;

  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      onUploadProgress: (progressEvent) => {
        const chunk_sent = progressEvent.loaded - last_chunk_sent;
        last_chunk_sent = progressEvent.loaded;

        setLoaded({ chunk_sent: chunk_sent, file_size: file.size });
      },
    })
    .then((res) => {
      if (res.data) {
        return {
          data: res.data,
          start: start,
          total: start + size,
          size: size,
        };
      }
      return {
        start: start,
        total: start + size,
        size: size,
      };
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const finalizeFile = (id) => {
  const url = STORAGE_URL + "/file/finalize";

  return axios
    .post(
      url,
      { id: id },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getStorageUsage = () => {
  const url = STORAGE_URL + "/usage/get";

  return axios
    .post(
      url,
      {},
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const moveNode = (node_id, new_parent_id) => {
  const url = STORAGE_URL + "/node/reparent";

  return axios
    .post(
      url,
      {
        id: node_id,
        parent_id: new_parent_id,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getNodeThumbnail = (id, shareId) => {
  const url = STORAGE_URL + "/node/thumbnail/get";

  return axios
    .post(
      url,
      {
        storage_node_id: id,
      },
      shareIdHeaderParser(shareId)
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return [];
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getNodeThumbnails = (ids) => {
  const url = STORAGE_URL + "/node/thumbnails/get";

  return axios
    .post(
      url,
      {
        storage_node_ids: ids,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return [];
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const setNodeThumbnail = (id, imageContentType, imageData) => {
  const url = STORAGE_URL + "/node/thumbnail/set";

  return axios
    .post(
      url,
      {
        image_content_type: imageContentType,
        image_data: imageData,
        storage_node_id: id,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      return true;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getStorageActivity = (created_after, deleted_after) => {
  const url = STORAGE_URL + "/activity/get";

  return axios
    .post(
      url,
      {
        created_after: created_after,
        deleted_after: deleted_after,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return [];
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getNodePaginate = (payload) => {
  const url = STORAGE_URL + "/node/paginate";

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const createNodeShare = (nodeId) => {
  const url = STORAGE_URL + "/node/share/create";

  let payload = {
    storage_node_id: nodeId,
  };

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getNodeShare = (shareId) => {
  const url = STORAGE_URL + "/node/share/get";

  let payload = {
    id: shareId,
  };

  return axios
    .post(url, payload, {
      withCredentials: false,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const listNodeShare = (payload) => {
  const url = STORAGE_URL + "/node/share/list";

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteNodeShare = (shareId) => {
  const url = STORAGE_URL + "/node/share/delete";

  return axios
    .post(
      url,
      { id: shareId },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const renameNode = (id, name) => {
  const url = STORAGE_URL + "/node/rename";

  return axios
    .post(
      url,
      {
        id: id,
        name: name,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
