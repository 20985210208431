export const childPaths = (path) => {
  // Create path for each parent level.
  let childPaths = [{ name: "Home", path: "/" }];
  if (path !== "/") {
    let pathArr = path.substring(1).split("/");
    for (let i = 0; i < pathArr.length; i++) {
      let childPath = "/" + pathArr.slice(0, i + 1).join("/");
      childPaths.push({ name: pathArr[i], path: childPath });
    }
  }

  return childPaths;
}