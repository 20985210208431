import axios from "axios";

const STRIPE_URL = process.env.REACT_APP_API_STRIPE_URL

export const listPayment = (payload) => {
  const url = STRIPE_URL + "/payment/list";

  return axios.post(url, payload, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  }).catch(err => {
    return Promise.reject(err);
  });
}

export const createCustomerPortal = () => {
  const url = STRIPE_URL + "/customer/portal/create";

  return axios.post(url, {}, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  }).catch(err => {
    return Promise.reject(err);
  });
}