import {deleteAllFiles, deleteFolder, getFiles, getFolder, getStorageActivity, listNodeShare} from "../api/storage";

// Backend allows to delete up to 100 files from single request.
const DELETE_FILES_BATCH_SIZE = 100;

export const deleteFiles = (payload) => {
  return getFolder(payload)
    .then(async (res) => {
      if (Array.isArray(res.files)) {
        let fileIds = [];
        res.files.map((value) => fileIds.push(value.id));

        // Wait the process to be finish.
        await deleteAllFiles(fileIds).catch((err) => {
          return Promise.reject(err);
        });

        // Re-request to delete the files if the total node count more than 100.
        if (res.page.total_item_count > DELETE_FILES_BATCH_SIZE) {
          return deleteFiles({
            id: res.id,
            limit: DELETE_FILES_BATCH_SIZE,
          });
        }
      }

      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteFoldersAndFiles = (payload) => {
  return getFolder(payload)
    .then(async (res) => {
      if (Array.isArray(res.files)) {
        // Wait the deleting files process to be finish.
        await deleteFiles(payload).catch((err) => {
          return Promise.reject(err);
        });
      }

      if (Array.isArray(res.folders)) {
        // eslint-disable-next-line react-hooks/exhaustive-deps,no-unused-vars
        for await (const r of res.folders.map((value) =>
          deleteFoldersAndFiles({
            id: value.id,
            limit: DELETE_FILES_BATCH_SIZE,
          })
        )) {}
      }

      if (res.page.total_item_count > DELETE_FILES_BATCH_SIZE) {
        return await deleteFoldersAndFiles({
          id: res.id,
          limit: DELETE_FILES_BATCH_SIZE,
        }).catch((err) => {
          return Promise.reject(err);
        });
      }

      if (res.name === "/") {
        return {};
      }

      return deleteFolder(res.id);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getStorageActivities = () => {
  let storage_activities = {
    last_day_size: 0,
    last_week_size: 0,
    last_month_size: 0
  };

  const last_day = new Date();
  last_day.setDate(last_day.getDate() - 1);
  const last_day_in_seconds = Math.floor(last_day.getTime() / 1000);

  return getStorageActivity(last_day_in_seconds, last_day_in_seconds).then(async res => {
    if (res) {
      storage_activities.last_day_size = res.created_size;
    }

    const last_week = new Date();
    last_week.setDate(last_week.getDate() - 7);
    const last_week_in_seconds = Math.floor(last_week.getTime() / 1000);

    const last_month = new Date();
    last_month.setDate(last_month.getDate() - 30);
    const last_month_in_seconds = Math.floor(last_month.getTime() / 1000);

    const last_week_size = await getStorageActivity(last_week_in_seconds, last_week_in_seconds);
    const last_month_size = await getStorageActivity(last_month_in_seconds, last_month_in_seconds);

    if (last_week_size) {
      storage_activities.last_week_size = last_week_size.created_size;
    }

    if (last_month_size) {
      storage_activities.last_month_size = last_month_size.created_size;
    }

    return storage_activities;
  }).catch(err => {
    return Promise.reject(err);
  });
}

export const getFileByShared = (payload) => {
  return listNodeShare(payload).then(shared => {
    const files = shared.data ? shared.data : [];
    let fileIds = [];
    files.forEach((file) => {
      fileIds.push(file.storage_node_id);
    });

    return getFiles(fileIds).then(res => {
      return {
        data: res,
        page: {
          item_count: shared.page.item_count
        }
      };
    }).catch(err => {
      return Promise.reject(err);
    });
  }).catch(err => {
    return Promise.reject(err);
  });
}