import {getRenderList} from "../api/render";

export const getNextRenderPage = (payload, result) => {
  return getRenderList(payload).then(res => {
    if (res.data) {
      result.push(...res.data);
    }

    const new_offset = payload.pagination_options.offset + payload.pagination_options.size;
    if (res.page.item_count > new_offset) {
      const next_page = {
        pagination_options: {
          offset: new_offset,
          size: payload.pagination_options.size
        },
        created_after: payload.created_after,
      };

      return getNextRenderPage(next_page, result);
    }

    return result;
  }).catch(err => {
    return Promise.reject(err);
  });
}