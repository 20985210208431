import {Col, Container, Image, Row, Card} from "react-bootstrap";

import Styles from "./ExploreWidget.module.css";

import SimplerCar from "../../assets/img/renders/werender_shoes.png";


const TopLayout = () => {
  return (
    <div className={Styles.BackgroundTop}>
      <Container className="pb-5">
        <Row className="flex-lg-row align-items-center g-5">
          <Col lg={6}>
            <h1 className="display-5">Explore our solutions</h1>
            <p className="lead">
              WeRender provides a comprehensive suite of web services and solutions to run sophisticated and scalable
              rendering and other processing tasks to describe, visualize, transform, store and share your data through the internet and in the metaverse.
            </p>
            <p className={`text-dark`}>
              <em>— Note: <u>content in this section will be completed during the βeta phase</u></em>.
            </p>
          </Col>
          <Col xs={12} md={12} lg={6} className="justify-content-md-start">
            <Card bg="none" className="p-3">
              <Image src={SimplerCar} className="d-block mx-lg-auto rounded img-fluid shadow" alt="WeRender" loading="lazy"/>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

TopLayout.propTypes = {};
TopLayout.defaultProps = {};

export default TopLayout;