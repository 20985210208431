import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {useTranslation, Trans} from "react-i18next";

import "./HomeWidget.css";
import Styles from "./HomeWidget.module.css";

const PricingLayout = () => {
  const { t } = useTranslation(["translation", "home.pricing"]);

  return (
    <div className="pb-5">
      <Container className="py-5">
        <Row className="pricing">
          <Col xs={12} lg={6}>
            <Card className="h-100" border="0">
              <Card.Body className="px-0">
                <h5>{t('home.pricing:intro')}</h5>
                <h1 className="Copy__title">{t('home.pricing:title')}</h1>
                <Card.Subtitle as="h6" className={`mb-3 text-muted ${Styles.LineHeight}`}>
                  {t('home.pricing:subtitle')}
                </Card.Subtitle>
              </Card.Body>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <Button as="a" href="/pricing" className={`${Styles.ButtonWerender} rounded-pill min-w-25`}>
                  {t('pricing')} <i className={`${Styles.BI} bi-chevron-right`}/></Button>
                <Button as="a" href="mailto:sales@werender.io" variant="outline-dark" className={`${Styles.ButtonOutlineDark} rounded-pill min-w-25`}>
                  {t('contact_sales')} <i className={`${Styles.BI} bi-chevron-right`}/></Button>
              </div>
            </Card>
          </Col>
          <Col className="mt-sm-4">
            <Card className="h-100" border="0">
              <Card.Body className="px-0">
                <div className="feature-icon">
                  <h3><i className={`${Styles.BI} bi-currency-dollar`}/></h3>
                </div>
                <h5>{t('home.pricing:transparent.title')}</h5>
                <p>
                  <Trans i18nKey="home.pricing:transparent.description"
                         components={{small: <small/>, code: <code className="text-dark"/>}}/>
                </p>
              </Card.Body>
              <div>
                <Button as="a" href="/pricing#details" className={`${Styles.ButtonOutlineWerender} rounded-pill min-w-50 m-auto`}>
                  {t('home.pricing:pricing_detail')}</Button>
              </div>
            </Card>
          </Col>
          <Col className="mt-sm-4">
            <Card border="0" className="h-100">
              <Card.Body className="px-0">
                <div className="feature-icon">
                  <h3><i className={`${Styles.BI} bi-play-circle`}/></h3>
                </div>
                <h5>{t('home.pricing:starting.title')}</h5>
                <p>
                  <Trans i18nKey="home.pricing:starting.description"
                         components={{small: <small/>}}/>
                </p>
              </Card.Body>
              <div>
                <Button as="a" href="/signup" className={`${Styles.ButtonOutlineWerender} rounded-pill min-w-50 m-auto`}>
                  {t('home.pricing:get_started')}</Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

PricingLayout.propTypes = {};
PricingLayout.defaultProps = {};

export default PricingLayout;