import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Image,
  Row,
  Button,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import saveAs from "file-saver";
import { run as runHolder } from "holderjs/holder";

import {
  getFile,
  getFileBlob,
  getNodeShare,
  getNodeThumbnail,
} from "../../api/storage";
import { parseStorageSize } from "../../utils/parser";

import ThreeViewer from "../../components/ThreeViewer/ThreeViewer";

import "../../styles/Modal.css";
import "../../styles/styles.css";
import "./SharePage.css";

import WerenderLogo from "../../assets/img/brand/werender_logo.png";

const SharePage = (props) => {
  // Parse URL parameters.
  const { shareId } = useParams();

  // Define the state.
  const [file, setFile] = useState(null);

  // The "dataUrl" saves the direct URL to USD file.
  const [dataUrl, setDataUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const [showToast, setShowToast] = useState({
    show: false,
    src: "",
    title: "",
    message: "",
  });

  useEffect(() => {
    runHolder("holder-image");
  });

  useEffect(() => {
    getNodeShare(shareId)
      .then((res) => {
        getFile({ id: res.storage_node_id }, res.id).then((res) => {
          setFile(res);
          document.title = res.name;
          getFileBlob(res.id, shareId).then((r) => {
            setDataUrl(r.url);
          });
          getNodeThumbnail(res.id, shareId).then((res) => {
            setThumbnailUrl(res.url);
          });
        });
      })
      .catch((err) => {
        onShowToast(
          "holder.js/20x20?text=%20&bg=F12B00",
          "Failed",
          `${err.message}`
        );
      });
  }, [shareId]);

  const filePreviewHandler = () => {
    if (file && dataUrl) {
      if (file.content_type.includes("model")) {
        return (
          <ThreeViewer name={file.name} src={dataUrl} thumbnail={thumbnailUrl} />
        );
      }
      if (file.content_type.includes("video")) {
        return (
          <video src={dataUrl} poster={thumbnailUrl} controls autoPlay loop/>
        );
      }
      if (file.content_type.includes("image")) {
        return (
          <Image src={dataUrl} className="p-0" fluid thumbnail style={{background: `url(${thumbnailUrl}) no-repeat`}} />
        );
      }
    }

    if (thumbnailUrl) {
      return (
        <Image src={thumbnailUrl} fluid thumbnail />
      );
    }

    return (
      <p className="text-medium" style={{ color: "gray" }}>
        No Preview
      </p>
    );
  }

  const onShowToast = (src, title, message) => {
    setShowToast({
      show: true,
      src: src,
      title: title,
      message: message,
    });
  };

  const onDownload = () => {
    if (dataUrl) {
      saveAs(dataUrl, file.name);
    } else {
      onShowToast(
        "holder.js/20x20?text=%20&bg=F12B00",
        "Failed",
        "Failed to download the file."
      );
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="background-masthead py-5">
        <ToastContainer className="p-5" position="top-end">
          <Toast
            onClose={() =>
              setShowToast({
                show: false,
                src: "",
                title: "",
                message: "",
              })
            }
            show={showToast.show}
            animation
            autohide
            delay={3000}
            style={{ position: "relative", zIndex: "1" }}
          >
            <Toast.Header>
              <Image
                src={showToast.src}
                className="holder-image me-2"
                rounded
              />
              <strong className="me-auto">{showToast.title}</strong>
              <small>just now</small>
            </Toast.Header>
            <Toast.Body>{showToast.message}</Toast.Body>
          </Toast>
        </ToastContainer>

        <main className="flex-shrink-0">
          <Container className="col-xl-10 col-xxl-8 px-4 py-4">
            <Row className="align-items-center g-lg-5 py-5">
              <Col md={6} lg={6} className="mx-auto">
                <p className="lead">
                  <a
                    href="/"
                    className="d-flex align-items-center col-md-3 mb-md-0 text-dark text-decoration-none"
                  >
                    <Image src={WerenderLogo} height="30" className="me-2" />
                    WeRender
                  </a>
                </p>
                <Card className="p-5 shadow-lg text-center">
                  <legend className="text-small">
                    This is a WeRender publicly shared link for:
                  </legend>
                  <Col md={6} lg={6} className="mx-auto">
                    <Row
                      className="content-center text-color-gray"
                      style={{ wordBreak: "break-word" }}
                    >
                      {file ? file.name : ""}
                    </Row>
                    <Row className="content-center text-color-gray">
                      ({file ? parseStorageSize(file.size) : ""})
                    </Row>
                  </Col>

                  <Row className="align-items-center g-lg-4 py-4">
                    <Card
                      className="p-1 text-center"
                      style={{ backgroundColor: "#F3F3F300", minHeight: "256px", justifyContent: "center" }}
                    >
                      <Card.Body>
                        {filePreviewHandler()}
                      </Card.Body>
                    </Card>
                  </Row>

                  <Row className="p-1">
                    <Button
                      className="btn-wr btn-sm rounded-pill"
                      onClick={onDownload}
                    >
                      Download
                    </Button>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
};

SharePage.propTypes = {};

SharePage.defaultProps = {};

export default SharePage;
