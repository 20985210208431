import {Button, Card, Col, Container, Form, Image, Row, Spinner} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist";

import {resetPassword} from "../../api/user";

import {passwordValidator} from "../../utils/validator";
import HomeFooterTransparent from "../../components/HomeFooter/HomeFooterTransparent";

import "../../styles/button.css";
import "./UpdatePasswordPage.css";

import WerenderLogo from "../../assets/img/brand/werender_logo.svg";


const UpdatePasswordPage = () => {
  let history = useHistory();

  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [token, setToken] = useState("");

  const [passwordRequired, setPasswordRequired] = useState(false);
  const [retypePasswordRequired, setRetypePasswordRequired] = useState(false);
  const [retypePasswordNotMatch, setRetypePasswordNotMatch] = useState(false);
  const [tokenRequired, setTokenRequired] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [showPasswordChecklist, setShowPasswordCheckList] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);

  const clearPasswordErrorMessage = () => {
    setPasswordRequired(false);
  }

  const clearRetypePasswordErrorMessage = () => {
    setRetypePasswordRequired(false);
    setRetypePasswordNotMatch(false);
  }

  const clearTokenErrorMessage = () => {
    setTokenRequired(false);
  }

  const updatePassword = () => {
    setLoadingButton(true);

    const passwordError = passwordValidator(password);
    const retypePasswordError = passwordValidator(retypePassword);
    const tokenError = passwordValidator(token);

    // Ignore the process if error is true
    if (passwordError.isEmpty || retypePasswordError.isEmpty || tokenError.isEmpty) {
      setPasswordRequired(passwordError.isEmpty);
      setRetypePasswordRequired(retypePasswordError.isEmpty);
      setTokenRequired(tokenError.isEmpty);
      setLoadingButton(false);
      return;
    }

    if (password !== retypePassword) {
      setRetypePasswordNotMatch(true);
      setLoadingButton(false);
      return;
    }

    resetPassword(token, password).then(() => {
      setLoadingButton(false);
      history.replace("/signin");
    }).catch(err => {
      setLoadingButton(false);
      alert(err);
    })
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="update-password py-5">
        <main className="flex-shrink-0">
          <Container className="col-xl-10 col-xxl-8 px-4 py-4">
            <Row className="align-items-center g-lg-5 py-5">
              <Col md={6} lg={6} className="mx-auto">
                <p className="lead">
                  <a href="/" className="d-flex align-items-center col-md-3 mb-md-0 text-dark text-decoration-none">
                    <Image src={WerenderLogo} height="30" className="me-2"/>WeRender
                  </a>
                </p>
                <Card className="p-5 shadow-lg">
                  <Form>
                    <legend>Update your password</legend>
                    <Form.Floating>
                      <Form.Control id="reset-token" defaultValue={token} placeholder="Reset Token"
                                    style={{border: tokenRequired ? "2px solid red" : ""}} onClick={clearTokenErrorMessage}
                                    onChange={(e) => {setToken(e.target.value)}}/>
                      <Form.Label htmlFor="reset-token">Reset Token</Form.Label>
                      {
                        tokenRequired ? (
                          <Form.Text className="text-danger" style={{ color: "red" }}>The reset token is required</Form.Text>
                        ) : ("")
                      }
                    </Form.Floating>
                    <br/>
                    <Form.Floating>
                      <Form.Control type="password" id="new-password"
                                    defaultValue={password}
                                    placeholder="New Password"
                                    style={{border: passwordRequired ? "2px solid red" : ""}}
                                    onClick={clearPasswordErrorMessage}
                                    onSelect={() => setShowPasswordCheckList(true)}
                                    onChange={(e) => {setPassword(e.target.value)}}/>
                      <Form.Label htmlFor="new-password">New Password</Form.Label>
                      <PasswordStrengthBar password={password} minLength={8} />
                    </Form.Floating>
                    <br/>
                    <Form.Floating>
                      <Form.Control type="password" id="retype-password" defaultValue={retypePassword} placeholder="Retype Password"
                                    style={{border: retypePasswordRequired || retypePasswordNotMatch ? "2px solid red" : ""}}
                                    onClick={clearRetypePasswordErrorMessage}
                                    onChange={(e) => {setRetypePassword(e.target.value)}}/>
                      <Form.Label htmlFor="retype-password">Retype Password</Form.Label>
                      <PasswordChecklist style={{display: showPasswordChecklist ? "block" : "none"}}
                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                        minLength={8}
                        value={password}
                        valueAgain={retypePassword}
                        onChange={(isValid) => {setIsValidPassword(isValid)}}
                      />
                    </Form.Floating>
                    <br/>
                    <Button onClick={updatePassword} className="w-100 btn btn-sm rounded-pill mx-auto btn-wr"
                            disabled={!isValidPassword || loadingButton}>
                      {loadingButton ? <Spinner as="span" size="sm" role="status" animation="border" variant="light"/> : ""}Update
                    </Button>
                    <hr className="my-4"/>
                    <small className="text-muted align-items-center">Return to <a href="/signin">Sign in</a> page.</small>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </main>
      </div>

      <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
        <HomeFooterTransparent/>
      </div>
    </div>
  );
}

UpdatePasswordPage.propTypes = {};
UpdatePasswordPage.defaultProps = {};

export default UpdatePasswordPage;