import axios from "axios";

const COMPUTE_URL = process.env.REACT_APP_API_COMPUTE_URL;

export const getCompute = () => {
  const url = COMPUTE_URL + "/get";

  return axios.post(url, {}, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return {}
  }).catch(err => {
    return Promise.reject(err);
  });
}

export const findComputeTransaction = (payload) => {
  const url = COMPUTE_URL + "/transaction/find";

  return axios.post(url, payload, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return [];
  }).catch(err => {
    return Promise.reject(err);
  });
}

export const getComputeTransactionList = (payload) => {
  const url = COMPUTE_URL + "/transaction/list";

  return axios.post(url, payload, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  }).catch(err => {
    return Promise.reject(err);
  });
}
