import axios from "axios";

const AUTH_URL = process.env.REACT_APP_API_AUTH_URL;

export const createSession = (payload) => {
  const url = AUTH_URL + "/session/create";

  return axios.post(url, payload, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  }).catch(err => {
    return Promise.reject(err);
  });
}

export const deleteSession = () => {
  const url = AUTH_URL + "/session/delete";

  return axios.post(url, {}, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  }).catch(err => {
    return Promise.reject(err);
  });
}