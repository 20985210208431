import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  Container,
  Row,
  Image,
  Card,
  Form,
  FormCheck,
  Button,
  Spinner,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import bcryptjs from "bcryptjs";
import sha1 from "crypto-js/sha1";

import { emailValidator, passwordValidator } from "../../utils/validator";

import HomeFooterTransparent from "../../components/HomeFooter/HomeFooterTransparent";
import ErrorDialog from "../../components/ErrorWidget/ErrorWidget";

import Styles from "./SigninPage.module.css";
import WerenderLogo from "../../assets/img/brand/werender_logo.svg";
import {createSession} from "../../api/auth";

const SigninPage = () => {
  const { t } = useTranslation(["translation", "error", "login"]);

  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [stayForWeek, setStayForWeek] = useState(false);

  const [emailValidation, setEmailValidation] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState({ show: false });

  const [loadingButton, setLoadingButton] = useState(false);

  const awsWafIntegration = window.AwsWafIntegration;
  const awsWafIntegrationUrl = process.env.REACT_APP_AWS_WAF_INTEGRATION_URL;

  useEffect(() => {
    const script = document.createElement('script');

    // Change this url to real waf integration url
    script.src = awsWafIntegrationUrl
    script.defer = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    }
  }, [awsWafIntegrationUrl]);

  const emailErrorClear = () => {
    setEmailRequired(false);
    setEmailValidation(false);
  };

  const passwordErrorClear = () => {
    setPasswordRequired(false);
  };

  const awsWafSignin = async (data) => {
    const url = process.env.REACT_APP_API_AUTH_URL + "/session/create";
    await awsWafIntegration.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    }).then((res) => {
      if (!res.ok) {
        setShowErrorMessage({
          show: true,
          title: t("error:title.default"),
          message: "Failed to secure network.",
        });
        setLoadingButton(false);
        return;
      }

      // Redirect to dashboard.
      history.push("/dashboard");
    }).catch((err) => {
      const res = err.response;

      if (res) {
        if (res.status >= 400 && res.status < 500) {
          setShowErrorMessage({
            show: true,
            title: t("error:title.default"),
            message: t("error:credential.invalid_email_or_password"),
          });
          setLoadingButton(false);
          return;
        }
      }

      setLoadingButton(false);
      setShowErrorMessage({
        show: true,
        title: t("error:title.default"),
        message: t("error:unknown"),
      });
    });
  }

  const basicSignin = (data) => {
    createSession(data).then(() => {
      // Redirect to dashboard.
      history.push("/dashboard");
    }).catch((err) => {
      const res = err.response;

      if (res) {
        if (res.status >= 400 && res.status < 500) {
          setShowErrorMessage({
            show: true,
            title: t("error:title.default"),
            message: t("error:credential.invalid_email_or_password"),
          });
          setLoadingButton(false);
          return;
        }
      }

      setLoadingButton(false);
      setShowErrorMessage({
        show: true,
        title: t("error:title.default"),
        message: t("error:unknown"),
      });
    });
  }

  const signin = () => {
    setLoadingButton(true);

    const passwordError = passwordValidator(password);
    const emailError = emailValidator(email);

    // Skip the process if the error is true
    if (emailError.isEmpty || emailError.isInvalid || passwordError.isEmpty) {
      setEmailRequired(emailError.isEmpty);
      setPasswordRequired(passwordError.isEmpty);
      if (!emailError.isEmpty) {
        setEmailValidation(emailError.isInvalid);
      }
      setLoadingButton(false);
      return;
    }

    // Create session as login.
    let passwordSha1 = "sha1:" + sha1(password);
    let passwordSalt = bcryptjs.genSaltSync(10);
    let passwordBcrypt = "bcrypt:" + bcryptjs.hashSync(passwordSha1, passwordSalt);

    let data = {
      email: email,
      password: passwordBcrypt,
    };
    if (stayForWeek) {
      data.duration = "168h";
    }

    if (awsWafIntegrationUrl) {
      return awsWafSignin(data);
    }

    return basicSignin(data);
  };

  return (
    <>
      <ErrorDialog
        showErrorMessage={showErrorMessage}
        setShowErrorMessage={setShowErrorMessage}
      />
      <div className="d-flex flex-column h-100">
        <div className={`${Styles.BackgroundMasthead} py-5`}>
          <main className="flex-shrink-0">
            <Container className="col-xl-10 col-xxl-8 px-4 py-4">
              <Row className="align-items-center g-lg-5 py-5">
                <Col md={8} lg={6} className="mx-auto">
                  <p className="lead">
                    <a
                      href="/"
                      className="d-flex align-items-center col-md-3 mb-md-0 text-dark text-decoration-none"
                    >
                      <Image src={WerenderLogo} height="30" className="me-2" />
                      WeRender
                    </a>
                  </p>
                  <Card className="p-5 shadow-lg">
                    <Form id="signin-form">
                      <legend>{t("login:legend")}</legend>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="email"
                          id="signin-email"
                          defaultValue={email}
                          placeholder="name@example.com"
                          style={{
                            border:
                              emailValidation || emailRequired
                                ? "2px solid red"
                                : "",
                          }}
                          onClick={emailErrorClear}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <Form.Label htmlFor="signin-email">
                          {t("email")}
                        </Form.Label>
                        {emailRequired ? (
                          <Form.Text
                            className="text-danger"
                            style={{ color: "red" }}
                          >
                            {t("error:email.required")}
                          </Form.Text>
                        ) : (
                          ""
                        )}
                        {emailValidation ? (
                          <Form.Text
                            className="text-danger"
                            style={{ color: "red" }}
                          >
                            {t("error:email.invalid")}
                          </Form.Text>
                        ) : (
                          ""
                        )}
                      </Form.Floating>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="password"
                          id="signin-password"
                          defaultValue={password}
                          placeholder="Password"
                          style={{
                            border: passwordRequired ? "2px solid red" : "",
                          }}
                          onClick={passwordErrorClear}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <Form.Label htmlFor="signin-password">
                          {t("password")}
                        </Form.Label>
                        {passwordRequired ? (
                          <Form.Text
                            className="text-danger"
                            style={{ color: "red" }}
                          >
                            {t("error:password.required")}
                          </Form.Text>
                        ) : (
                          ""
                        )}
                        {passwordRequired ? <br /> : ""}
                        <small className="text-muted text-end">
                          <a href="/password/forget">
                            {t("login:forgot_password")}?
                          </a>
                        </small>
                      </Form.Floating>
                      <FormCheck className="form-check mb-3">
                        <FormCheck.Input
                          type="checkbox"
                          onChange={(e) => {
                            setStayForWeek(e.target.value);
                          }}
                        />
                        <FormCheck.Label>
                          <small className="text-muted">
                            {t("login:stay_signed")}
                          </small>
                        </FormCheck.Label>
                      </FormCheck>
                      <Button
                        className={`w-100 ${Styles.Button} ${Styles.ButtonSmall} rounded-pill mx-auto ${Styles.ButtonWerender}`}
                        onClick={signin}
                        disabled={loadingButton}
                      >
                        {loadingButton ? (
                          <Spinner style={{marginRight: "5px"}}
                            as="span"
                            size="sm"
                            role="status"
                            animation="border"
                            variant="light"
                          />
                        ) : (
                          ""
                        )}
                        {t("sign_in")}
                      </Button>
                      <hr className="my-4" />
                      <small className="text-muted align-items-center">
                        {t("login:have_account")}{" "}
                        <a href="/signup">{t("sign_up")}</a>.
                      </small>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
          </main>
        </div>

        <HomeFooterTransparent />
      </div>
    </>
  );
};

SigninPage.propTypes = {};
SigninPage.defaultProps = {};

export default SigninPage;
