import axios from "axios";

const RENDER_URL = process.env.REACT_APP_API_RENDER_URL;

export const getRenderList = (payload) => {
  const url = RENDER_URL + "/list";

  return axios.post(url, payload, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return {data: {}};
  }).catch(err => {
    return Promise.reject(err);
  });
}

export const getRenderPaginate = (render_id, options) => {
  const url = RENDER_URL + "/paginate";

  return axios.post(url, {
    render_id: render_id,
    pagination_options: options
  }, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  }).catch(err => {
    return Promise.reject(err);
  });
}