import {Col, Container, Nav, Row} from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const HomeFooterTransparent = () => {
  const { t } = useTranslation(["translation"]);

  return (
    <footer className="py-5">
      <Container>
        <Row>
          <Col xs={6}>
            <Nav as="ul" className="list-unstyled link-dark">
              <Nav.Item as="li">
                <Nav.Link className="px-2 link-dark" href="https://j-cube.jp"><small>© J CUBE, INC.</small></Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xs={6} className="justify-content-end text-end">
            <Nav as="ul" className="list-unstyled link-dark justify-content-end">
              <Nav.Item as="li">
                <Nav.Link as="a" className="px-2 link-dark" href="/privacy"><small style={{textTransform: "uppercase"}}>{t('privacy')}</small></Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as="a" className="px-2 link-dark" href="/terms"><small style={{textTransform: "uppercase"}}>{t('term')}</small></Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as="a" className="px-2 link-dark" href="#">↑</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

HomeFooterTransparent.propTypes = {};
HomeFooterTransparent.defaultProps = {};

export default HomeFooterTransparent;