import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

import {
  getFile,
  getFileBlob,
  getNodeShare,
  getNodeThumbnail,
} from "../../api/storage";

import ThreeViewer from "../../components/ThreeViewer/ThreeViewer";

const EmbedPage = (props) => {
  // Parse URL parameters.
  const history = useHistory();
  const { shareId } = useParams();

  // The "dataUrl" saves the direct URL to USD file.
  const [dataUrl, setDataUrl] = useState("");
  const [name, setName] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  // Get model name and data URL from share ID or URL query parameter.
  useEffect(() => {
    if (shareId) {
      getNodeShare(shareId)
        .then((res) => {
          getFile({ id: res.storage_node_id }, res.id).then((res) => {
            setName(res.name);
            document.title = res.name;
            getFileBlob(res.id, shareId)
              .then((res) => {
                setDataUrl(res.url);
              })
              .catch((err) => {
                console.warn(err);
              });
            getNodeThumbnail(res.id, shareId)
              .then((res) => {
                setThumbnailUrl(res.url);
              })
              .catch((err) => {
                console.warn(err);
              });
          });
        })
        .catch((err) => {
          console.warn(err);
        });
    } else {
      const query = new URLSearchParams(history.location.search);
      let name = query.get("name");
      let src = query.get("src");

      setDataUrl(src);
      setName(name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareId]);

  if (dataUrl) {
    return (<ThreeViewer name={name} src={dataUrl} thumbnail={thumbnailUrl} />);
  } else if (thumbnailUrl) {
    return (<Image src={thumbnailUrl} />);
  } else {
    return (<p>404</p>)
  }
};

EmbedPage.propTypes = {};
EmbedPage.defaultProps = {};

export default EmbedPage;
