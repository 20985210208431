import {useEffect, useState} from "react";
import {useTranslation, Trans} from "react-i18next";
import {Button, Card, Carousel, Col, Container, Form, Image, Modal, Row} from "react-bootstrap";

import {getUser} from "../../api/user";
import {findDownload} from "../../api/download";

import Styles from "./HomeWidget.module.css";

import DownloadBackground from "../../assets/img/dl-bg.jpg";
import Esper from "../../assets/img/renders/werender_esper_room.jpg";
import Metaverse from "../../assets/img/renders/werender_metaverse.jpg";
import Shoes from "../../assets/img/renders/werender_shoes.png";
import Panther from "../../assets/img/renders/panther.jpg";
import Porsche from "../../assets/img/renders/werender_porsche911.jpg";
import Nft from "../../assets/img/renders/werender_nft.jpg";
import IntelLogo from "../../assets/img/logos/intel_logo_bg.png";
import AWSLogo from "../../assets/img/logos/pby_aws_logo.png";
import JTLLogo from "../../assets/img/logos/jtl_logo.png";

import "../../styles/styles.css";

const AboutLayout = () => {
  const [weRenderVersion, setWeRenderVersion] = useState("");
  const [weRenderClient, setWeRenderClient] = useState(new Map());
  const [isLogin, setIsLogin] = useState(false);

  const [downloadShow, setDownloadShow] = useState(false);

  const { t } = useTranslation(["translation", "home.about"]);

  const handleClose = () => setDownloadShow(false);
  const handleShow = () => setDownloadShow(true);

  useEffect(() => {
    getUser().then(() => {
      setIsLogin(true);
      return findDownload().then(res => {
        if (res.data) {
          const clients = new Map();
          res.data.forEach(value => {
            if (clients.has(value.version)) {
              let client = clients.get(value.version);
              client.push(value);
              clients.set(value.version, client);
            } else {
              clients.set(value.version, [value]);
            }
          });

          const sortedMap = new Map([...clients.entries()].reverse());

          if (!weRenderVersion) {
            setWeRenderVersion(sortedMap.keys().next().value);
          }

          setWeRenderClient(sortedMap);
        }
      });
    }).catch(() => {
      setIsLogin(false);
    });
  }, [weRenderVersion]);

  return (
    <div className={Styles.BackgroundTop}>
      <Container className="pb-5">
        <Row className="flex-lg-row align-items-center gy-5">
          <Col lg={6}>
            <h1 className="display-5 fw-bold lh-1mb-3">
              <Trans i18nKey="home.about:title"
                     components={{span: <span className={Styles.WerenderText}/>}}
              />
            </h1>
            <p className="lead">
              <Trans i18nKey="home.about:description"
                     components={{small: <small/>, b: <b/>, em: <em/>}}/>
            </p>
            <div>
              <Row className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <Col xs={12} sm={5} className="text-center">
                  {
                    isLogin ? (
                      <div>
                        <Button as="a" variant="outline-dark" className={`${Styles.ButtonOutlineDark} rounded-pill w-100`} onClick={handleShow}>
                          {t('download')} <i className={`${Styles.BI} bi-download`}/>
                        </Button>
                        <br/><small className="text-muted"><small>({t('home.about:muted.download')})</small></small>
                      </div>
                    ) : (
                      <div>
                        <Button as="a" href="/signup" className={`${Styles.ButtonWerender} rounded-pill w-100`}>
                          {t('sign_up')} <i className={`${Styles.BI} bi-chevron-right`}/>
                        </Button>
                        <br/><small className="text-muted"><small>({t('home.about:muted.sign_up')})</small></small>
                      </div>
                    )
                  }
                </Col>
              </Row>
            </div>

            <Modal show={downloadShow} onHide={handleClose} centered={true} className="fade">
              <Modal.Header className={`${Styles.ModalHeader} p-0`}>
                <Image className="img-fluid br-modal-img card-img-top" src={DownloadBackground}/>
              </Modal.Header>
              <Modal.Body>
                <p className="text-center"><span>{t('home.about:modal.title')}</span><br/>
                  <small className="text-center text-muted">{t('home.about:modal.description')}</small>
                </p>
                <Row className="text-center px-4">
                  {
                    weRenderClient.has(weRenderVersion) ? (
                      Array.from(weRenderClient.get(weRenderVersion)).map(value => (
                        <Col key={value.name} xs={12} className="pb-1">
                          <Button as="a" target="_blank" variant="outline-dark" href={value.url}
                                  className={`${Styles.Button} ${Styles.ButtonSmall} rounded-pill col-12`}>
                            {value.target} <i className={`${Styles.BI} bi-chevron-right`}/></Button>
                        </Col>
                      ))
                    ) : ""
                  }
                </Row>
                <hr/>
                <p className="text-center">{t('home.about:modal.material')}</p>
                <Row className="text-center px-5">
                  <Col className="pb-0">
                    <Button variant="outline-dark" href="https://werender-downloads.s3-us-east-2.amazonaws.com/kj8y997j/..."
                            className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill col-6`}>
                      {t('examples')} <i className={`${Styles.BI} bi-archive`}/></Button>
                    <p><small className="text-muted">({t('home.about:modal.example_description')})</small></p>
                  </Col>
                </Row>
              </Modal.Body>
              <div className={Styles.CardFooter}>
                <Form className="text-center">
                  <Form.Label htmlFor="formVersionSelect">{t('home.about:modal.version')}:</Form.Label>
                  <select defaultValue={weRenderVersion} className="select my-1 mr-sm-2" id="formVersionSelect"
                          onChange={(e) => setWeRenderVersion(e.target.value)}>
                    {
                      Array.from(weRenderClient.keys()).map(value => (
                        <option key={value} value={value}>{value}</option>
                      ))
                    }
                  </select>
                </Form>
              </div>
            </Modal>
          </Col>
          <Col className="justify-content-md-start p-3 rounded" xs={12} md={12} lg={6}>
            <Card className="p-3 bg-white">
              <Carousel id="carouselHome" className="shadow">
                <Carousel.Item>
                  <Image src={Shoes} className="img-fluid rounded" alt="..."/>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={Metaverse} className="img-fluid rounded" alt="..."/>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={Porsche} className="img-fluid rounded" alt="..."/>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={Nft} className="img-fluid rounded" alt="..."/>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={Esper} className="img-fluid rounded" alt="..."/>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={Panther} className="img-fluid rounded" alt="..."/>
                </Carousel.Item>
              </Carousel>
            </Card>
            <Col className="p-0 mt-3 text-center">
              <Card className="bg-transparent text-dark p-5 pt-3 pb-4 no-border">
                <p className="disabled"><small>OFFICIAL PARTNERS</small></p>
                <Row>
                  <Col className="px-3">
                    <a href="https://www.intel.com" target="_blank" rel="noreferrer">
                      <Image className="p-1 250 img-thumbnail" src={IntelLogo} rounded fluid/>
                    </a>
                  </Col>
                  <Col className="px-3">
                    <a href="https://aws.amazon.com/what-is-cloud-computing" target="_blank" rel="noreferrer">
                      <Image className="p-1 250 img-thumbnail" src={AWSLogo} rounded fluid/>
                    </a>
                  </Col>
                  <Col className="px-3">
                    <a href="http://www.jadason.com" target="_blank" rel="noreferrer">
                      <Image className="p-1 250 img-thumbnail" src={JTLLogo} rounded fluid/>
                    </a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

AboutLayout.propTypes = {};
AboutLayout.defaultProps = {};

export default AboutLayout;