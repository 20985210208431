import {Button, Col, Container, Image, Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import Styles from "./HomeWidget.module.css";

import PythonLogo from "../../assets/img/logos/py_logo.png";
import CSLogo from "../../assets/img/logos/cs_logo.png";
import JavascriptLogo from "../../assets/img/logos/js_logo.png";
import UnityLogo from "../../assets/img/logos/unity_logo.png";
import UnityUI from "../../assets/img/unity-ui.jpg";

const FeatureLayout = () => {
  const { t } = useTranslation(["translation", "home.feature"]);

  return (
    <div className={`bg-dark text-secondary px-4 pt-5 text-center`}>
      <div className="py-5">
        <h1 className="display-5 text-white">{t('home.feature:integrate.title')}</h1>
        <Col lg={8} className="mx-auto">
          <p className="fs-5 mb-4">
            {t('home.feature:integrate.description')}
          </p>
          <Nav as="ul" className="row row-cols-3 list-unstyled list">
            <Nav.Item as="li" className="col mb-4">
              <a className="d-block text-dark text-decoration-none" href="https://www.python.org/"
                 target="_blank" rel="noreferrer">
                <div className={`p-3 py-4 mb-2 ${Styles.BackgroundLight} text-center rounded`}>
                  <Image src={PythonLogo} width={80} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <p className="text-white">Python
                    <br/><small className="text-muted">3.7+</small>
                  </p>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="col mb-4">
              <a className="d-block text-dark text-decoration-none" href="https://docs.microsoft.com/en-us/dotnet/csharp/"
                 target="_blank" rel="noreferrer">
                <div className={`p-3 py-4 mb-2 ${Styles.BackgroundLight} text-center rounded`}>
                  <Image src={CSLogo} width={80} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-muted text-decoration-none text-center pt-1">
                  <span className="text-white">C#</span><br/><small>.NET 4.6+, Unity, Mono</small>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="col mb-4">
              <a className="d-block text-dark text-decoration-none" href="https://www.javascript.com/"
                 target="_blank" rel="noreferrer">
                <div className={`p-3 py-4 mb-2 ${Styles.BackgroundLight} text-center rounded`}>
                  <Image src={JavascriptLogo} width={80} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-muted text-decoration-none text-center pt-1">
                  <div className="name text-muted text-decoration-none text-center pt-1">
                    <span className="text-white">JavaScript</span><br/><small>Server-side</small>
                  </div>
                </div>
              </a>
            </Nav.Item>
          </Nav>
        </Col>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <Button as="a" href="/docs" className={`${Styles.ButtonOutlineWerender} px-4 me-sm-3 rounded-pill`}>{t('documentation')}</Button>
          <Button as="a" href="https://werenderworkspace.slack.com" variant="outline-light" target="_blank" rel="noreferrer"
                  className="px-4 me-sm-3 rounded-pill">{t('home.feature:slack_community')}</Button>
        </div>
        <hr className="mt-5"/>
      </div>

      <h3 className="display-6 text-white">{t('home.feature:use_case.title')}</h3>
      <Col lg={6} className="mx-auto">
        <h5 className="text-white lead fw-normal">{t('home.feature:use_case.subtitle')}</h5>
        <p className="lead mb-4">{t('home.feature:use_case.description')}</p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
          <Button href="/docs" className={`${Styles.ButtonOutlineWerender} px-4 me-sm-3 rounded-pill`}>WeRender API</Button>
          <Button href="https://docs.unity3d.com/2022.1/Documentation/Manual/overview-of-dot-net-in-unity.html"
                  variant="outline-light" className="px-4 me-sm-3 rounded-pill" target="_blank" rel="noreferrer">
            {t('home.feature:use_case.net_unity')}
          </Button>
        </div>
      </Col>
      <Image src={UnityLogo} className="img-fluid border rounded-3 mb-4 bg-white p-3" alt="..." width="250" loading="lazy"/>
      <div className={`${Styles.OverflowHidden} overflow-hidden`} >
        <Container className="px-5">
          <Image src={UnityUI} className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy"/>
        </Container>
      </div>
    </div>
  );
}

FeatureLayout.propTypes = {};
FeatureLayout.defaultProps = {};

export default FeatureLayout;