import {Button, Card, Col, Container, Row} from "react-bootstrap";

import Styles from "./PricingWidget.module.css";

const MiddleLayout = () => {
  return (
    <div className={Styles.BackgroundLight}>
      <Container className="py-5">
        <div className="mx-auto text-center pb-4">
          <h6 className="display-6 fw-normal">Choose your plan</h6>
          <p className="fs-6 text-muted">
            Select the WeRender plan that best suits your needs.
          </p>
        </div>
        <Row className="row-cols-1 row-cols-lg-3 g-5 pb-4">
          <Col>
            <Card className={`rounded-3 shadow-sm border-top ${Styles.BorderWerender} ${Styles.BorderTopCard} ${Styles.Card}`}>
              <Card.Body>
                <h4 className={`card-title mb-2 ${Styles.LineHeight15}`}>
                  <ul className="d-flex list-unstyled mt-auto">
                    <li className="me-auto">
                      <h4 className={`${Styles.TextWerender} my-0`}>Trial</h4>
                    </li>
                    <li className="d-flex align-items-center">
                      FREE
                    </li>
                  </ul>
                </h4>
                <Card.Subtitle as="h6" className={`mb-2 text-muted ${Styles.LineHeight15}`}>
                  Get a small amount of free storage and gift credits. Buy more credits on-demand. Good for individuals and evaluations.
                </Card.Subtitle>
                <Card.Text as="ul" className="mt-3">
                  <li>1 GB of storage</li>
                  <li>Limited data-transfer<sup>†</sup></li>
                  <li>1000 WRC, one-time gift</li>
                  <li>Buy more WRC on-demand</li>
                  <li>Max 2K resolution<sup>†</sup></li>
                  <li>Default quality only<sup>†</sup></li>
                  <li>Up to 100 compute cores<sup>†</sup></li>
                  <li>1 concurrent process</li>
                  <li>Community-only Support<br/><small className="text-muted">(public Slack)</small></li>
                </Card.Text>
              </Card.Body>
              <Card.Footer className={`text-center ${Styles.CardFooterPlans}`}>
                <Button as="a" href="/signup" className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonWerender} rounded-pill col-6 mx-auto`}>
                  Sign up <i className={`${Styles.BI} bi-chevron-right`}/>
                </Button>
              </Card.Footer>
            </Card>
            <p className="text-center text-muted small">
              <em className="small">(†) Restrictions are lifted at first WRC purchase.</em>
            </p>
          </Col>
          <Col>
            <Card className={`rounded-3 shadow-sm border-top ${Styles.BorderWerender} ${Styles.BorderTopCard} ${Styles.Card}`}>
              <Card.Body>
                <h4 className={`card-title mb-2 ${Styles.LineHeight15}`}>
                  <ul className="d-flex list-unstyled mt-auto">
                    <li className="me-auto">
                      <h4 className={`${Styles.TextWerender} my-0`}>Pro</h4>
                    </li>
                    <li className="d-flex align-items-center">
                      βeta: $99<small className="text-muted fw-light">/mo</small><span class="text-muted px-2"><s>199<small className="text-muted fw-light">/mo</small></s></span>
                    </li>
                  </ul>
                </h4>
                <Card.Subtitle as="h6" className={`mb-2 text-muted ${Styles.LineHeight15}`}>
                  Good starting base of storage and credits. Add more on-demand. Good for professionals, small and medium business.
                </Card.Subtitle>
                <Card.Text as="ul" className="mt-3">
                  <li>1 TB of storage</li>
                  <li>Unlimited data-transfer</li>
                  <li>3,000 WRC /month</li>
                  <li>Buy credits & storage on-demand</li>
                  <li>Up to 16K resolution</li>
                  <li>No quality restrictions</li>
                  <li>Up to 6,000 compute cores</li>
                  <li>Up to 20 parallel processes</li>
                  <li>Private Support<br/><small className="text-muted">(private Slack, e-mail)</small></li>
                </Card.Text>
              </Card.Body>
              <Card.Footer className={`text-center ${Styles.CardFooterPlans}`}>
                <Button as="a" href={process.env.REACT_APP_STRIPE_PRO_LICENSE_URL} target="_blank"
                        className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonWerender} rounded-pill col-6 mx-auto`}>
                  Subscribe <i className={`${Styles.BI} bi-chevron-right`}/>
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card className={`rounded-1 shadow-sm border-top ${Styles.BorderWerender} ${Styles.BorderTopCard} ${Styles.Card}`}>
              <Card.Body>
                <h4 className={`card-title mb-2 ${Styles.LineHeight15}`}>
                  <ul className="d-flex list-unstyled mt-auto">
                    <li className="me-auto">
                      <h4 className={`${Styles.TextWerender} my-0`}>Enterprise</h4>
                    </li>
                    <li className="d-flex align-items-center">
                      Tailored
                    </li>
                  </ul>
                </h4>
                <Card.Subtitle as="h6" className={`mb-2 text-muted ${Styles.LineHeight15}`}>
                  Tell us your storage and credit needs. Pay a base fee and adjust at month end. Good for corporate and enterprise.
                </Card.Subtitle>
                <Card.Text as="ul" className="mt-3">
                  <li>Choose a storage base amount</li>
                  <li>Choose a WRC base amount</li>
                  <li>Unlimited data-transfer</li>
                  <li>Bill adjusted at month-end</li>
                  <li>Unlimited resolution</li>
                  <li>No quality restrictions</li>
                  <li>Up to 12,000 compute cores</li>
                  <li>Up to 100 parallel processes</li>
                  <li>Private Support, Training, Consulting<br/><small className="text-muted">(private Slack, e-mail,
                    call)</small></li>
                </Card.Text>
              </Card.Body>
              <Card.Footer className={`text-center ${Styles.CardFooterPlans}`}>
                <Button as="a" href="mailto:sales@j-cube.jp" className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonWerender} rounded-pill col-6 mx-auto`}>
                  Contact <i className={`${Styles.BI} bi-chevron-right`}/>
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

MiddleLayout.propTypes = {};
MiddleLayout.defaultProps = {};

export default MiddleLayout;