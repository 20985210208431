import ComputeCreditWidget from '../../components/ComputeCreditWidget/ComputeCreditWidget.js';
import HelloWidget from '../../components/HelloWidget/HelloWidget.js';
import StorageOverview from '../StorageWidget/StorageOverview';

import "./OverviewWidget.css"

import {Row} from "react-bootstrap";

const OverviewWidget = (props) => {
  return (
    <div className="overview">
      <Row className="flex-lg-row g-5">
        <HelloWidget />
        <ComputeCreditWidget />
        <StorageOverview {...props} />
      </Row>
    </div>
  );
};

OverviewWidget.propTypes = {};
OverviewWidget.defaultProps = {};

export default OverviewWidget;
