import {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Button, Col, Container, Dropdown, Image, Nav} from "react-bootstrap";

import {getUser} from "../../api/user";

import Styles from "./HomeNavbar.module.css";
import WerenderLogo from '../../assets/img/brand/werender_logo.png';

const HomeNavbar = (props) => {
  const { t, i18n } = useTranslation(["translation"]);

  const [isLogin, setIsLogin] = useState(false);

  const lngs = {
    en: { nativeName: 'English' },
    // jp: { nativeName: '日本語' }
  };

  useEffect(() => {
    getUser().then(() => {
      setIsLogin(true);
    }).catch(() => {
      setIsLogin(false);
    });
  }, []);

  const ActiveNav = (path) => {
    const location = useLocation();
    if (location.pathname.includes(path)) {
      return Styles.ActiveHighlight;
    }
  }

  return (
    <div className={`sticky-top ${Styles.HeaderWerender}`}>
      <Container>
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
          <a href="/" className="d-flex align-items-center col-lg-2 col-md-3 mb-md-0 text-dark text-decoration-none">
            <Image src={WerenderLogo} height="30" alt="logo" className="me-2"/>
            <span>WeRender <sup><small><small><em>βeta</em></small></small></sup></span>
          </a>
          <Nav as="ul" className="col-12 col-lg-auto col-md-auto justify-content-center mb-md-0">
            <Nav.Item key="explore" as="li">
              <Nav.Link href="/explore" className={`px-3 link-dark ${ActiveNav("/explore")}`}>{t('explore')}</Nav.Link>
            </Nav.Item>
            <Nav.Item key="docs" as="li">
              <Nav.Link href="/docs" className={`px-3 link-dark ${ActiveNav("/docs")}`}>{t('documentation')}</Nav.Link>
            </Nav.Item>
            <Nav.Item key="pricing" as="li">
              <Nav.Link href="/pricing" className={`px-3 link-dark ${ActiveNav("/pricing")}`}>{t('pricing')}</Nav.Link>
            </Nav.Item>
            <Nav.Item key="slack" as="li">
              <Nav.Link href="https://werenderworkspace.slack.com" className="px-3 link-dark" target="_blank" rel="noreferrer">
                <i className={`${Styles.BI} bi-slack`}/></Nav.Link>
            </Nav.Item>
            <Nav.Item key="lang" as="li">
              <Dropdown>
                <Button className="nav-link link-dark" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" variant="link">
                  <i className={`${Styles.BI} bi-translate`}/>
                </Button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  {Object.keys(lngs).map((lng) => (
                    <li key={lng}>
                      <Button variant="link" className="nav-link link-dark" onClick={() => i18n.changeLanguage(lng)}
                              style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}>
                        {lngs[lng].nativeName}
                      </Button>
                    </li>
                  ))}
                </ul>
              </Dropdown>
            </Nav.Item>
          </Nav>
          <Col lg={2} className="text-end">
            {
              isLogin ? (
                <Button as="a" href="/dashboard" className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineWerender} rounded-pill mx-auto`}>
                  {t('dashboard')} <i className={`${Styles.BI} bi-chevron-right`}/>
                </Button>
              ) : (
                <Button as="a" href="/signin" className={`${Styles.Button} ${Styles.ButtonSmall} rounded-pill mx-auto ${Styles.ButtonWerender}`}>
                  {t('sign_in')} <i className={`${Styles.BI} bi-chevron-right`}/>
                </Button>
              )
            }
          </Col>
        </header>
      </Container>
      <div className={Styles.NavbarColorBar}/>
    </div>
  );
};

HomeNavbar.propTypes = {};
HomeNavbar.defaultProps = {};

export default HomeNavbar;
