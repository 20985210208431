import {useState} from "react";
import {Button, Card, Col, Image, Row} from "react-bootstrap";

import "./ExploreWidget.css";
import Styles from "./ExploreWidget.module.css";
import ImageModalLayout from "./ImageModalLayout";
import CodeModalLayout from "./CodeModalLayout";

import FeatureContentEn from "../../assets/pages/explore/feature/content_en.json";

const FeatureLayout = () => {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [code, setCode] = useState("");

  const [codeShow, setCodeShow] = useState(false);
  const [imageShow, setImageShow] = useState(false);

  const handleCodeShow = (title, codeSrc) => {
    fetch(codeSrc)
      .then((res) => res.text())
      .then((text) => setCode(text))
      .catch((err) => alert(err));

    setTitle(title);
    setCodeShow(true);
  }
  const handleCodeClose = () => setCodeShow(false);

  const handleImageShow = (title, image) => {
    setTitle(title);
    setImage(image);
    setImageShow(true);
  }
  const handleImageClose = () => setImageShow(false);

  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <Row className="g-5 use-case industry feature">
      {FeatureContentEn.map((content, index) => (
        <Col key={index} xs={12} lg={6} xl={4}>
          <Card className={`${Styles.Card} shadow h-100`}>
            <div className="card-img-top-h-250-px">
              <Image src={content.image.src} className="card-img-top card-img-ratio bg-light" alt={content.title}/>
              {content.after_beta ? <div className="top-left-label"><em>After βeta</em></div> : ""}
            </div>
            <Card.Body>
              <Card.Title><h5>{content.title}</h5></Card.Title>
              <Card.Text>{content.text}</Card.Text>
            </Card.Body>
            <Card.Footer className={`${Styles.BackgroundWhite} border-0 pb-3 pt-0`}>
              <Button onClick={() => handleImageShow(content.title, content.image)} variant="outline-dark"
                      className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill`}>
                <i className={`${Styles.BI} bi-eye`}/> View</Button>
              {content.snippet ? (
                <Button onClick={() => handleCodeShow(content.title, content.snippet)} variant="outline-dark"
                        className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill ml-p-1`}>
                  <i className={`${Styles.BI} bi-code`}/> Code</Button>
              ): ""}
              {content.link ? (
                <Button onClick={() => handleClick(content.link)} variant="outline-dark"
                        className={`${Styles.Button} ${Styles.ButtonSmall} ${Styles.ButtonOutlineDark} rounded-pill ml-p-1`}>
                  <i className={`${Styles.BI} bi-github`}/> Github</Button>
              ): ""}
            </Card.Footer>
          </Card>
        </Col>
      ))}

      <ImageModalLayout imageShow={imageShow} imageClose={handleImageClose} title={title} image={image}/>
      <CodeModalLayout codeShow={codeShow} codeClose={handleCodeClose} title={title} language="python" code={code}/>
    </Row>
  );
}

FeatureLayout.propTypes = {};
FeatureLayout.defaultProps = {};

export default FeatureLayout;