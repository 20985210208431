import {ButtonGroup, Dropdown, DropdownButton, Button} from "react-bootstrap";
import {CgChevronDoubleDown, CgChevronDoubleUp, CgSelect} from "react-icons/cg";

import "./BootstrapTable.css";

export const pageButtonRenderer = ({page, active, onPageChange}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };

  const activeStyle = {};
  if (active) {
    activeStyle.backgroundColor = "#20C2AF";
    activeStyle.color = "white";
  } else {
    activeStyle.backgroundColor = "white";
    activeStyle.color = "#20C2AF";
  }
  if (typeof page === 'string') {
    activeStyle.backgroundColor = "white";
    activeStyle.color = "#20C2AF";
  }
  return (
    <li key={page} className="page-item">
      <Button variant="link" className="page-link" onClick={handleClick} style={ activeStyle }>{ page }</Button>
    </li>
  );
};

export const sizePerPageRenderer = ({options, currSizePerPage, onSizePerPageChange}) => (
  <DropdownButton key={currSizePerPage} as={ButtonGroup} drop="up" title={currSizePerPage} className="per-page">
    {
      options.map((option, index) => {
        return (
          <Dropdown.Item key={index} eventKey={option.text} onClick={() => onSizePerPageChange(option.page)}>{option.text}</Dropdown.Item>
        );
      })
    }
  </DropdownButton>
);

export const sortCaretFormatter = (order, column) => {
  if (!order) {
    return (
      <span style={{verticalAlign: ".125em"}}>
          &nbsp;<CgSelect/>
        </span>
    );
  } else if (order === 'asc') {
    return (
      <span style={{verticalAlign: ".125em"}}>
          &nbsp;<CgChevronDoubleUp/>
        </span>
    );
  } else if (order === 'desc') {
    return (
      <span style={{verticalAlign: ".125em"}}>
          &nbsp;<CgChevronDoubleDown/>
        </span>
    );
  }
  return null;
}