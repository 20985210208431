import {Button, Card, Container, Col, Image} from "react-bootstrap";
import {useEffect, useState} from "react";

import {getUser} from "../../api/user";

import HomeNavbar from "../../components/HomeNavbar/HomeNavbar";
import HomeFooter from "../../components/HomeFooter/HomeFooter";

import Styles from "./DocumentPage.module.css";
import ScreenShot from "../../assets/img/screenshot_docs.png";

const DocumentPage = () => {
  const [isLogin, setIsLogin] = useState(false);

  //TODO: remove this URL after release.
  const openDocumentation = () => {
    window.location.href=process.env.REACT_APP_HELP_URL;
  }

  useEffect(() => {
    getUser().then(() => {
      setIsLogin(true);
    }).catch(() => {
      setIsLogin(false);
    });
  }, []);

  return (
    <div>
      <HomeNavbar/>
      <main className="py-5">
        <Container className="text-center">
          <Col lg={8} className="mx-auto flex-lg-row align-items-center text-center g-5">
            <h1 className="display-5 lh-1 mb-3">WeRender Docs</h1>
            <p className="lead">
              Documentation is what the API user interacts with the most:
              special care has been put in writing clear, complete and
              compelling documentation. The WeRender documentation also contains
              a large <em>"visual reference"</em> to showcase API properties for
              materials, lights, cameras, settings etc. The visual reference is
              built with the WeRender API: hundreds of images are generated and
              referenced automatically into the docs.
            </p>
            <hr/>
            <Card.Subtitle as="p" className={`${Styles.Subtitle} ${Styles.LineHeight15} text-dark pb-3 px-5`}>
              Access to the documentation is currently restricted to registered
              users that have enrolled into βeta, it will be public
              afterwards.
            </Card.Subtitle>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
              <Col xs={12} sm={5} className="text-center">
                {
                  isLogin ? (
                    <Button as="a" className={`${Styles.ButtonWerender} rounded-pill w-100`} onClick={openDocumentation}>
                      Read documentation <i className={`${Styles.BI} bi-chevron-right`}/>
                    </Button>
                  ) : (
                    <div>
                      <Button as="a" href="/signup" className={`${Styles.ButtonWerender} rounded-pill w-100`}>
                        Sign up <i className={`${Styles.BI} bi-chevron-right`}/>
                      </Button>
                      <br/>
                      <p className="text-muted"><small>(registered beta only)</small></p>
                    </div>
                  )
                }
              </Col>
            </div>
          </Col>
          <Image src={ScreenShot} className="d-block mx-lg-auto img-fluid overflow-hidden" alt="WeRender Docs" loading="lazy"/>
        </Container>
      </main>
      <HomeFooter/>
    </div>
  );
}

DocumentPage.propTypes = {};
DocumentPage.defaultProps = {};

export default DocumentPage;