import HomeNavbar from "../../components/HomeNavbar/HomeNavbar.js";
import HomeFooter from "../../components/HomeFooter/HomeFooter";
import PricingWidget from "../../components/PricingWidget/PricingWidget";

const PricingPage = () => (
  <div>
    <HomeNavbar/>
    <main className="py-5">
      <PricingWidget/>
    </main>
    <HomeFooter/>
  </div>
);

PricingPage.propTypes = {};
PricingPage.defaultProps = {};

export default PricingPage;
