import axios from "axios";

const ASSET_URL = process.env.REACT_APP_API_ASSET_URL;

export const getModelHierarchyById = (id) => {
  const url = ASSET_URL + "/model/hierarchy/get";
  const payload = {
    storage_file_id: id,
  };

  return axios
    .post(url, payload, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return {};
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getAssetList = (data) => {
  const url = ASSET_URL + "/list";

  return axios.post(url, data, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  }).catch(err => {
    return Promise.reject(err);
  });
}
