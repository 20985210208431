import axios from "axios";

export const findDownload = () => {
  const url = process.env.REACT_APP_API_DOWNLOAD_URL + "/list";

  return axios.post(url, {}, {
    withCredentials: true
  }).then(res => {
    if (res.data) {
      return res.data;
    }
    return [];
  }).catch(err => {
    return Promise.reject(err);
  });
}
