import {useEffect, useState} from "react";
import {Row, Col, Card, Table, Button, Spinner} from "react-bootstrap";
import {TbFaceIdError} from "react-icons/tb";

import {getRenderList} from "../../api/render";
import {getStorageUsage, getStorage} from "../../api/storage";
import {getStorageActivities} from "../../services/storage";

const StorageOverview = (props) => {
  const [render, setRender] = useState([]);
  const [storageUsage, setStorageUsage] = useState({});
  const [userStorage, setUserStorage] = useState({});
  const [storageActivity, setStorageActivity] = useState({});

  const [refresh, setRefresh] = useState(0);
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {

    getRenderList({}).then(async res => {
      setRender(res);

      const storage_usage = await getStorageUsage();
      setStorageUsage(storage_usage);

      const user_storage = await getStorage();
      setUserStorage(user_storage);

      const storage_activity = await getStorageActivities();
      setStorageActivity(storage_activity);

      setLoadingButton(false);
      setShowRetryButton(false);
    }).catch(err => {
      setLoadingButton(false);
      setShowRetryButton(true);
    });
  }, [refresh]);

  const retry = () => {
    setLoadingButton(true);

    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const KB_BY_BYTES = 1000;
  const MB_BY_BYTES = 1000000;
  const GB_BY_BYTES = 1000000000;

  const formatStorageSize = (size) => {
    if (size >= MB_BY_BYTES && size < GB_BY_BYTES) {
      return (size/MB_BY_BYTES).toFixed(2) + ' MB';
    } else if (size >= GB_BY_BYTES) {
      return (size/GB_BY_BYTES).toFixed(2) + ' GB';
    } else if (size < MB_BY_BYTES) {
      return (size/KB_BY_BYTES).toFixed(2) + ' KB';
    } else {
      return '0';
    }
  }

  const formatFreeStorage = (size, capacity) => {
    let storage = capacity - size;
    return formatStorageSize(storage);
  }

  const formatTotalStorage = (capacity) => {
    return `${formatStorageSize(capacity)}`;
  }

  const storagePercentage = (size, capacity) => {
    let percentage = (size * 100) / capacity;
    return percentage.toFixed(2);
  }

  const formatAvgRenderStorage = (size, capacity, renders) => {
    // Discard the null for the very new user.
    if (renders === null) {
      return 'N/A';
    }

    let length = 1;
    if (renders.length > 1) {
      length = renders.length;
    }
    let avg = size / length;

    return formatStorageSize(avg);
  }

  const avgRenderStoragePercentage = (size, capacity, renders) => {
    // Discard the null for the very new user.
    if (renders === null) {
      return 'N/A';
    }

    let length = 1;
    if (renders.length > 1) {
      length = renders.length;
    }
    let avg = size / length;
    let percentage = (avg * 100) / capacity;

    return percentage.toFixed(2);
  }

  return (
    <div style={{height: "fit-content"}}>
      <Row style={{marginTop: "10px"}} className="g-5">
        <Col>
          <h6>STORAGE (GB)</h6>
        </Col>
        <Col style={{textAlign: "right"}}>
          <a style={{color: "#20C2AF"}} href="/dashboard/account">BUY MORE</a>
        </Col>
      </Row>

      {showRetryButton ? (
        <Row className="text-center justify-content-center">
          <TbFaceIdError style={{height: "100px"}}/>
          <h6>Sorry, We are failed to get storage data, Click the Retry button to retry.</h6>
          <Button className="btn-wr btn-sm rounded-pill" style={{width: "100px"}} disabled={loadingButton} onClick={retry}>
            {loadingButton ? <Spinner as="span" size="sm" role="status" animation="border" variant="light"/> : ""} Retry
          </Button>
        </Row>
      ) : (
        <Card style={{height: "100%"}} className="g-5">
          <Card.Body>
            <Row style={{textAlign: "center"}}>
              <h6>TOTAL STORAGE: {formatTotalStorage(userStorage.capacity)}</h6>
            </Row>
            <div style={{height: "2px", backgroundColor: "#20C2AF"}}/>
            <Table responsive>
              <tbody>
              <tr>
                <td>Used Storage:</td>
                <td style={{textAlign: "right"}}>{formatStorageSize(storageUsage.size)}</td>
                <td>({storagePercentage(storageUsage.size, userStorage.capacity)}% used)</td>
              </tr>
              <tr>
                <td>Free Storage:</td>
                <td style={{textAlign: "right"}}>{formatFreeStorage(storageUsage.size, userStorage.capacity)}</td>
                <td>({storagePercentage(userStorage.capacity-storageUsage.size, userStorage.capacity)}% free)</td>
              </tr>
              <tr>
                <td>Avg. storage per render:</td>
                <td style={{textAlign: "right"}}>{render === null ? "N/A" : formatAvgRenderStorage(storageUsage.size, userStorage.capacity, render)}</td>
                <td>{render === null ? "" : `(${avgRenderStoragePercentage(storageUsage.size, userStorage.capacity, render)}%)`}</td>
              </tr>
              </tbody>
            </Table>
            <Table responsive style={{marginTop: "25px"}}>
              <tbody>
              <tr>
                <td/>
                <td style={{textAlign: "right"}}>LAST 24 HOURS</td>
                <td style={{textAlign: "right"}}>LAST 7 DAYS</td>
                <td style={{textAlign: "right"}}>LAST 30 DAYS</td>
              </tr>
              <tr>
                <td>Storage used</td>
                <td style={{textAlign: "right"}}>{formatStorageSize(storageActivity.last_day_size)}</td>
                <td style={{textAlign: "right"}}>{formatStorageSize(storageActivity.last_week_size)}</td>
                <td style={{textAlign: "right"}}>{formatStorageSize(storageActivity.last_month_size)}</td>
              </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </div>
  )
}

StorageOverview.prototype = {};
StorageOverview.defaultProps = {};

export default StorageOverview;