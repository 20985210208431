import {Modal} from "react-bootstrap";

const ModelModalLayout = props => {
  const {modelShow, modelClose, title, model} = props;

  const src = `${window.location.origin}/model/preview?name=${title}&src=${model.src}`

  return (
    <div>
      <Modal show={modelShow} onHide={modelClose} centered className="fade" size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-center vh-100">
          <iframe allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                  height="100%" width="100%" title={title} src={src}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

ModelModalLayout.propTypes = {};
ModelModalLayout.defaultProps = {};

export default ModelModalLayout;