import {getComputeTransactionList} from '../api/compute';
import {getUser, getUserAddress} from "../api/user";

export const getUserAndAddress = () => {
  return getUser().then(res => {
    const user = res;
    return getUserAddress().then(res => {
      return [user, res];
    }).catch(err => {
      return Promise.reject(err);
    });
  }).catch(err => {
    return Promise.reject(err);
  });
}

export const getAllComputeTransaction = (payload, result) => {
  return getComputeTransactionList(payload).then(res => {
    if (res.data) {
      result.push(...res.data);
    }

    const new_offset = payload.pagination_options.offset + payload.pagination_options.size;
    if (res.page.item_count > new_offset) {
      const next_page = {
        pagination_options: {
          offset: new_offset,
          size: payload.pagination_options.size
        },
        created_after: payload.created_after,
        category: payload.category
      };
      return getAllComputeTransaction(next_page, result);
    }

    return result;
  }).catch(err => {
    return Promise.reject(err);
  });
}