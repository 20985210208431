import {Container, Table} from "react-bootstrap";

import Styles from "./PricingWidget.module.css";

const BottomLayout = () => {
  return (
    <Container className="my-5">
      <div className="mx-auto text-center pb-4 col-lg-8">
        <h6 className="display-6 fw-normal">Feature & Pricing details</h6>
        <p className="fs-6 text-muted">
          Some of the WeRender planned features are not yet available while in βeta phase,
          regardless of the plan chosen. We may roll out features for selected accounts along the road.
        </p>
      </div>
      <Table responsive className="text-center">
        <thead>
          <tr>
            <th scope="row" className="text-start" style={{width: "33%"}}/>
            <th style={{width: "11%"}}>Trial</th>
            <th style={{width: "12%"}}>Pro</th>
            <th style={{width: "11%"}}>Enterprise</th>
            <th style={{width: "33%"}}>Reference Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="text-start">Render image</th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>~ 5 <small>WRC</small> <small>/megapixel</small>
              <br/><small className="text-muted"><em>(actual price depends on content, pixel coverage, and settings)</em></small>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row" className="text-start">Make video</th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>10 <small>WRC</small> <small>/compute/sec</small></td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row" className="text-start">Additional storage <small><span
              className="text-normal">(TB)</span></small></th>
            <td><i className={`${Styles.BI} bi-x-circle-fill text-muted`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>6000 <small>WRC</small> /<small>TB</small></td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row" className="text-start">Data transfer <small><span
              className="text-normal">(upload/download)</span></small></th>
            <td>Limited</td>
            <td>Full</td>
            <td>Full</td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">Uploaded data persistence <small><span
              className="text-normal"></span></small></th>
            <td>1 month</td>
            <td>As long as subscription</td>
            <td>As long as subscription</td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">Query assets information</th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">Thumbnails</th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>

          <tr>
            <th scope="row" className="text-start">In-browser preview<small><sup
              className="text-danger fw-light">β</sup></small></th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">Share links</th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">Embed codes</th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">Convert to USDZ<small><sup
              className="text-danger fw-light">β</sup></small></th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">Create USDZ Materials<small><sup
              className="text-danger fw-light">β</sup></small></th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row" className="text-start">Python API</th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">C# API</th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">Javascript API<small><sup
              className="text-danger fw-light">β</sup></small></th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">REST API<small><sup
              className="text-danger fw-light">β</sup></small></th>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td><i className={`${Styles.BI} bi-check-circle`}/></td>
            <td>Free</td>
          </tr>
        </tbody>
      </Table>
      <p className="text-center text-muted small pb-0 pt-1">
        <em className="small"><sup>(<span className="text-danger">β</span>)</sup> Features not / partially available during the βeta
          phase.</em>
      </p>
    </Container>
  );
}

BottomLayout.propTypes = {};
BottomLayout.defaultProps = {};

export default BottomLayout;