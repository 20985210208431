import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {getUser} from "../../api/user";

import Styles from "./HelloWidget.module.css";
import {Button, Modal} from "react-bootstrap";

const HelloWidget = (props) => {
  const history = useHistory();

  const [user, setUser] = useState(null);

  const [showErrorMessage, setShowErrorMessage] = useState({show: false});

  useEffect(() => {
    getUser().then(res => {
      setUser(res);
    }).catch((err) => {
      setShowErrorMessage({
        show: true,
        title: "Error",
        message: "Your access is invalid, please re-login."
      });
    });
  }, [history]);

  const closeErrorMessage = () => {
    setShowErrorMessage({show: false});
    history.replace("/signin");
  }

  return (
    <div>
      <Modal show={showErrorMessage.show} centered className="fade modal-global" onHide={closeErrorMessage}>
        <Modal.Header>
          <Modal.Title>{showErrorMessage.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{showErrorMessage.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" style={{marginRight: "2px", width: "100px"}}
                  onClick={closeErrorMessage}>Close</Button>
        </Modal.Footer>
      </Modal>

      {user !== null ? (
        <h6 align="left">Hello, {user.email}</h6>
      ) : (
        <h6>Unknown</h6>
      )}
      {user !== null ? (
        <p align="left">
          Your Role is: <b>{user.role.toUpperCase()}</b> {
          user.role === "basic" ? (
            <Button as="a" target="_blank" href={process.env.REACT_APP_STRIPE_PRO_LICENSE_URL}
                    className={`${Styles.ButtonWerender} ${Styles.ButtonSmall} rounded-pill`}>Upgrade to Pro</Button>
          ) : (
            <Button href="mailto:sales@j-cube.jp" className={`${Styles.ButtonWerender} ${Styles.ButtonSmall} rounded-pill`}>{
              user.role === "pro" ? "Upgrade to Enterprise" : "Contact Sales"
            }</Button>
          )
        }</p>
      ) : (
        <p>Unknown</p>
      )}
    </div>
  );
};

HelloWidget.propTypes = {};

HelloWidget.defaultProps = {};

export default HelloWidget;
