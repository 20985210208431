import {Container, Image} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import Styles from "./HomeWidget.module.css";
import Diagram from "../../assets/img/werender-io.jpg";

const IOLayout = () => {
  const { t } = useTranslation(["home.io"]);

  return (
    <div className={Styles.BackgroundLiteGreen}>
      <Container className="py-5 col-lg-12">
        <div className="mx-auto text-center px-5">
          <h6 className="display-6 fw-normal">{t('home.io:title')}</h6>
          <p className="fs-6 text-muted">
            {t('home.io:description')}
          </p>
        </div>

        <div className="card">
          <Image src={Diagram} className="img-fluid p-1" alt="WeRender I/O"/>
        </div>
      </Container>
    </div>
  );
}

IOLayout.propTypes = {};
IOLayout.defaultProps = {};

export default IOLayout;