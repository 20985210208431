import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader} from "react-pro-sidebar";
import {useHistory} from "react-router-dom";
import {BiRightIndent, BiLeftIndent, BiBarChart, BiGroup} from "react-icons/bi";
import {MdOutlinePolicy, MdOutlinePrivacyTip} from "react-icons/md";
import {IoFileTrayStackedOutline, IoDocumentOutline, IoPaperPlaneOutline} from "react-icons/io5";
import {BsInfoCircle, BsDiagram3Fill} from "react-icons/bs";
import {useState} from "react";
import {Dropdown, Image, Button} from "react-bootstrap";

import WeRenderLogo from "../../assets/img/brand/werender_logo.png";

const SidebarWidget = (props) => {
  let history = useHistory();

  const [collapsed, setCollapsed] = useState(false);

  const handleCollapsed = () => {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  }

  const setOverviewActive = () => {
    props.setComponent("overview");
    props.handleToggleSidebar(false);
    history.push(props.match.url + "/overview");
  }

  const setComputeActive = () => {
    props.setComponent("compute");
    props.handleToggleSidebar(false);
    history.push(props.match.url + "/compute");
  }

  const setStorageActive = () => {
    props.setComponent("storage");
    props.handleToggleSidebar(false);
    history.push(props.match.url + `/storage/folder/${props.rootFolder.id}`, {path: "/"});
  }

  const setAccountActive = () => {
    props.setComponent("account");
    props.handleToggleSidebar(false);
    history.push(props.match.url + "/account");
  }

  const formatCreditInfo = (credit) => {
    return (
      <div>
        <div>Credit</div>
        <h6>
          {credit}
          <span> </span>
          <span><small><small style={{color: "#20C2AF"}}>WRC</small></small></span>
        </h6>
      </div>
    );
  };

  const formatCreditInfoCollapsed = (credit) => {
    let newCredit = credit;
    if (credit >= 1000) {
      newCredit = credit / 1000;
      newCredit = newCredit.toFixed(1) + "K";
    }

    return (
      <div>
        <div style={{color: "#20C2AF"}}>WRC</div>
        <h6>{newCredit}</h6>
      </div>
    );
  }

  const formatStorageInfo = (size, capacity) => {
    const divider = 1000.0 * 1000 * 1000;
    const volume = capacity / divider;
    const percentage = (size * 100) / capacity;
    return (
      <div>
        Storage
        <h6>
          <span>{volume.toFixed(2)} <small><small style={{color: "#20C2AF"}}>GB</small></small></span>
          <span> </span>
          <span><small>({percentage.toFixed(2)}%)</small></span>
        </h6>
      </div>
    );
  };

  const formatStorageInfoCollapsed = (capacity) => {
    const divider = 1000.0 * 1000 * 1000;
    let volume = capacity / divider;

    if (volume >= 1000) {
      volume = volume / 1000;
      volume = volume.toFixed(1) + "K";
    } else {
      volume = volume.toFixed(1);
    }

    return (
      <div>
        <div style={{color: "#20C2AF"}}>GB</div>
        <h6>{volume}</h6>
      </div>
    );
  };

  return (
    <ProSidebar collapsed={collapsed} toggled={props.toggled} breakPoint="md" onToggle={props.handleToggleSidebar}>
      <SidebarHeader onClick={handleCollapsed}>
        {
          collapsed ? (
            <BiRightIndent style={{textAlign: "center", display: "flex", justifyContent: "center", width: "2em", height: "1.6em"}}/>
          ) : (
            <div>
              <Image src={WeRenderLogo} height="30" alt="logo" className="me-2"/>
              <span style={{marginLeft: "5px", color: "#20C2AF", fontWeight: "bold"}}>WeRender</span>
              <BiLeftIndent style={{position: "absolute", right: "25px", width: "1.5em", height: "1.5em"}}/>
            </div>
          )
        }
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="round">
          <MenuItem onClick={setOverviewActive} icon={<BiBarChart/>} active={props.component === "overview"}>
            Overview
          </MenuItem>
          <MenuItem onClick={setStorageActive} icon={<IoFileTrayStackedOutline/>} active={props.component === "storage"}>
            Storage
          </MenuItem>
          <MenuItem onClick={setComputeActive} icon={<BsDiagram3Fill/>} active={props.component === "compute"}>
            Compute
          </MenuItem>
          <MenuItem onClick={setAccountActive} icon={<BiGroup/>} active={props.component === "account"}>
            Account
          </MenuItem>
        </Menu>
        <div style={{color: "#000", textAlign: "center"}}>
          <div className="large-color-bar"/>
          {
            collapsed ? (
              formatCreditInfoCollapsed(props.userCompute.credit)
            ) : (
              formatCreditInfo(props.userCompute.credit)
            )
          }
          <div className="thin-color-bar"/>
          {
            collapsed ? (
              formatStorageInfoCollapsed(props.userStorage.capacity)
            ) : (
              formatStorageInfo(props.storageUsage.size, props.userStorage.capacity)
            )
          }
          <div className="large-color-bar"/>
        </div>

      </SidebarContent>

      <SidebarFooter style={{textAlign: "center"}}>
        {
          collapsed ? (
            <div/>
          ) : (
            <Dropdown style={{marginBottom: "20px"}} drop="up">
              <Button variant="link" className="link-dark" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <BsInfoCircle className="footer-info-icon"/>
              </Button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li><a className="nav-link link-dark" href="/docs"><IoDocumentOutline/> Documentation</a></li>
                <li><a className="nav-link link-dark" href="/terms"><MdOutlinePolicy/> Terms of service</a></li>
                <li><a className="nav-link link-dark" href="/privacy"><MdOutlinePrivacyTip/> Privacy policy</a></li>
                <li><a className="nav-link link-dark" href="mailto:support@j-cube.jp"><IoPaperPlaneOutline/> Contact Support</a></li>
                <Dropdown.Divider/>
                <li><a className="nav-link link-dark" href="https://j-cube.jp/" target="_blank" rel="noreferrer">Copyright © J Cube Inc</a></li>
              </ul>
            </Dropdown>
          )
        }
      </SidebarFooter>
    </ProSidebar>
  );
}

export default SidebarWidget;