import {useHistory} from "react-router-dom";
import {Col, Navbar, Dropdown, DropdownButton, Button, Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import {FaBars, FaRegUserCircle} from "react-icons/fa";
import {BsDoorOpen} from "react-icons/bs";

import {deleteSession} from "../../api/auth";
import {getCompute} from "../../api/compute";
import {getFolder, getStorageUsage, getStorage} from "../../api/storage";
import {getUser} from "../../api/user";

import SidebarWidget from "../../components/SidebarWidget/SidebarWidget";
import OverviewWidget from "../../components/OverviewWidget/OverviewWidget";
import StorageWidget from "../../components/StorageWidget/StorageWidget";
import AccountWidget from "../../components/AccountWidget/AccountWidget";
import ComputeWidget from "../../components/ComputeWidget/ComputeWidget";

import "react-pro-sidebar/dist/css/styles.css";
import "./DashboardPage.css";

const DashboardPage = (props) => {
  let history = useHistory();

  const [toggled, setToggled] = useState(false);
  const [component, setComponent] = useState("overview");
  const [rootFolder, setRootFolder] = useState({});
  const [progressBar, setProgressBar] = useState(false);
  const [user, setUser] = useState({});
  const [compute, setCompute] = useState({});
  const [userStorage, setUserStorage] = useState({});
  const [storageUsage, setStorageUsage] = useState({});

  const [showErrorMessage, setShowErrorMessage] = useState({show: false});

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  // Sign out function.
  const signout = () => {
    deleteSession().then(() => {
      history.replace("/signin");
    }).catch(() => {
      history.replace("/signin");
    })
  };


  const getUserDashboard = () => {
    return getFolder({
      path: "/"
    }).then(async res => {
      setRootFolder(res)

      const user = await getUser();
      const user_compute = await getCompute();
      const user_storage = await getStorage();
      const storage_usage = await getStorageUsage();

      return {
        user: user,
        user_compute: user_compute,
        user_storage: user_storage,
        storage_usage: storage_usage
      }
    }).catch(err => {
      return Promise.reject(err);
    });
  }

  // Fix the URL and choose proper component.
  let paths = props.location.pathname.split("/");
  if (paths.length <= 2) {
    let url = props.match.url + "/" + component;
    history.replace(url);
  } else if (paths[2] !== "" && paths[2] !== component) {
    setComponent(paths[2]);
  }

  useEffect(() => {
    getUserDashboard().then(res => {
      setUser(res.user);
      setCompute(res.user_compute);
      setUserStorage(res.user_storage);
      setStorageUsage(res.storage_usage);
    }).catch(() => {
      setShowErrorMessage({
        show: true,
        title: "Error",
        message: "Your access is invalid, please re-login."
      });
    })
  }, [history]);

  const closeErrorMessage = () => {
    setShowErrorMessage({show: false});
    history.replace("/signin");
  }

  return (
    <div className={`dashboard ${toggled ? "toggled" : ""}`}>
      <Modal show={showErrorMessage.show} centered className="fade modal-global" onHide={closeErrorMessage}>
        <Modal.Header>
          <Modal.Title>{showErrorMessage.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{showErrorMessage.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" style={{marginRight: "2px", width: "100px"}}
                  onClick={closeErrorMessage}>Close</Button>
        </Modal.Footer>
      </Modal>

      <SidebarWidget {...props} toggled={toggled} handleToggleSidebar={handleToggleSidebar} component={component}
                     setComponent={setComponent} userCompute={compute} storageUsage={storageUsage}
                     userStorage={userStorage} rootFolder={rootFolder}/>
      <div className="dashboard-layout">
        <div className="dashboard-navbar">
          <Navbar className="navbar">
            <Navbar.Brand>
              <div style={{display: "inline-flex"}}>
                <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
                  <FaBars />
                </div>
                <b style={{paddingLeft: "10px", paddingTop: "3px"}}>{component[0].toUpperCase() + component.substr(1)}</b>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Dropdown className="navbar-trans-button dropdown px-3">
                <Button variant="link" className="nav-link link-dark" id="dropdownMenuLink" data-bs-toggle="dropdown"
                        aria-expanded="false">
                  <FaRegUserCircle className="navbar-user-button"/>
                </Button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li>
                    <Button className="nav-link link-dark" variant="link">
                      <FaRegUserCircle/> Signed in as {user.email}
                    </Button>
                   </li>
                  <li>
                    <Button className="nav-link link-dark" variant="link" onClick={signout}>
                      <BsDoorOpen/> Logout
                    </Button>
                  </li>
                </ul>
              </Dropdown>
              <DropdownButton variant="dark" title="EN" size="sm" className="navbar-trans-button dropdown-no-caret px-3">
                <Dropdown.Item href="#/action-1">English</Dropdown.Item>
                {/*<Dropdown.Item href="#/action-2">Japanese</Dropdown.Item>*/}
              </DropdownButton>
              <Button variant="light" size="sm" className="" href="https://werender.io">
                Home
              </Button>
            </Navbar.Collapse>
          </Navbar>
          {
            progressBar ? (
              <div className="loading-bar"/>
            ) : (
              <div className="navbar-color-bar"/>
            )
          }

        </div>

        <main className="ox-hidden">
          <Col>
            <div id="dashboard-content" style={{marginTop: "24px"}} className={(progressBar ? "v-hidden" : "")}>
              {
                component === "overview" ? (
                  <OverviewWidget {...props} />
                ) : component === "storage" ? (
                  <StorageWidget {...props} setProgressBar={setProgressBar}/>
                ) : component === "compute" ? (
                  <ComputeWidget {...props} setProgressBar={setProgressBar}/>
                ) : component === "account" ? (
                  <AccountWidget {...props} setProgressBar={setProgressBar}/>
                ) : (
                  <h1>Unknown</h1>
                )
              }
            </div>
          </Col>
        </main>
      </div>
    </div>
  );
};

DashboardPage.propTypes = {};
DashboardPage.defaultProps = {};

export default DashboardPage;
