import { React, Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import "./index.css";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <CookiesProvider>
      <Suspense fallback={<Spinner animation="grow" variant="success"/>}>
        <App />
      </Suspense>
    </CookiesProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();