import validator from "validator";

export const emailValidator = (email) => {
  return {
    isEmpty: validator.isEmpty(email),
    isInvalid: !validator.isEmail(email)
  }
}

export const passwordValidator = (password) => {
  return {
    isEmpty: validator.isEmpty(password)
  }
}