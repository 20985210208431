import {Image, Modal} from "react-bootstrap";

const ImageModalLayout = props => {
  const {imageShow, imageClose, title, image} = props;

  return (
    <div>
      <Modal show={imageShow} onHide={imageClose} centered className="fade" size="xl">
        <Modal.Header closeButton>
          <Modal.Title><h5>{title}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-center">
          <Image src={image.src} className="img-fluid w-100" alt={title}/>
          <div className="carousel-caption d-none d-md-block">
            {image.tags?.map((tag, index) => (
              <kbd key={index} className="text-white bg-dark" style={{margin: "3px"}}><small>{tag}</small></kbd>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

ImageModalLayout.propTypes = {};
ImageModalLayout.defaultProps = {};

export default ImageModalLayout;