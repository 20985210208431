import Styles from "./PricingWidget.module.css";
import {Card, Carousel, Col, Container, Image, Row} from "react-bootstrap";

const TopLayout = () => {
  return (
    <div className={Styles.BackgroundTop}>
      <Container className="pb-5">
        <Row className="flex-lg-row align-items-center g-5">
          <Col lg={6}>
            <h1 className="display-5 lh-1 mb-3">Pricing for anyone</h1>
            <p className="lead">
              Pick the best solution for your needs. <b>Trial</b> is for evaluation purposes and comes with some free credits. <b>Pro</b> is a subscription that fits the need of
              professionals and small to mid business. With <b>Enterprise</b> you get complete flexibility and an adjusted
              bill at month-end.
            </p>
            <Card className={`p-3 bg-light`}>
            <Card.Subtitle as="p" className={`${Styles.Subtitle} ${Styles.LineHeight15} text-dark`}>
              Costs are visible after each compute operation, both in the web UI and CLI.
              They are presented in <small>"WRC"</small>, short for <em>WeRender Credits</em>,
              and they are exchanged at the rate of <b>1 <small>USD</small> = 100 <small>WRC</small></b>.
            </Card.Subtitle>
            </Card>
          </Col>
          <Col>
            <Card bg="light" className="p-3">
              <Carousel className={`rounded shadow ${Styles.CarouselInner}`} id="carouselPricing">
                <Carousel.Item>
                  <Image src={"https://raw.githubusercontent.com/j-cube/werender-examples/release/explore/by-use-case/render-usdz-assets/py/shoes_usdz.png"} className={`img-fluid rounded ${Styles.CarouselItem}`} alt="..."/>
                  <Carousel.Caption className={`${Styles.CarouselCaption} d-none d-md-block`}>
                    <kbd className="text-white bg-dark">5 <small>WRC</small></kbd> <kbd className="text-white bg-dark">1 <small>Mpx</small></kbd>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={"https://raw.githubusercontent.com/j-cube/werender-examples/release/explore/by-use-case/render-360-panorama/py/flat_360cam.png"} className={`img-fluid rounded ${Styles.CarouselItem}`} alt="..."/>
                  <Carousel.Caption className={`${Styles.CarouselCaption} d-none d-md-block`}>
                    <kbd className="text-white bg-dark">10 <small>WRC</small></kbd> <kbd className="text-white bg-dark">2 <small>Mpx</small></kbd>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={"https://raw.githubusercontent.com/j-cube/werender-examples/release/explore/by-use-case/render-assets/py/maneki_and_torii.jpg"} className={`img-fluid rounded ${Styles.CarouselItem}`} alt="..."/>
                  <Carousel.Caption className={`${Styles.CarouselCaption} d-none d-md-block`}>
                    <kbd className="text-white bg-dark">5 <small>WRC</small></kbd> <kbd className="text-white bg-dark">1 <small>Mpx</small></kbd>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={"https://raw.githubusercontent.com/j-cube/werender-examples/release/explore/by-use-case/render-variations/py/montage.jpg"} className={`img-fluid rounded ${Styles.CarouselItem}`} alt="..."/>
                  <Carousel.Caption className={`${Styles.CarouselCaption} d-none d-md-block`}>
                    <kbd className="text-white bg-dark">12<small>x</small>5 <small>WRC</small></kbd> <kbd className="text-white bg-dark">12 <small>Mpx</small></kbd>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={"https://raw.githubusercontent.com/j-cube/werender-examples/release/explore/by-industry/nft/py/nft_thecube.jpg"} className={`img-fluid rounded ${Styles.CarouselItem}`} alt="..."/>
                  <Carousel.Caption className={`${Styles.CarouselCaption} d-none d-md-block`}>
                    <kbd className="text-white bg-dark">10 <small>WRC</small></kbd> <kbd className="text-white bg-dark">2 <small>Mpx</small></kbd>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={"https://raw.githubusercontent.com/j-cube/werender-examples/release/explore/by-industry/automotive/py/automotive_porsche911.jpg"} className={`img-fluid rounded ${Styles.CarouselItem}`} alt="..."/>
                  <Carousel.Caption className={`${Styles.CarouselCaption} d-none d-md-block`}>
                    <kbd className="text-white bg-dark">30 <small>WRC</small></kbd> <kbd className="text-white bg-dark">6 <small>Mpx</small></kbd>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

TopLayout.propTypes = {};
TopLayout.defaultProps = {};

export default TopLayout;