import {useState} from "react";
import {Button, Card, Col, Container, Form, Image, Modal, Row, Spinner} from "react-bootstrap";
import {useHistory} from "react-router-dom";

import {forgetPassword} from "../../api/user";

import {emailValidator} from "../../utils/validator";

import HomeFooterTransparent from "../../components/HomeFooter/HomeFooterTransparent";

import "../../styles/Modal.css";
import "./ResetPasswordPage.css";

import WerenderLogo from "../../assets/img/brand/werender_logo.svg";

const ResetPasswordPage = () => {
  let history = useHistory();

  const [email, setEmail] = useState("");

  const [emailValidation, setEmailValidation] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);

  const [resetPasswordResult, setResetPasswordResult] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const showResetPasswordResult = () => setResetPasswordResult(true);

  const closeResetPasswordResult = () => {
    setResetPasswordResult(false);
    history.replace("/password/reset");
  }

  const emailErrorClear = () => {
    setEmailRequired(false);
    setEmailValidation(false);
  }

  const reset = () => {
    setLoadingButton(true);

    const emailError = emailValidator(email);

    // Skip the process if the error is true
    if (emailError.isEmpty || emailError.isInvalid) {
      setEmailRequired(emailError.isEmpty);
      if (!emailError.isEmpty) {
        setEmailValidation(emailError.isInvalid);
      }
      setLoadingButton(false);
      return
    }

    forgetPassword(email).then(() => {
      setLoadingButton(false);
      showResetPasswordResult();
    }).catch(err => {
      setLoadingButton(false);
      alert(err);
    });
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="reset-password py-5">
        <main className="flex-shrink-0">
          <Container className="col-xl-10 col-xxl-8 px-4 py-4">
            <Row className="align-items-center g-lg-5 py-5">
              <Col md={6} lg={6} className="mx-auto">
                <p className="lead">
                  <a href="/" className="d-flex align-items-center col-md-3 mb-md-0 text-dark text-decoration-none">
                    <Image src={WerenderLogo} height="30" className="me-2"/>WeRender
                  </a>
                </p>
                <Card className="p-5 shadow-lg">
                  <Form>
                    <legend>Reset your password</legend>
                    <Form.Floating>
                      <Form.Control type="email" id="reset-password-email"
                                    defaultValue={email}
                                    placeholder="name@example.com"
                                    style={{border: emailValidation || emailRequired ? "2px solid red" : ""}}
                                    onClick={emailErrorClear}
                                    onChange={(e) => {setEmail(e.target.value)}}/>
                      <Form.Label htmlFor="reset-password-email">Email</Form.Label>
                      {
                        emailRequired ? (
                          <Form.Text className="text-danger" style={{ color: "red" }}>The email is required</Form.Text>
                        ) : ("")
                      }
                      {
                        emailValidation ? (
                          <Form.Text className="text-danger" style={{ color: "red" }}>The email is not valid</Form.Text>
                        ) : ("")
                      }
                    </Form.Floating>
                    <br/>
                    <Button className="w-100 btn btn-sm rounded-pill mx-auto btn-wr" onClick={reset} disabled={loadingButton}>
                      {loadingButton ? <Spinner as="span" size="sm" role="status" animation="border" variant="light"/> : ""}Continue
                    </Button>
                    <Button variant="outline-dark" href="/signin" className="w-100 btn btn-sm rounded-pill mx-auto margin-2">
                      Return to Sign In</Button>
                    <hr className="my-4"/>
                    <small className="text-muted align-items-center">Don't have an account? <a href="/signup">Sign up.</a></small>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </main>
      </div>

      <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
        <HomeFooterTransparent/>
      </div>

      <Modal show={resetPasswordResult} onHide={closeResetPasswordResult} centered className="fade modal-global">
        <Modal.Header className="p-1" style={{position: "relative"}}>
          <h5 style={{marginLeft: "40px", marginTop: "10px"}}>Reset Password</h5>
        </Modal.Header>
        <Modal.Body className="p-0 align-center">
          <br/>
          <p style={{marginLeft: "10px", marginRight: "10px"}}>A token was sent to <b>{email}</b>, use this token to reset your password</p>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" style={{marginRight: "2px"}} onClick={closeResetPasswordResult}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ResetPasswordPage.propTypes = {};
ResetPasswordPage.defaultProps = {};

export default ResetPasswordPage;