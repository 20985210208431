import axios from "axios";

import {getModelHierarchyById} from "../api/asset";

const getHierarchyChild = (parent, models) => {
  let children = [];
  if (parent.children_count > 0) {
    const start = parent.first_child_offset;
    const end = parent.first_child_offset + parent.children_count;

    models.slice(start, end).forEach((value) => {
      value.path = parent.name === "/" ?
        parent.name + value.name : parent.path + "/" + value.name;
      children.push(getHierarchyChild(value, models));
    })
    parent.children = children;
  }

  return parent;
}

export const getModelHierarchy = (id) => {
  return getModelHierarchyById(id).then(res => {
    return axios.get(res.url).then(res => {
      let models = [];

      res.data.names.forEach((value, index) => {
        let model = {
          index: index,
          name: value,
        };

        if (res.data.children_count.length > index) {
          model.children_count = res.data.children_count[index];
        }

        if (res.data.first_child_offset.length > index) {
          model.first_child_offset = res.data.first_child_offset[index];
        }

        if (res.data.type_indices) {
          if (res.data.types) {
            model.type = res.data.types[res.data.type_indices[index]];
          }
        }

        models.push(model);
      });

      if (models) {
        return getHierarchyChild(models[0], models);
      }

      return models;
    }).catch(err => {
      return Promise.reject(err);
    });
  }).catch(err => {
    return Promise.reject(err);
  })
}