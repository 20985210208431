import dateFormat from "dateformat";

export const formatState = (cell, row) => {
  if (cell === 0) {
    return 'Created';
  } else if (cell === 1) {
    return 'Enqueued';
  } else if (cell === 2) {
    return 'Cancelled';
  } else if (cell === 3) {
    return 'Started';
  } else if (cell === 11) {
    return 'Preparing';
  } else if (cell === 12) {
    return 'Prepared';
  } else if (cell === 13) {
    return 'Prepare Failed';
  } else if (cell === 21) {
    return 'Render';
  } else if (cell === 22) {
    return 'Rendered';
  } else if (cell === 23) {
    return 'Render Failed or Stopped';
  } else if (cell === 31) {
    return 'Finishing';
  } else if (cell === 32) {
    return 'Finish Failed';
  } else if (cell === 100) {
    return 'Finished';
  }

  return cell;
}

export const formatTimestamp = (cell, row) => {
  const date = new Date(cell * 1000);
  return dateFormat(date, 'yyyy/mm/dd @ HH:mm:ss');
};

export const getFilteredDate = (filter) => {
  if (filter === "month") {
    let date = new Date();
    date.setDate(1);
    date.setUTCHours(0,0,0,0);

    return Math.round(date.getTime() / 1000);
  } else if (filter === "week") {
    // Calculate the local monday from current time.
    let date = new Date();
    date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1));
    date.setUTCHours(0,0,0,0);

    return Math.round(date.getTime() / 1000);
  } else if (filter === "today") {
    // Last 24 hours.
    let date = new Date();
    date.setUTCHours(-24);

    return Math.round(date.getTime() / 1000);
  }

  return null;
}