import { useEffect, useState } from "react";
import {Button, Card, Col, Row, Spinner, Table} from "react-bootstrap";
import {Bar} from "react-chartjs-2";
import {useLocation} from "react-router-dom";
import { TbFaceIdError } from "react-icons/tb";

import {getCompute, findComputeTransaction} from "../../api/compute";
import {getNextRenderPage} from "../../services/render";
import {getAllComputeTransaction} from "../../services/user";

import Styles from "./ComputeCreditWidget.module.css";

import "../../styles/button.css";

const formatTimestampWithoutYear = (u) => {
  let date = new Date(u * 1000);
  let day = date.getDate();
  if (day < 10) {
    day = "0" + date.getDate();
  }

  return [date.getMonth() + 1, day].join("/");
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ComputeCreditWidget = (props) => {
  let query = useQuery();

  const [refresh, setRefresh] = useState(0);
  const [renderCompute, setRenderCompute] = useState([]);
  const [userCompute, setUserCompute] = useState({});
  const [userComputeUsage, setUserComputeUsage] = useState([]);

  const [showRetryButton, setShowRetryButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const getAvgComputeUsage = (data) => {
    let totalUsage = 0;

    if (!data) {
      return 0
    }

    if (data.length < 1) {
      return 0;
    }

    Array.from(data).forEach((d) => {
      totalUsage += Math.abs(d.credit);
    });

    return totalUsage / data.length;
  }

  const getTotalCreditUsed = (data) => {
    let totalUsed = 0;

    Array.from(data).forEach((d) => {
      if (d.credit < 0) {
        totalUsed += Math.abs(d.credit);
      }
    });

    return totalUsed;
  }

  useEffect(() => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDayInSeconds = Math.round(firstDay.getTime() / 1000)

    getNextRenderPage({
      pagination_options: {
        offset: 0,
        size: 100
      },
      created_after: firstDayInSeconds
    }, []).then(async res => {
      let renderIds = [];

      for await (const r of res.map((value) =>
        value.id
      )) {
        renderIds.push(r);
      }
      if (renderIds.length > 0) {
        const compute_trx = await findComputeTransaction({
          "render_ids": renderIds
        });
        setRenderCompute(compute_trx);
      }

      const user_compute = await getCompute();
      setUserCompute(user_compute);

      const start_date = parseInt((firstDay.getTime() / 1000).toFixed(0));
      // Get charged WRC.
      const charged_compute = await getAllComputeTransaction({
        pagination_options: {
          offset: 0,
          size: 100
        },
        created_after: start_date
      }, []);

      // Get render usage.
      const render_usage = await getAllComputeTransaction({
        pagination_options: {
          offset: 0,
          size: 100
        },
        created_after: start_date,
        category: "render"
      }, charged_compute);

      // Get asset usage.
      const asset_usage = await getAllComputeTransaction({
        pagination_options: {
          offset: 0,
          size: 100
        },
        created_after: start_date,
        category: "asset"
      }, render_usage);

      // Put the final result to the state.
      setUserComputeUsage(asset_usage);

      setLoadingButton(false);
      setShowRetryButton(false);
    }).catch(() => {
      setLoadingButton(false);
      setShowRetryButton(true);
    });
  }, [refresh]);

  const retry = () => {
    setLoadingButton(true);

    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const getMetrics = (d) => {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    let charged = new Map();
    let dateQuery = new Date();
    let usage = new Map();

    if (query.get("start")) {
      dateQuery = new Date(query.get("start"));
      if (isNaN(dateQuery.getMonth())) {
        dateQuery = new Date();
        dateQuery.setFullYear(today.getFullYear(), today.getMonth(), 1);
      }
    } else {
      dateQuery.setFullYear(today.getFullYear(), today.getMonth(), 1);
    }

    for (let d = dateQuery; d <= lastDay; d.setDate(d.getDate() + 1)) {
      charged.set(formatTimestampWithoutYear(d.getTime() / 1000), 0);
      usage.set(formatTimestampWithoutYear(d.getTime() / 1000), 0);
    }

    Array.from(d).forEach((c) => {
      let date = formatTimestampWithoutYear(c.created_at);
      let charged_val = charged.get(date);
      let usage_val = usage.get(date);
      if (usage.has(date) && c.credit < 0) {
        usage.set(date, usage_val - c.credit);
      }
      if (charged.has(date) && c.credit > 0) {
        charged.set(date, charged_val + c.credit);
      }
    });

    return {
      'charged':  new Map([...charged.entries()].sort()),
      'usage': new Map([...usage.entries()].sort())
    };
  };

  const computeMetrics = getMetrics(userComputeUsage);

  const computeChartMetric = {
    labels: Array.from(computeMetrics.usage.keys()),
    datasets: [{
      label: "# WRC Charged",
      data: Array.from(computeMetrics.charged.values()),
      fill: false,
      backgroundColor: "rgb(75, 192, 192)",
      borderColor: "rgba(75, 192, 192, 0.2)",
    }, {
      label: "# WRC Usage",
      data: Array.from(computeMetrics.usage.values()),
      fill: false,
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgba(255, 99, 132, 0.2)",
    }],
  };

  const computeChartOptions = {
    maintainAspectRatio: true,
    responsive: true
  };

  return (
    <div>
      <Row className="g-5">
        <Col>
          <h6>COMPUTE CREDITS (WRC)</h6>
        </Col>
        <Col style={{textAlign: "right"}}>
          <a style={{color: "#20C2AF"}} href="/dashboard/account">BUY MORE</a>
        </Col>
      </Row>

      {showRetryButton ? (
        <Row className="text-center justify-content-center">
          <TbFaceIdError style={{height: "100px"}}/>
          <h6>Sorry, We are failed to get compute data, Click the Retry button to retry.</h6>
          <Button className="btn-wr btn-sm rounded-pill" style={{width: "100px"}} disabled={loadingButton} onClick={retry}>
            {loadingButton ? <Spinner as="span" size="sm" role="status" animation="border" variant="light"/> : ""} Retry
          </Button>
        </Row>
      ) : (
        <Card style={{height: "100%"}} className="g-5">
          <Card.Body>
            <Row style={{textAlign: "center"}}>
              <h6>AVAILABLE CREDITS: {userCompute.credit} WRC</h6>
            </Row>
            <div style={{height: "2px", backgroundColor: "#20C2AF"}}/>
            <Table responsive>
              <tbody>
                <tr>
                  <td>Credits used</td>
                  <td style={{textAlign: "right"}}>{getTotalCreditUsed(userComputeUsage)} WRC</td>
                </tr>
                <tr>
                  <td>Avg. cost per render</td>
                  <td style={{textAlign: "right"}}>{Math.ceil(getAvgComputeUsage(renderCompute))} WRC</td>
                </tr>
              </tbody>
            </Table>

            <div className={`${Styles.ChartContainer}`}>
              <Bar id="user-compute-credit-chart" options={computeChartOptions} data={computeChartMetric}/>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

ComputeCreditWidget.propTypes = {};

ComputeCreditWidget.defaultProps = {};

export default ComputeCreditWidget;
