import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {Col, Card, Container, Row} from "react-bootstrap";
import PuffLoader from "react-spinners/PuffLoader";
import {useTranslation} from 'react-i18next';
import {activateUser} from "../../api/user";

import "./ActivationPage.css";

import HomeFooterTransparent from "../../components/HomeFooter/HomeFooterTransparent";

const ActivationPage = (props) => {
  const { t } = useTranslation(["activation"]);

  const history = useHistory();

  // 0 = Checking, 1 = Successful, 2 = Failed
  const STAGE_CHECKING = 0;
  const STAGE_SUCCESSFUL = 1;
  const STATE_FAILED = 2;
  const [stage, setStage] = useState(STAGE_CHECKING);

  useEffect(() => {
    // Parse query string.
    const params = new URLSearchParams(window.location.search);
    const activationId = params.get("activation_id");
    const userId = params.get("user_id");
    if (activationId === null || userId === null) {
      return <Redirect to="/" />;
    }

    // Call API to check.
    activateUser(activationId, userId).then(() => {
      setStage(STAGE_SUCCESSFUL);

      // Jump to dashboard.
      setTimeout(() => {
        history.replace("/signin");
      }, 3000);
    }).catch(() => {
      setStage(STATE_FAILED);

      // Back to main page after 3 seconds.
      setTimeout(() => {
        history.replace("/");
      }, 3000);
    });
  }, [history]);

  return (
    <div className="activation-page d-flex flex-column h-100">
      <div className="py-5">
        <main className="flex-shrink-0">
          <Container className="col-xl-10 col-xxl-8 px-4 py-4">
            <Row className="align-items-center g-lg-5 py-5">
              <Col md={6} lg={6} className="mx-auto">
                {stage === STAGE_CHECKING ? (<div>
                  <p className="lead">
                    <h5>{t('activation:please_wait')}</h5>
                  </p>
                  <p className="lead">
                    <h5>{t('activation:checking')}</h5>
                  </p>
                </div>) : ""}
                {stage === STAGE_SUCCESSFUL ? (<div>
                  <p className="lead">
                    <h5>{t('activation:activated')}</h5>
                  </p>
                </div>) : ""}
                {stage === STATE_FAILED ? (<div>
                  <p className="lead">
                    <h5>{t('activation:failed')}</h5>
                  </p>
                </div>) : ""}
                <Card className="p-5 align-items-center" style={{backgroundColor: "transparent", borderColor: "transparent"}}>
                  <PuffLoader color={stage !== STAGE_CHECKING ? (stage === STAGE_SUCCESSFUL ? "blue" : "red") : "black"}/>
                </Card>
              </Col>
            </Row>
          </Container>
        </main>
      </div>

      <div style={{position: "absolute", bottom: 0, width: "100%"}}>
        <HomeFooterTransparent/>
      </div>
    </div>
  );
};

ActivationPage.propTypes = {};
ActivationPage.defaultProps = {};

export default ActivationPage;
