import { Environment, OrbitControls } from "@react-three/drei";
import { useLoader, Canvas } from "@react-three/fiber";
import { useEffect, useState, Suspense } from "react";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

const AppleUsdzViewer = (src, thumbnail) => {
  let imgSrc = null;
  if (thumbnail) {
    imgSrc = thumbnail;
  } else {
    imgSrc = "/assets/img/share.png";
  }

  return (
    <div id="apple-usdz-viewer">
      <a rel="ar" href={src}>
        <img src={imgSrc} alt="thumbnail" />
      </a>
    </div>
  );
};

const ThreeJsCanvas = (src, loaderType) => {
  // Refers to https://gracious-keller-98ef35.netlify.app/docs/api/hooks/useLoader/
  // Use gstatic instead of local as https://stackoverflow.com/questions/56071764/how-to-use-dracoloader-with-gltfloader-in-reactjs
  let loader = useLoader(loaderType, src, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader(dracoLoader);
  });

  // TODO: Here is a workaround for the canvas size. For some reasons the
  // height of canvas is always 150px.
  const fillStyle = { width: "100%", height: "100%", minHeight: "512px" };
  return (
    <div id="three-js-canvas" style={fillStyle}>
      <Canvas style={fillStyle}>
        <Suspense fallback={null}>
          <primitive object={loader.scene} />
          <OrbitControls />
          <Environment preset="sunset" background />
        </Suspense>
      </Canvas>
    </div>
  );
};

const ThreeJsModelViewer = (name, src, thumbnail) => {
  let isGltf = name.endsWith(".glb") || name.endsWith(".gltf");
  let isObj = name.endsWith(".obj");
  if (isGltf) {
    return ThreeJsCanvas(src, GLTFLoader);
  } else if (isObj) {
    return ThreeJsCanvas(src, OBJLoader);
  }

  return <></>;
};

const ThreeViewer = (props) => {
  const [isThreeJsOk, setIsThreeJsOk] = useState(false);
  const [isAppleArOk, setIsAppleArOk] = useState(false);

  useEffect(() => {
    let isGltf = props.name.endsWith(".glb") || props.name.endsWith(".gltf");
    let isObj = props.name.endsWith(".obj");
    setIsThreeJsOk(isGltf || isObj);

    // Use Apple AR for all .usdz files for now.
    let isUsdz = props.name.endsWith(".usdz");
    setIsAppleArOk(isUsdz);
  }, [props]);

  // Check the name and decide which loader to use.
  if (isThreeJsOk) {
    return ThreeJsModelViewer(props.name, props.src, props.thumbnail);
  } else if (isAppleArOk) {
    return AppleUsdzViewer(props.src, props.thumbnail);
  } else {
    return <></>;
  }
};

ThreeViewer.propTypes = {};
ThreeViewer.defaultProps = {};

export default ThreeViewer;
