import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import "../../styles/Modal.css";

const ErrorWidget = (props) => {
  const { t } = useTranslation(["translation"]);

  const closeErrorMessage = () => props.setShowErrorMessage({show: false});

  return (
    <div id="error-message" className="error-widget">
      <Modal show={props.showErrorMessage.show} centered className="fade modal-global" onHide={closeErrorMessage}>
        <Modal.Header>
          <Modal.Title>{props.showErrorMessage.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.showErrorMessage.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" style={{marginRight: "2px", width: "100px"}}
                  onClick={closeErrorMessage}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ErrorWidget.propTypes = {};
ErrorWidget.defaultProps = {};

export default ErrorWidget;