import { BrowserRouter, Route, Switch } from "react-router-dom";

import ActivationPage from "./pages/ActivationPage/ActivationPage.js";
import DashboardPage from "./pages/DashboardPage/DashboardPage.js";
import ExplorePage from "./pages/ExplorePage/ExplorePage";
import HomePage from "./pages/HomePage/HomePage.js";
import PricingPage from "./pages/PricingPage/PricingPage.js";
import SigninPage from "./pages/SigninPage/SigninPage.js";
import SignupPage from "./pages/SignupPage/SignupPage.js";
import SharePage from "./pages/SharePage/SharePage.js";
import EmbedPage from "./pages/EmbedPage/EmbedPage";
import DocumentPage from "./pages/DocumentPage/DocumentPage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import UpdatePasswordPage from "./pages/UpdatePasswordPage/UpdatePasswordPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import TermsPage from "./pages/TermsPage/TermsPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";

import './App.css';

function App() {
  return (
    <div id="app">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route path="/404" component={NotFoundPage}/>
          <Route path="/activation" component={ActivationPage}/>
          <Route path="/dashboard" component={DashboardPage}/>
          <Route path="/docs" component={DocumentPage}/>
          <Route path="/explore" component={ExplorePage}/>
          <Route path="/pricing" component={PricingPage}/>
          <Route path="/password/forget" component={ResetPasswordPage}/>
          <Route path="/password/reset" component={UpdatePasswordPage}/>
          <Route path="/privacy" component={PrivacyPage}/>
          <Route path="/signin" component={SigninPage}/>
          <Route path="/signup" component={SignupPage}/>
          <Route path="/share/:shareId" component={SharePage}/>
          <Route path="/embed/:shareId" component={EmbedPage}/>
          <Route path="/model/preview" component={EmbedPage}/>
          <Route path="/terms" component={TermsPage}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
