import {Button, Col, Container, Image, Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import Styles from "./HomeWidget.module.css";

import FBXLogo from "../../assets/img/logos/fbx_logo.png";
import USDLogo from "../../assets/img/logos/usd_logo.png";
import USDZLogo from "../../assets/img/logos/usdz_logo.png";
import AlembicLogo from "../../assets/img/logos/alembic_logo.png";
import STLLogo from "../../assets/img/logos/stl_logo.png";
import STPLogo from "../../assets/img/logos/stp_logo.png";
import IGSLogo from "../../assets/img/logos/igs_logo.png";
import IFCLogo from "../../assets/img/logos/ifc_logo.png";
import OBJLogo from "../../assets/img/logos/obj_logo.png";
import VDBLogo from "../../assets/img/logos/openvdb_logo.png";
import JPGLogo from "../../assets/img/logos/jpg_logo.png";
import PNGLogo from "../../assets/img/logos/png_logo.png";
import TIFFLogo from "../../assets/img/logos/tiff_logo.png";
import OpenEXRLogo from "../../assets/img/logos/openexr_logo.png";
import HDRLogo from "../../assets/img/logos/hdr_logo.png";

const AssetLayout = () => {
  const { t } = useTranslation(["translation", "home.asset"]);

  return (
    <div className={Styles.BackgroundWhite}>
      <Container className="py-5">
        <Col lg={8} className="mx-auto">
          <h4 className="display-6 text-center">{t('home.asset:title')}</h4>
          <p className="fs-5 mb-4 text-secondary text-center">
            <small>{t('home.asset:description')}</small>
          </p>
          <Nav as="ul" className="row row-cols-5 list-unstyled list">
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://www.autodesk.com/products/fbx/overview"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={FBXLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.fbx</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://graphics.pixar.com/usd/release/index.html"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={USDLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.usd .usdc .usda</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://graphics.pixar.com/usd/release/spec_usdz.html"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={USDZLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.usdz</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://www.alembic.io/"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={AlembicLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.abc</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://en.wikipedia.org/wiki/STL_(file_format)"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={STLLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.stl</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://en.wikipedia.org/wiki/ISO_10303-21"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={STPLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.stp .step</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://en.wikipedia.org/wiki/IGES"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={IGSLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.igs</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://en.wikipedia.org/wiki/Industry_Foundation_Classes"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={IFCLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.ifc</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://en.wikipedia.org/wiki/Wavefront_.obj_file"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={OBJLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.obj</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://www.openvdb.org/"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={VDBLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.vdb (after βeta)</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://jpeg.org/"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={JPGLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.jpg .jpeg</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://en.wikipedia.org/wiki/Portable_Network_Graphics"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={PNGLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.png</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://www.adobe.io/open/standards/TIFF.html"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={TIFFLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.tif .tiff</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://www.openexr.com/"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={OpenEXRLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.exr</code>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mb-4" data-tags="pulse heartbeat rhythm" data-categories="data">
              <a className="d-block text-dark text-decoration-none" href="https://en.wikipedia.org/wiki/RGBE_image_format"
                 target="_blank" rel="noreferrer">
                <div className={`${Styles.BackgroundWhite} bg-white text-center rounded img-thumbnail`}>
                  <Image src={HDRLogo} width={100} className="img-fluid p-0" alt="..."/>
                </div>
                <div className="name text-decoration-none text-center pt-1">
                  <code className="text-muted">.hdr</code>
                </div>
              </a>
            </Nav.Item>
          </Nav>
        </Col>
        <p className="text-muted text-center mb-2">{t('home.asset:format')}?</p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
          <Button as="a" variant="outline-dark" href="mailto:sales@werender.io" className={`${Styles.ButtonOutlineDark} rounded-pill`}>{t('contact_us')}</Button>
        </div>
      </Container>
    </div>
  );
}

AssetLayout.propTypes = {};
AssetLayout.defaultProps = {};

export default AssetLayout;