import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Col, FormControl, InputGroup, Modal, Nav, Row} from "react-bootstrap";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import {ControlledMenu, MenuItem, useMenuState} from "@szhsin/react-menu";
import {TbFaceIdError} from "react-icons/tb";
import {BsInfoCircle} from "react-icons/bs";
import {VscCopy} from "react-icons/vsc";
import copy from "copy-to-clipboard";
import {FiLink} from "react-icons/fi";

import {formatState, formatTimestamp, getFilteredDate} from "../../utils/table";
import {pageButtonRenderer, sizePerPageRenderer, sortCaretFormatter} from "../BoostrapTable/BootstrapTable";
import {defaultSizePerPage} from "../../constants/bootstrapTable";
import {getOffsetFromPageNumber} from "../../utils/pagination";
import {getRenderList, getRenderPaginate} from "../../api/render";
import {findComputeTransaction} from "../../api/compute";
import {getFiles} from "../../api/storage";
import {paginationOrderConverter} from "../../utils/parser";
import dateFormat from "dateformat";
import {getAssetList} from "../../api/asset";

import "./ComputeWidget.css";

const ComputeWidget = (props) => {
  const history = useHistory();

  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [showComputeInfo, setShowComputeInfo] = useState(false);
  const [showRetryButton, setShowRetryButton] = useState(false);

  // Table components.
  const [sortTable, setSortTable] = useState("created_at");
  const [orderTable, setOrderTable] = useState("desc");
  const [sizePerPage, setSizePerPage] = useState(10);
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [selectedRender, setSelectedRender] = useState([]);
  const [filterTab, setFilterTab] = useState("all");
  const [computeTab, setComputeTab] = useState("render");

  // Context menu.
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useMenuState();
  const [contextSelectedRender, setContextSelectedRender] = useState({});

  const setRenderCreditAndName = (credit, renders, files) => {
    let res = [];

    Array.from(renders).forEach((d) => {
      d.credit = '0 WRC';

      if (credit) {
        credit.find((element, index) => {
          if (d.id === element.render_id) {
            d.credit = element.credit + ' WRC';
            return true;
          }
          return false;
        });
      }

      if (files) {
        files.find((element, index) => {
          if (d.outputs && Array.isArray(d.outputs)) {
            if (d.outputs[0].storage_node_id === element.id) {
              d.finalized_at = element.finalized_at;
              d.name = element.name;
              d.parent = element.parent;
              d.node_id = element.id;
              return true;
            }
          }
          return false;
        });
      }

      res.push(d);
    });

    setData(res);

    // Finish the progress bar.
    props.setProgressBar(false);
    setShowRetryButton(false);
  }

  const getComputeTransaction = (params) => {
    let nodeIds = [];
    let renderIds = [];
    Array.from(params).forEach((d) => {
      renderIds.push(d.id);

      if (d.outputs && Array.isArray(d.outputs)) {
        nodeIds.push(d.outputs[0].storage_node_id);
      }
    });

    const data = {
      "render_ids": renderIds
    };
    findComputeTransaction(data).then(async res => {
      const r = await getFiles(nodeIds);

      setRenderCreditAndName(res, params, r);
    }).catch(() => {
      props.setProgressBar(false);
      setShowRetryButton(true);
    });
  };

  const getRender = (payload) => {
    getRenderList(payload).then(res => {
      setTotalSize(res.page.item_count);

      const renders = res.data ? res.data : [];
      getComputeTransaction(renders);

      const params = new URLSearchParams(history.location.search);
      params.set("page", tableCurrentPage);

      history.replace(`${history.location.pathname}?${params.toString()}`);
    }).catch(() => {
      props.setProgressBar(false);
      setShowRetryButton(true);
    });
  }

  const renderPageHandler = (render_id) => {
    getRenderPaginate(render_id, {
      column: "created_at",
      order: "descending",
      size: 10
    }).then(res => {
      const params = new URLSearchParams(history.location.search);
      params.set("page", res.page_number);
      params.delete("render_id");

      history.replace(`${history.location.pathname}?${params.toString()}`);

      setSelectedRender([render_id]);
      setTableCurrentPage(res.page_number);

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }).catch(() => {
      history.replace(`/dashboard/render`);

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    });
  }

  const getAssets = (payload) => {
    getAssetList(payload).then(async res => {
      setTotalSize(res.page.item_count);

      const params = new URLSearchParams(history.location.search);
      params.set("page", tableCurrentPage);

      history.replace(`${history.location.pathname}?${params.toString()}`);

      const assets = res.data ? res.data : [];

      let asset_ids = [];
      let file_ids = [];

      Array.from(assets).forEach((a) => {
        asset_ids.push(a.id);
        if (Array.isArray(a.outputs) && a.outputs.length) {
          if (a.outputs[0].storage_node_id) {
            file_ids.push(a.outputs[0].storage_node_id);
          }
        }
      });

      const compute_trx = await findComputeTransaction({
        asset_ids: asset_ids
      });
      const files = await getFiles(file_ids);

      let asset_data = [];
      assets.forEach((a) => {
        const trx = compute_trx.find(t => t.asset_id === a.id);
        a.credit = trx ? `${trx.credit} WRC` : `0 WRC`;

        const file_id = Array.isArray(a.outputs) && a.outputs.length ? a.outputs[0].storage_node_id : "";
        const file = files.find(x => x.id === file_id);
        if (file) {
          a.file = file;
          a.name = file.name;
          a.finalized_at = file.finalized_at;
          a.parent = file.parent;
          a.node_id = file.id;
        }

        asset_data.push(a);
      });

      setData(asset_data);

      setShowRetryButton(false);
      props.setProgressBar(false);
    }).catch(() => {
      props.setProgressBar(false);
      setShowRetryButton(true);
    });
  }

  useEffect(() => {
    props.setProgressBar(true);

    const queryParams = new URLSearchParams(history.location.search);
    const renderId = queryParams.get("render_id");

    const filter = queryParams.get("filter");
    const tab = queryParams.get("tab");
    const search = queryParams.get("search");
    let page = queryParams.get("page");
    let size = queryParams.get("size");

    if (renderId) {
      return renderPageHandler(renderId);
    }

    if (!page) {
      page = 1;
      setTableCurrentPage(page);
    }

    if (!size) {
      size = sizePerPage;
    }

    // Payload for getAsset(), it uses type=1 to display only paid operation.
    let payload = {
      pagination_options: {
        offset: getOffsetFromPageNumber(page, size),
        size: parseInt(size),
        column: sortTable,
        order: paginationOrderConverter(orderTable)
      },
      type: 1
    }

    if (search) {
      payload.search = search;
    }

    const filteredDate = getFilteredDate(filter);
    if (filteredDate) {
      payload.created_after = filteredDate;
    }

    if (tab === "video") {
      payload.domain = "video";
      getAssets(payload);
    } else if (tab === "image") {
      payload.domain = "image";
      getAssets(payload);
    } else if (tab === "model") {
      payload.domain = "model";
      getAssets(payload);
    } else {
      getRender(payload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  // Use effect with cleanup to handle history listener
  useEffect(() => {
    return  history.listen(location => {
      if (history.action === 'POP') {
        const nextRefresh = refresh + 1;
        setRefresh(nextRefresh);
      }
    });
  }, [history, refresh]);

  const retry = () => {
    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const onSearchFilterChange = (event) => {
    const { value } = event.target;
    const params = new URLSearchParams(history.location.search);
    params.delete("page");
    params.set("search", value);

    if (event.key === "Enter") {
      history.push(`${history.location.pathname}?${params.toString()}`);
      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }
  };

  const filterTabHandler = (key) => {
    const params = new URLSearchParams(history.location.search);
    params.delete("page");
    params.set("filter", key);

    setFilterTab(key);

    history.push(`${history.location.pathname}?${params.toString()}`);
    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const computeTabHandler = (key) => {
    const params = new URLSearchParams(history.location.search);
    params.delete("page");
    params.set("tab", key);

    setComputeTab(key);

    history.push(`${history.location.pathname}?${params.toString()}`);
    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const sortingHandler = (field, order) => {
    setSortTable(field);
    setOrderTable(order);

    const params = new URLSearchParams(history.location.search);
    history.push(`${history.location.pathname}?${params.toString()}`);
    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const rowEvents = {
    onContextMenu: (e, row, rowIndex) => {
      e.preventDefault();
      setAnchorPoint({ x: e.pageX, y: e.pageY });
      setShowContextMenu(true);
      setContextSelectedRender(row);
    }
  };

  const rowStyle = (row, rowIndex) => {
    let style = {};
    if ((row.id === contextSelectedRender.id) && (showContextMenu.state === "open")) {
      style.backgroundColor = 'rgb(222, 222, 222)';
    }
    return style;
  }

  const defaultSorted = [{
    dataField: "created_at",
    order: "desc",
  }, ];

  const onSort = {
    dataField: sortTable,
    order: orderTable
  }

  const tableOption = {
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    page: tableCurrentPage,
    disablePageTitle: true,
    pageButtonRenderer,
    sizePerPageRenderer,
    sizePerPageList: defaultSizePerPage
  }

  const selectRow = {
    mode: "checkbox",
    selected: selectedRender,
    clickToSelect: true,
    style: { backgroundColor: 'rgba(32, 194, 175, 0.19)' },
  };

  const columns = [
    {
      align: "left",
      dataField: "id",
      formatter: function (cell, row) {
        return (
          <span data-toggle="tooltip" title={cell}>{cell}</span>
        );
      },
      style: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "10vh",
      },
      text: "Compute ID",
    },
    {
      align: "center",
      dataField: "state",
      formatter: formatState,
      sort: true,
      style: {
        width: "25vh",
      },
      headerAlign: "center",
      text: "Status",
      onSort: sortingHandler,
      sortCaret: sortCaretFormatter,
    },
    {
      align: "right",
      dataField: "credit",
      style: {
        maxWidth: "15vh",
      },
      headerAlign: "right",
      text: "Credit Used",
    },
    {
      align: "center",
      dataField: "created_at",
      formatter: formatTimestamp,
      sort: true,
      style: {
        maxWidth: "30vh",
      },
      headerAlign: "center",
      text: "Submit Time",
      onSort: sortingHandler,
      sortCaret: sortCaretFormatter,
    },
    {
      align: "left",
      dataField: "name",
      formatter: function (cell, row) {
        if (!row.finalized_at) {
          return (
            <p style={{color: "red"}}>{cell}</p>
          );
        }

        if (!row.parent) {
          return cell;
        }

        return (
          <a data-toggle="tooltip" title={cell} href={`/dashboard/storage/folder/${row.parent}?file_id=${row.node_id}`} className="a">{cell}</a>
        );
      },
      style: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "40vh",
      },
      text: "Output",
    },
  ];

  return (
    <div>
      <Row className="text-center justify-content-center" style={{display: showRetryButton ? "block" : "none"}}>
        <TbFaceIdError style={{height: "100px"}}/>
        <h6>Sorry, We are failed to get render data, Click the Retry button to retry.</h6>
        <Button className="btn-wr btn-sm rounded-pill" style={{width: "100px"}} onClick={retry}>Retry</Button>
      </Row>

      <div style={{display: showRetryButton ? "none" : "block"}} className="compute-tab">
        <Row className="px-2">
          <Col md={6}>
            <InputGroup id="search-filter">
              <FormControl className="rounded-pill"
                           id="search"
                           onKeyPress={onSearchFilterChange}
                           onChange={onSearchFilterChange}
                           placeholder="Search ..."
                           style={{ maxWidth: "auto" }}
              />
            </InputGroup>
          </Col>
        </Row>

        <br/>

        <ControlledMenu {...showContextMenu} anchorPoint={anchorPoint}
                        onClose={() => setShowContextMenu(false)}>
          <MenuItem className="mc" onClick={() => setShowComputeInfo(true)}>
            <BsInfoCircle/>&nbsp;&nbsp;File Info</MenuItem>
          <MenuItem className="mc" onClick={() => copy(contextSelectedRender.id)}>
            <VscCopy/>&nbsp;&nbsp;Copy Compute ID</MenuItem>
          <MenuItem className="mc" disabled={!contextSelectedRender.finalized_at}
                    onClick={() => history.push(`/dashboard/storage/folder/${contextSelectedRender.parent}?file_id=${contextSelectedRender.node_id}`)}>
            <FiLink style={{color: "transparent"}}/>&nbsp;&nbsp;Go to Storage</MenuItem>
        </ControlledMenu>

        <Row className="p-0">
          <Nav as="ul" className="col-6 nav-tabs ps-2" defaultActiveKey={filterTab} onSelect={(key) => filterTabHandler(key)}>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="all">ALL</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="month">THIS MONTH</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="week">THIS WEEK</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="today">TODAY</Nav.Link>
            </Nav.Item>
          </Nav>

          <Nav as="ul" className="col-6 justify-content-end nav-tabs" defaultActiveKey={computeTab} onSelect={(key) => computeTabHandler(key)}>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="render">RENDER</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="video">VIDEO</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="model">MODEL</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as="button" eventKey="image">IMAGE</Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>

        <BootstrapTable
          remote
          bootstrap5
          bordered={false}
          columns={columns}
          condensed
          data={data}
          defaultSorted={defaultSorted}
          hover
          id="compute-table"
          keyField="id"
          pagination={paginationFactory(tableOption)}
          selectRow={selectRow}
          rowEvents={rowEvents}
          rowStyle={rowStyle}
          sort={onSort}
          onTableChange={function (type, {page, sizePerPage}) {
            const params = new URLSearchParams(history.location.search);
            if (type === 'pagination') {
              params.set("page", page);
              params.set("size", sizePerPage);

              setTableCurrentPage(page);
              setSizePerPage(sizePerPage);
            }
            history.push(`${history.location.pathname}?${params.toString()}`);
            const nextRefresh = refresh + 1;
            setRefresh(nextRefresh);
          }}
        />

        {/*Context menu file info modal.*/}
        <Modal show={showComputeInfo} onHide={() => setShowComputeInfo(false)} centered className="fade modal-st">
          <Modal.Header closeButton>
            <Modal.Title className="text-warp">{contextSelectedRender.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={3} style={{textAlign: "right"}}>
                ID:
              </Col>
              <Col xs={9} style={{textAlign: "left"}}>
                {contextSelectedRender.id}
              </Col>
            </Row>
            <Row>
              <Col xs={3} style={{textAlign: "right"}}>
                Context ID:
              </Col>
              <Col xs={9} style={{textAlign: "left"}}>
                {contextSelectedRender.context_id}
              </Col>
            </Row>
            <Row>
              <Col xs={3} style={{textAlign: "right"}}>
                Filename:
              </Col>
              <Col xs={9} style={{textAlign: "left"}}>
                {contextSelectedRender.name}
              </Col>
            </Row>
            <Row>
              <Col xs={3} style={{textAlign: "right"}}>
                Credit:
              </Col>
              <Col xs={9} style={{textAlign: "left"}}>
                {contextSelectedRender.credit}
              </Col>
            </Row>
            <Row>
              <Col xs={3} style={{textAlign: "right"}}>
                Created:
              </Col>
              <Col xs={9} style={{textAlign: "left"}}>
                {contextSelectedRender.created_at ?
                  dateFormat(new Date(contextSelectedRender.created_at * 1000), "dddd, dd mmmm yyyy HH:mm:ss") : ""}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" className="rounded-pill btn-wr" style={{marginRight: "2px", width: "100px"}}
                    onClick={() => setShowComputeInfo(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

ComputeWidget.propTypes = {};
ComputeWidget.defaultProps = {};

export default ComputeWidget;