import {Modal} from "react-bootstrap";

const VideoModalLayout = props => {
  const {videoShow, videoClose, title, video} = props;

  return (
    <div>
      <Modal show={videoShow} onHide={videoClose} centered className="fade" size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-center">
          <video src={video.src} controls autoPlay loop className="w-100" />
        </Modal.Body>
      </Modal>
    </div>
  );
}

VideoModalLayout.propTypes = {};
VideoModalLayout.defaultProps = {};

export default VideoModalLayout;