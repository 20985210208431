import sha1 from "crypto-js/sha1";
import CryptoJS from "crypto-js";
import {useEffect, useRef, useState} from "react";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  FormControl,
  Image,
  InputGroup,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
  Tabs,
  ProgressBar,
  Spinner,
  Toast,
  ToastContainer,
  Form,
  FormGroup,
  ToggleButton,
  Container
} from "react-bootstrap";
import {ControlledMenu, MenuItem, useMenuState} from '@szhsin/react-menu';
import {BsCloudDownload, BsCloudUpload, BsFileEarmark, BsFolderFill, BsFolderPlus, BsLinkedin,
  BsFolderSymlink, BsImage, BsInfoCircle, BsTrash, BsPlusSquareFill, BsShare, BsLink45Deg, BsTwitter, BsPinterest,
  BsShuffle, BsCode, BsStopCircle, BsForward } from "react-icons/bs";
import {BiImageAdd} from "react-icons/bi";
import {CgChevronDoubleDown, CgChevronDoubleUp, CgSelect} from "react-icons/cg";
import {FiLink} from "react-icons/fi";
import {TbFaceIdError} from "react-icons/tb";
import {VscTypeHierarchySub} from "react-icons/vsc";
import {MdOutlineDriveFileRenameOutline, MdOutlineEmail} from "react-icons/md"
import {ImEmbed2, ImReddit} from "react-icons/im";
import {SlSocialVkontakte} from "react-icons/sl";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import { run as runHolder } from 'holderjs/holder';
import {useHistory} from "react-router-dom";
import filterFactory from "@musicstory/react-bootstrap-table2-filter";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import validator from "validator";
import copy from "copy-to-clipboard";
import saveAs from "file-saver";
import "@szhsin/react-menu/dist/index.css";
import "@musicstory/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import dateFormat from "dateformat";

import {
  deleteFolder, deleteAllFiles, createFile, uploadChunk, finalizeFile, getFolder,
  createFolder, moveNode, getFileBlob, getNodeThumbnails, getNodePaginate, getFolderList,
  createNodeShare, listNodeShare, deleteNodeShare, renameNode,
  setNodeThumbnail
} from "../../api/storage";
import {deleteFoldersAndFiles, getFileByShared} from "../../services/storage";
import {getModelHierarchy} from "../../services/asset";
import {getFileContentType} from "../../utils/extension";

import {paginationOrderConverter, parseRenderFromMetadata} from "../../utils/parser";
import ErrorDialog from "../../components/ErrorWidget/ErrorWidget";
import {defaultSizePerPage} from "../../constants/bootstrapTable";
import {sizePerPageRenderer, pageButtonRenderer} from "../BoostrapTable/BootstrapTable";
import {childPaths} from "../../utils/breadcrumb";

import "./StorageWidget.css";
import "../../styles/Modal.css";
import "../../styles/styles.css";
import styles from "./StorageWidget.module.css";
import {getOffsetFromPageNumber} from "../../utils/pagination";

const StorageWidget = (props) => {
  let history = useHistory();

  const defaultPageLimit = 10;

  // Reference to all tables.
  const allTable = useRef(null);

  // Reference to thumbnail input.
  const thumbnailInputRef = useRef();

  // Basic state for data from REST around path.
  const [data, setData] = useState({ files: [], folders: [] });
  const [thumbnails, setThumbnails] = useState({});
  const [shares, setShares] = useState({});
  const [path, setPath] = useState("/");
  const [subPaths, setSubPaths] = useState([{ name: "/", path: "/" }]);
  const [refresh, setRefresh] = useState(0);

  // Processed data to be used by Tab elements based on file content type.
  const [tabKey, setTabKey] = useState("all");

  // Table data.
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [selectedFile, setSelectedFile] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortTable, setSortTable] = useState("content_type");
  const [orderTable, setOrderTable] = useState("desc");
  const [renderFile, setRenderFile] = useState(null);

  // Enable multi-selection for table with state.
  const emptySelection = { files: {}, folders: {} };
  const [selection, setSelection] = useState(emptySelection);
  const [showToast, setShowToast] = useState(false);

  // Context menu.
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useMenuState();
  const [contextSelectedItem, setContextSelectedItem] = useState({});
  const [modelHierarchy, setModelHierarchy] = useState({});
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [renameNodeName, setRenameNodeName] = useState("");

  const [showFileInfo, setShowFileInfo] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [filePreSignedUrl, setFilePreSignedUrl] = useState("");
  const [showModelHierarchy, setShowModelHierarchy] = useState(false);

  const [showFileMover, setShowFileMover] = useState(false);

  // Node re-parent dialog.
  const [nodeReParentPath, setNodeReParentPath] = useState([{name: "Home", path: "/"}]);
  const [totalItemReParent, setTotalItemReParent] = useState(0);
  const [tableReParentCurrentPage, setTableReParentCurrentPage] = useState(1);
  const [reParentData, setReParentData] = useState({ files: [], folders: [] });
  const [nodeCurrentData, setNodeCurrentData] = useState({});

  // Delete a selected node(file or folder) from button.
  const [deleteNodeModalVisibility, setDeleteNodeModalVisibility] = useState(false);
  const [deleteNodeButtonDisabled, setDeleteNodeButtonDisabled] = useState(true);
  const [downloadFileButtonDisabled, setDownloadFileButtonDisabled] = useState(true);

  const [showErrorMessage, setShowErrorMessage] = useState({show: false});
  const [showDeleteSubFolder, setShowDeleteSubFolder] = useState({});
  const [showShareDialog, setShowShareDialog] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});
  const [fileChunkUploaded, setFileChunkUploaded] = useState(0);
  const [showUploadProgressBar, setShowUploadProgressBar] = useState(false);
  const [uploadProgressBarLabel, setUploadProgressBarLabel] = useState(0);
  const [loaded, setLoaded] = useState({chunk_sent: 0, file_size: 0});

  const [collapseModelHierarchy, setCollapseModelHierarchy] = useState({});

  const [shareButtonDisabled, setShareButtonDisabled] = useState(true);

  const [thumbnailModal, setThumbnailModal] = useState(false);

  const [showRetryButton, setShowRetryButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  // Share dialog.
  const [showEmbed, setShowEmbed] = useState(false);
  const [showLink, setShowLink] = useState(true);
  const [copiedText, setCopiedText] = useState("");
  const [shareLink, setShareLink] = useState("");
  const [iFrame, setIFrame] = useState("");

  useEffect(() => {
    runHolder('holder-image');
  });

  useEffect(() => {
    // Start the progress bar.
    props.setProgressBar(true);

    // Get the render id from rendering page.
    const queryParams = new URLSearchParams(history.location.search);
    const pathName = history.location.pathname.split("/");

    const filter = queryParams.get("filter");
    const nodeId = pathName[pathName.length-1];
    const fileId = queryParams.get("file_id");
    const search = queryParams.get("search");
    let page = queryParams.get("page");
    let size = queryParams.get("size");

    if (!page) {
      page = tableCurrentPage;
    }

    if (!size) {
      size = sizePerPage;
    }

    // Get data from new path.
    let data = {
      pagination_options: {
        offset: getOffsetFromPageNumber(page, size),
        size: parseInt(size)
      },
      id: nodeId,
    };

    if (orderTable) {
      data.pagination_options.order = paginationOrderConverter(orderTable);
    }

    if (filter === "shared") {
      data.pagination_options.column = "created_at";
      return getFileByShared(data).then(async res => {
        const files = res.data === undefined ? [] : res.data;
        const folders = [];
        setTotalSize(res.page.item_count);
        setSizePerPage(parseInt(size));
        setTableCurrentPage(parseInt(page));

        let fileIds = [];
        files.forEach((file) => {
          fileIds.push(file.id);
        });
        const thumbnail = await getNodeThumbnails(fileIds);
        setThumbnails(thumbnail);

        setData({ files: files, folders: folders });

        // Finish the progress bar.
        setShowRetryButton(false);
        props.setProgressBar(false);
      }).catch(() => {
        setShowRetryButton(false);
        props.setProgressBar(false);
      });
    } else {
      // Handle the storage page tab filter
      if (filter === "image") {
        data.content_type = "image";
      } else if (filter === "model") {
        data.content_type = "model";
      } else if (filter === "video") {
        data.content_type = "video";
      }

      // Replace data to root path if the id is not valid.
      if (!validator.isUUID(nodeId)) {
        data = {
          pagination_options: {
            offset: getOffsetFromPageNumber(page, size),
            size: parseInt(size)
          },
          path: "/"
        };
      }

      if (sortTable) {
        data.pagination_options.column = sortTable;
      }

      if (search) {
        data.search = search;
      }

      getFolderList(data).then(res => {
        // Set data.
        let files = res.data.files === undefined ? [] : res.data.files;
        let folders = res.data.folders === undefined ? [] : res.data.folders;
        setTotalSize(res.page.item_count);
        setSizePerPage(parseInt(size));
        setTableCurrentPage(parseInt(page));

        // Flag the selected node.
        if (fileId) {
          return defaultFileHandler();
        }

        getFolder(data).then(async res => {
          setPath(res.path);
          setSubPaths(childPaths(res.path));
          history.replace(`${props.match.url}/storage/folder/${res.id}`);

          // Collect file IDs to get thumbnails later.
          // TODO: Folder could have thumbnail too.
          let fileIds = [];
          files.forEach((file) => {
            fileIds.push(file.id);
          });

          const thumbnail = await getNodeThumbnails(fileIds);
          setThumbnails(thumbnail);
          setData({ files: files, folders: folders });

          // Finish the progress bar.
          setShowRetryButton(false);
          props.setProgressBar(false);
        }).catch(err => {
          return err;
        });
      }).catch(() => {
        props.setProgressBar(false);
        setShowRetryButton(true);
      });
    }

    // Clear selection-related state.
    allTable.current.selectionContext.selected = [];
    setSelection(emptySelection);
    setDownloadFileButtonDisabled(true);
    setDeleteNodeButtonDisabled(true);
    setShareButtonDisabled(true);

    if (renderFile) {
      setDownloadFileButtonDisabled(false);
      setDeleteNodeButtonDisabled(false);
      setShareButtonDisabled(false);
      setSelection({files: {
          [renderFile.id]: renderFile,
        }, folders: {}});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  // Collect share from state "data", which is pointing to current path.
  useEffect(() => {
    let nodeIds = [];
    data.files.forEach((file) => {
      nodeIds.push(file.id);
    });
    data.folders.forEach((folder) => {
      nodeIds.push(folder.id);
    });
    if (nodeIds.length === 0) {
      return;
    }

    const payload = {
      pagination_options: {
        size: 100,
      },
      storage_node_ids: nodeIds
    };

    listNodeShare(payload).then((res) => {
      if (res.data !== null) {
        let shares = {};
        res.data.forEach((item) => {
          shares[item.storage_node_id] = item.id;
        });
        setShares(shares);
      }
    }).catch((err) => {
      console.error(err);
    });
  }, [data]);

  // Use effect with cleanup to handle history listener
  useEffect(() => {
    return  history.listen(location => {
      if (history.action === 'POP') {
        const nextRefresh = refresh + 1;
        setRefresh(nextRefresh);
      }
    });
  }, [history, refresh]);

  const retry = () => {
    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const defaultFileHandler = () => {
    const queryParams = new URLSearchParams(history.location.search);
    const pathName = history.location.pathname.split("/");

    const nodeId = pathName[pathName.length-1];
    const fileId = queryParams.get("file_id");

    // Stop the process if the node is not UUID.
    if (!validator.isUUID(nodeId) || !validator.isUUID(fileId)) {
      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
      return;
    }

    // Get node page position.
    const data = {
      storage_node_id: fileId,
      pagination_options: {
        column: "created_at",
        order: "descending",
        size: 10
      }
    };

    getNodePaginate(data).then(res => {
      setSortTable("created_at");
      setOrderTable("desc");

      // Replace the history.
      const params = new URLSearchParams(history.location.search);
      params.set("page", res.page_number);
      params.delete("file_id");

      history.replace(`${history.location.pathname}?${params.toString()}`);
      setSelectedFile([fileId]);

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }).catch(() => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to find file in the storage.`
      });
      history.replace(`/dashboard/storage`);

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    });
  }

  const getStorageNodeByPath = (path, page) => {
    // Start the progress bar.
    props.setProgressBar(true);

    const data = {
      pagination_options: {
        offset: getOffsetFromPageNumber(page, defaultPageLimit),
        size: defaultPageLimit,
        column: "type",
        order: "descending",
      },
      path: path
    };

    getFolderList(data).then(res => {
      let files = res.data.files === undefined ? [] : res.data.files;
      let folders = res.data.folders === undefined ? [] : res.data.folders;
      setReParentData({ files: files, folders: folders });

      setTotalItemReParent(res.page.item_count);
      setTableReParentCurrentPage(page);

      getFolder(data).then(res => {
        setNodeReParentPath(childPaths(res.path));

        setNodeCurrentData(res);

        // Finish the progress bar.
        props.setProgressBar(false);
      }).catch(err => {
        return err;
      });
    }).catch(() => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to get storage node`
      });

      // Finish the progress bar.
      props.setProgressBar(false);
    });
  }

  const showNodeReParentDialog = () => {
    getStorageNodeByPath("/", 1);
    setShowFileMover(true);
  }

  const getNodeByPathname = (pathname) => {
    const data = {
      limit: 1,
      path: pathname,
    };

    getFolder(data).then(res => {
      const params = new URLSearchParams(history.location.search);
      params.delete("page");

      history.push(`${props.match.url}/storage/folder/${res.id}?${params.toString()}`);
      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }).catch((err) => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to get storage node`
      });
    });
  }

  const showModelHierarchyModal = () => {
    setShowModelHierarchy(true);
    getModelHierarchy(contextSelectedItem.id).then(res => {
      setModelHierarchy(res);
    }).catch(err => {
      setShowModelHierarchy(false);
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to show model hierarchy.`
      });
    });
  }

  const onClickShareButton = () => {
    setShowLink(!showLink);
    setShowEmbed(!showEmbed);
    setCopiedText(shareLink);
  }

  const onClickEmbedButton = () => {
    setShowLink(!showLink);
    setShowEmbed(!showEmbed);
    setCopiedText(iFrame);
  }

  const copySharedText = () => {
    copy(copiedText);

    setShowToast(true);
    setShowShareDialog(false);
  }

  const startNodeShare = (id, name) => {
    // Create the share link.
    createNodeShare(id).then(res => {
      const shareLink = window.location.origin + '/share/' + res.id;
      const embedLink = window.location.origin + '/embed/' + res.id;

      setShareLink(shareLink);
      setCopiedText(shareLink);

      const frame = `<iframe width="560" height="315" frameBorder="0" src="${embedLink}" title="${name}" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      setIFrame(frame);
      if (showEmbed) {
        setCopiedText(frame);
      }

      setShowShareDialog(true);

      // Refresh this page.
      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);

      setSelectedFile([]);
    }).catch(err => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `${err}`
      });
    })
  };

  const startShareButtonHandler = () => {
    // Validate selection.
    const selFileKeys = Object.keys(selection.files);
    if (selFileKeys.length !== 1) {
      setShowErrorMessage({
        show: true,
        title: "Unsupported",
        message: `Only single file can enable share`
      });
      return;
    }

    const file = selection.files[selFileKeys[0]];

    startNodeShare(file.id, file.name);
  }

  const startShareMenuButtonHandler = () => {
    startNodeShare(contextSelectedItem.id, contextSelectedItem.name);
  }

  const stopNodeShare = (id) => {
    let shareId = shares[id];
    deleteNodeShare(shareId).then(() => {
      delete shares[id];

      // Refresh this page.
      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }).catch((err) => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `${err}`
      });
    });
  };

  const stopShareMenuButtonHandler = () => {
    stopNodeShare(contextSelectedItem.id);
  }

  // Table columns.
  const formatColumnName = (cell, row) => {
    if (row.hasOwnProperty("size")) {
      return (
        <div>
          <span>
            <BsFileEarmark />
          </span>
          <span data-toggle="tooltip" title={row.name} className={styles.span}>
            {shares.hasOwnProperty(row.id) ?
              <a
                data-toggle="tooltip"
                title={cell}
                href={`/share/${shares[row.id]}`}
                className="a-shared-link"
                target="_blank"
                rel="noreferrer">
                {cell}
              </a> : cell
            }
          </span>
        </div>
      );
    }
    return (
      <div>
        <span>
          <BsFolderFill />
        </span>
        <span data-toggle="tooltip" title={row.name} className={styles.span}>{row.name}</span>
      </div>
    );
  };

  const KB_BY_BYTES = 1000;
  const MB_BY_BYTES = 1000000;
  const GB_BY_BYTES = 1000000000;

  const round = (num) => {
    return num.toFixed(2);
  }

  const formatColumnRenderID = (cell, row) => {
    const data = parseRenderFromMetadata(row.metadata);
    if (data) {
      return (
        <a data-toggle="tooltip" title={data} href={`/dashboard/compute?render_id=${data}`} className="a">{data}</a>
      );
    }

    return "";
  }

  const parseStorageSize = (size) => {
    if (size >= MB_BY_BYTES && size < GB_BY_BYTES) {
      return round(size/MB_BY_BYTES) + ' MB';
    } else if (size >= GB_BY_BYTES) {
      return round(size/GB_BY_BYTES) + ' GB';
    } else if (size < MB_BY_BYTES) {
      return round(size/KB_BY_BYTES) + ' KB';
    } else {
      return '';
    }
  }

  const formatColumnSize = (cell, row) => {
    return parseStorageSize(cell);
  }

  const formatColumnContentType = (cell, row) => {
    if (cell) {
      return cell;
    }
    return "Folder";
  }

  const formatColumnTimestamp = (cell, row) => {
    const date = new Date(cell * 1000);
    return dateFormat(date, 'yyyy/mm/dd @ HH:mm:ss');
  };

  // TODO: Change the image URL when the backend is ready.
  const formatThumbColumn = (cell, row, rowIndex, extraData) => {
    if (extraData.hasOwnProperty(row.id)) {
      // Thumbnails saves objects contain URLs.
      let blob = extraData[row.id];
      let imageUrl = blob.url;
      return (
        <OverlayTrigger
          placement="left"
          delay={{show: 250, hide: 400}}
          overlay={<Popover className="shadow-sm bg-body rounded">
            <Popover.Body>
              <Image src={imageUrl} className="img-fluid img-thumbnail"/>
            </Popover.Body>
          </Popover>}>
          <Image className="i" src={imageUrl} height="25px" alt={row.name}/>
        </OverlayTrigger>
      );
    }

    return "";
  }

  const sortingHandler = (field, order) => {
    setOrderTable(order);
    setSortTable(field);

    const params = new URLSearchParams(history.location.search);
    history.push(`${history.location.pathname}?${params.toString()}`);
    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const sortCaretFormatter = (order, column) => {
    if (!order) {
      return (
        <span style={{verticalAlign: ".125em"}}>
          &nbsp;<CgSelect/>
        </span>
      );
    } else if (order === 'asc') {
      return (
        <span style={{verticalAlign: ".125em"}}>
          &nbsp;<CgChevronDoubleUp/>
        </span>
      );
    } else if (order === 'desc') {
      return (
        <span style={{verticalAlign: ".125em"}}>
          &nbsp;<CgChevronDoubleDown/>
        </span>
      );
    }
    return null;
  }

  const reParentDialogColumns = [{
    dataField: "id",
    text: "",
    hidden: true,
  },{
    align: "left",
    dataField: "name",
    formatter: formatColumnName,
    headerAlign: "left",
    text: "Name",
    style: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "35vh",
    }
  },{
    align: "right",
    dataField: "size",
    formatter: formatColumnSize,
    headerAlign: "right",
    text: "Size",
  },{
    align: "center",
    dataField: "content_type",
    formatter: formatColumnContentType,
    headerAlign: "center",
    text: "Type",
  },{
    align: "center",
    dataField: "created_at",
    formatter: formatColumnTimestamp,
    headerAlign: "center",
    text: "Creation Time",
  }]

  const columns = [{
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      align: "left",
      dataField: "name",
      formatter: formatColumnName,
      headerAlign: "left",
      text: "Name",
      sort: true,
      style: (cell, row, rowIndex, colIndex) => {
        let style = {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: "35vh",
        };

        // For the file that it doesn't have proper "finalized_at", it means
        // the data was not ready.
        let isRowFile = row.hasOwnProperty("finalized_at");
        if (isRowFile && row.finalized_at < 1) {
          style.color = "red";
        }

        // For shared node, it will be display in blue.
        if (shares.hasOwnProperty(row.id)) {
          style.color = "#20C2AF";
        }

        return style;
      },
      onSort: sortingHandler,
      sortCaret: sortCaretFormatter,
    },
    {
      align: "center",
      dataField: "content_type",
      formatter: formatColumnContentType,
      sort: true,
      headerAlign: "center",
      text: "Kind",
      onSort: sortingHandler,
      sortCaret: sortCaretFormatter,
    },
    {
      align: "center",
      dataField: "created_at",
      formatter: formatColumnTimestamp,
      sort: true,
      style: {
        maxWidth: "30vh",
      },
      headerAlign: "center",
      text: "Creation Time",
      onSort: sortingHandler,
      sortCaret: sortCaretFormatter,
    },
    {
      align: "right",
      dataField: "size",
      formatter: formatColumnSize,
      headerAlign: "right",
      sort: true,
      text: "Size",
      onSort: sortingHandler,
      sortCaret: sortCaretFormatter,
    },
    {
      align: "center",
      dataField: "metadata",
      formatter: formatColumnRenderID,
      headerAlign: "center",
      text: "Compute ID",
      style: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "1vh",
      },
    },
    {
      align: "center",
      dataField: "thumbnail",
      formatter: formatThumbColumn,
      formatExtraData: thumbnails,
      headerAlign: "center",
      text: "Thumbnail"
    }
  ];

  useEffect(() => {
    if (loaded.chunk_sent > 0) {
      // calculate total of byte size had sent.
      const percentage = (fileChunkUploaded / loaded.file_size) * 100;
      setUploadProgressBarLabel(percentage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);


  const showFileDialog = (file) => {
    setUploadedFile(file);
    setShowUploadModal(true);
  };

  const cancelUploadFile = () => {
    setUploadedFile({});
    setShowUploadModal(false);
  }

  const onUploadFile = (file) => {
    setLoadingButton(true);

    const reader = new FileReader();

    reader.onloadend = function (event) {
      const hash = sha1(CryptoJS.lib.WordArray.create(event.target.result));

      const filePath = path === "/" ? path + file.name : path + "/" + file.name;
      const payload = {
        checksum: "sha1:" + hash.toString(),
        path: filePath,
        content_type: getFileContentType(file),
        mode: "unique",
        size: file.size
      }

      // Show the progress bar.
      setShowUploadProgressBar(true);
      setUploadProgressBarLabel(0);
      setFileChunkUploaded(0);

      createFile(payload).then(async res => {
        if (res.chunks) {
          let start = 0

          for (const chunk of res.chunks) {
            const index = start;
            start += chunk.size;
            const r = await uploadChunk(setLoaded, chunk.url, file, index, chunk.size);

            setFileChunkUploaded(start);
            const percentage = (r.total / file.size) * 100;
            if (fileChunkUploaded < percentage) {
              setShowUploadProgressBar(true);
              setUploadProgressBarLabel(percentage);
            }
          }
        }

        finalizeFile(res.id).then(() => {
          setUploadedFile({});
          setFileChunkUploaded(0);
          setShowUploadProgressBar(false);
          setUploadProgressBarLabel(0);
          setLoaded({chunk_sent: 0, file_size: 0});
          setLoadingButton(false);
          setShowUploadModal(false);

          const nextRefresh = refresh + 1;
          setRefresh(nextRefresh);
        }).catch(() => {
          setShowErrorMessage({
            show: true,
            title: "Failed",
            message: `Cannot upload ${file.name} into server.`
          });
          setShowUploadProgressBar(false);
          setFileChunkUploaded(0);
          setUploadProgressBarLabel(0);
          setLoaded({chunk_sent: 0, file_size: 0});
          setLoadingButton(false);
          setShowUploadModal(false);

          const nextRefresh = refresh + 1;
          setRefresh(nextRefresh);
        });
      })
    };
    reader.readAsArrayBuffer(file);
  }

  const onColumnNameFilterChange = (event) => {
    const { value } = event.target;
    const params = new URLSearchParams(history.location.search);
    params.set("name", value);
    params.delete("page");

    if (event.key === "Enter") {
      setSelectedFile([]);
      history.push(`${history.location.pathname}?${params.toString()}`);
      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }
  };

  // Handle click for folder.
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // TODO: Decide if the row is a file or folder in a better way.
      if (!row.hasOwnProperty("size")) {
        setSelectedFile([]);

        const params = new URLSearchParams(history.location.search);
        params.delete("page");

        history.push(`${props.match.url}/storage/folder/${row.id}?${params.toString()}`);
        const nextRefresh = refresh + 1;
        setRefresh(nextRefresh);
      }
    },
    onContextMenu: (e, row, rowIndex) => {
      e.preventDefault();
      setAnchorPoint({ x: e.pageX, y: e.pageY });
      setShowContextMenu(true);
      setContextSelectedItem(row);
    }
  };

  const rowStyle = (row, rowIndex) => {
    let style = {};
    if ((row.id === contextSelectedItem.id) && (showContextMenu.state === "open")) {
      style.backgroundColor = 'rgb(222, 222, 222)';
    }
    return style;
  }

  const manageFileRowEvent = {
    onClick: (e, row, rowIndex) => {
      if (!row.hasOwnProperty("size")) {
        const path = nodeCurrentData.path + `/${row.name}`;
        getStorageNodeByPath(path.replace("//", "/"), 1)
      }
    }
  }

  // Modal for create new folder.
  const [createFolderModalVisibility, setCreateFolderModalVisibility] = useState(false);
  const [createFolderName, setCreateFolderName] = useState("");

  const showCreateFolderModal = () => {
    setCreateFolderModalVisibility(true);
  };

  const hideCreateFolderModal = () => {
    setCreateFolderModalVisibility(false);
  };

  const onCreateFolderChanged = (event) => {
    setCreateFolderName(event.target.value);
  };

  const onCreateFolder = () => {
    const name = createFolderName;
    if (name.length === 0) {
      return;
    }

    // Create a new folder with full path.
    const currPath = path;
    const folderPath = currPath === "/" ? currPath + name : currPath + "/" + name;

    createFolder(folderPath).then(() => {
      hideCreateFolderModal();
      setCreateFolderName("");

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }).catch(err => {
      hideCreateFolderModal();

      const res = err.response;
      if (res) {
        if (res.status === 409) {
          setShowErrorMessage({
            show: true,
            title: "Failed",
            message: `The name "${createFolderName}" is already taken. Please choose a different name.`
          });
          return
        }
      }

      setShowErrorMessage({
        show: true,
        title: "Error",
        message: `Cannot create "${createFolderName}".`
      });
      setCreateFolderName("");
    });
  };

  const onCollapseHierarchy = (e, model) => {
    let collapse = collapseModelHierarchy;
    collapse[model.path] = !collapseModelHierarchy[model.path];
    setCollapseModelHierarchy(collapse);

    e.stopPropagation();
  }

  const modelHierarchyParser = (model) => {
    const paths = model.path.split("/").slice(0, -1);
    const parent_paths = paths.join("/");

    if (model.children) {
      return (
        <li className="parent_li" style={{display: collapseModelHierarchy[parent_paths] ? "none" : "list-item"}}>
          <span onClick={(e) => onCollapseHierarchy(e, model)}>
            <i><BsPlusSquareFill/></i>&nbsp;{model.name}&nbsp;
          <span><small><small style={{color: "#20C2AF"}}>{model.type}</small></small></span></span>
          <ul>
            {model.children.map((child => modelHierarchyParser(child)))}
          </ul>
        </li>
      );
    }

    return (
      <li style={{display: collapseModelHierarchy[parent_paths] ? "none" : "list-item"}}><span>{model.name}&nbsp;
        <span><small><small style={{color: "#20C2AF"}}>{model.type}</small></small></span></span></li>
    );
  }

  // Download file by chunks from button.
  const DownloadFile = (url, filename) => {
    saveAs(url, filename);
  }

  const generatePreSignedUrl = (id) => {
    getFileBlob(id).then(res => {
      setFilePreSignedUrl(res.url);
      setShowFilePreview(true);
    }).catch(() => {
      setShowErrorMessage({
        show: true,
        title: "Error",
        message: "Failed to preview the file."
      });
    });
  }

  const onDownloadFile = async () => {
    const selFileKeys = Object.keys(selection.files);
    if (selFileKeys.length !== 1) {
      return;
    }

    const selFileKey = selFileKeys[0];
    const row = selection.files[selFileKey];
    if (!row.finalized_at) {
      return;
    }

    getFileBlob(row.id).then(res => {
      DownloadFile(res.url, row.name);
    }).catch((err) => {
      console.error(err);
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to download the file.`
      });
    });
  };

  const contextMenuDownloadFile = () => {
    if (!contextSelectedItem) {
      return;
    }

    if (!contextSelectedItem.finalized_at) {
      return;
    }

    getFileBlob(contextSelectedItem.id).then(res => {
      DownloadFile(res.url, contextSelectedItem.name);
    }).catch((err) => {
      console.error(err);
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to download the file.`
      });
    });
  }

  const handleFilePreviewDialog = () => {
    generatePreSignedUrl(contextSelectedItem.id);
  }

  const handleCopyFilePath = () => {
    let path = "";

    subPaths.map((subPath, index) => (
      path = path + subPath.name + "/"
    ));
    path = path + contextSelectedItem.name;

    copy(path.replace("Home", ""));
  }

  const showDeleteNodeModal = () => {
    setDeleteNodeModalVisibility(true);
  };

  const hideDeleteNodeModal = () => {
    setDeleteNodeModalVisibility(false);
  };

  const moveFileNode = (nodeId, parentId) => {
    moveNode(nodeId, parentId).catch(() => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to move the node to a new folder.`
      });
    });
  }

  const moveFileNodeHandler = () => {
    moveFileNode(contextSelectedItem.id, nodeCurrentData.id);
    setShowFileMover(false);

    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const renameNodeHandler = () => {
    // Skip rename if no name change.
    if (!renameNodeName || renameNodeName.isEmpty) {
      setShowRenameModal(false);
      setRenameNodeName("");
      return
    }

    renameNode(contextSelectedItem.id, renameNodeName).catch(err => {
      setShowErrorMessage({
        show: true,
        title: "Failed to Rename",
        message: `${err}`
      });
    });

    setShowRenameModal(false);
    setRenameNodeName("");

    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const onDeleteSubFolder = (folder) => {
    props.setProgressBar(true);

    setShowDeleteSubFolder({show: false});

    deleteFoldersAndFiles({
      id: folder.id,
      limit: 100
    }).then(() => {
      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }).catch(() => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Cannot delete folder "${folder.name}".`
      });

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    });
  }

  const deleteSelectedNode = async () => {
    // Delete nodes.
    const fileIds = Object.keys(selection.files);
    const folderIds = Object.keys(selection.folders);

    if (fileIds.length > 0) {
      let fileIdsPayload = [];
      fileIds.forEach(value => {
        fileIdsPayload.push(value);
        delete selection.files[value];
      });
      await deleteAllFiles(fileIdsPayload).catch(err => {
        return Promise.reject(err);
      });
    }

    if (folderIds.length > 0) {
      for await (const value of folderIds) {
        const payload = {
          id: value
        }

        const res = await getFolder(payload);
        delete selection.folders[value];

        if (res.folders || res.files) {
          setShowDeleteSubFolder({show: true, name: res.name, id: res.id});
        } else {
          await deleteFolder(value).catch(err => {
            return Promise.reject(err);
          });
        }
      }
    }

    return {}
  }

  const onDeleteNodeItem = (item) => {
    props.setProgressBar(true);
    if (item.hasOwnProperty("size")) {
      selection.files[item.id] = item;
    } else {
      selection.folders[item.id] = item;
    }

    deleteSelectedNode().then(() => {
      setSelectedFile([]);
      setData({ files: [], folders: [], thumbnail: {} });

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }).catch(err => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to delete the nodes.`
      });

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    })
  }

  const onDeleteNode = () => {
    // Hide modal.
    hideDeleteNodeModal();

    // Force to refresh.
    props.setProgressBar(true);

    deleteSelectedNode().then(() => {
      setSelectedFile([]);
      setData({ files: [], folders: [], thumbnail: {} });

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    }).catch((err) => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to delete the nodes.`
      });

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    })
  };

  // Used to upload thumbnail image to node.
  const onSetThumbnail = (id, file) => {
    setThumbnailModal(false);

    console.log('@onSetThumbnail@', id, file);

    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      // FileReader::readAsDataURL() returns base64 text with prefix as
      // "data:image/png;base64,aaaaa".
      let fileReaderResult = fileReader.result;
      let prefixAndBody = fileReaderResult.split(';');

      let prefix = prefixAndBody[0];
      let dataAndMimeType = prefix.split(':');
      let imageContentType = dataAndMimeType[1];

      let body = prefixAndBody[1];
      let schemeAndData = body.split(',');
      let imageData = schemeAndData[1];

      setNodeThumbnail(id, imageContentType, imageData).then(() => {
        const nextRefresh = refresh + 1;
        setRefresh(nextRefresh);
      }).catch((err) => {
        setShowErrorMessage({
          show: true,
          title: "Failed",
          message: `Failed to set thumbnail`,
        });
      });

      const nextRefresh = refresh + 1;
      setRefresh(nextRefresh);
    };
    fileReader.onerror = (error) => {
      setShowErrorMessage({
        show: true,
        title: "Failed",
        message: `Failed to read thumbnail file`,
      });
    };
  };

  const selectRow = {
    mode: "checkbox",
    selected: selectedFile,
    clickToSelect: true,
    style: { backgroundColor: 'rgba(32, 194, 175, 0.19)' },
    onSelect: (row, isSelect, rowIndex, e) => {
      // Update selection.
      if (isSelect) {
        selectedFile.push(row.id);

        if (row.hasOwnProperty("size")) {
          selection.files[row.id] = row;
        } else {
          selection.folders[row.id] = row;
        }
      } else {
        const index = selectedFile.indexOf(row.id);
        if (index > -1) {
          selectedFile.splice(index, 1);
        }

        if (row.hasOwnProperty("size")) {
          delete selection.files[row.id];
        } else {
          delete selection.folders[row.id];
        }
      }

      // Update state of download file button.
      let numSelectedFiles = Object.keys(selection.files).length;
      let numSelectedFolders = Object.keys(selection.folders).length;
      if (numSelectedFiles === 1 && numSelectedFolders === 0) {
        setDownloadFileButtonDisabled(false);
        setShareButtonDisabled(false);
      } else {
        setDownloadFileButtonDisabled(true);
        setShareButtonDisabled(true);
      }

      // Update state of delete node button.
      if (numSelectedFiles > 0 || numSelectedFolders > 0) {
        setDeleteNodeButtonDisabled(false);
      } else {
        setDeleteNodeButtonDisabled(true);
        setShareButtonDisabled(true);
      }

      setRenderFile(null);
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        rows.forEach(row => {
          selectedFile.push(row.id);

          if (row.hasOwnProperty("size")) {
            selection.files[row.id] = row;
          } else {
            selection.folders[row.id] = row;
          }
        });

        setDeleteNodeButtonDisabled(false);
        setShareButtonDisabled(true);
      } else {
        rows.forEach(row => {
          const index = selectedFile.indexOf(row.id);
          if (index > -1) {
            selectedFile.splice(index, 1);
          }

          if (row.hasOwnProperty("size")) {
            delete selection.files[row.id];
          } else {
            delete selection.folders[row.id];
          }
        });

        setDeleteNodeButtonDisabled(true);
        setShareButtonDisabled(true);
      }
      setDownloadFileButtonDisabled(true);
      setRenderFile(null);
    }
  };

  const tableOption = {
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    page: tableCurrentPage,
    disablePageTitle: true,
    pageButtonRenderer,
    sizePerPageRenderer,
    sizePerPageList: defaultSizePerPage
  }

  const tabHandler = (key) => {
    setTabKey(key);

    const params = new URLSearchParams(history.location.search);
    params.delete("page");
    params.set("filter", key);

    history.push(`${history.location.pathname}?${params.toString()}`);
    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const defaultSorted = [{
    dataField: "content_type",
    order: "desc",
  }, ];

  const onSort = {
    dataField: sortTable,
    order: orderTable
  }

  return (
    <div id="storage-widget" className="storage">
      <ErrorDialog showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage}/>

      <ToastContainer className="p-5" position="top-end">
        <Toast onClose={() => setShowToast(false)} show={showToast} animation autohide delay={3000} style={{position: "relative", zIndex: "1"}}>
          <Toast.Header>
            <Image src="holder.js/20x20?text=%20&bg=20c997" className="holder-image me-2" rounded/>
            <strong className="me-auto">Copied</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>Link copied to clipboard</Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal centered className="fade modal-global" onHide={hideCreateFolderModal} show={createFolderModalVisibility}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <FormControl onChange={onCreateFolderChanged} placeholder="Name" />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" onClick={hideCreateFolderModal}>
            Cancel
          </Button>
          <Button size="sm" className="rounded-pill btn-group-sm btn-wr" onClick={onCreateFolder}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered className="fade modal-global" show={showUploadModal}>
        <Modal.Header>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-break">
          You are about to upload: {uploadedFile.name} ({parseStorageSize(uploadedFile.size)})
        </Modal.Body>
        <Modal.Footer>
          {showUploadProgressBar ? <ProgressBar className="w-100" variant="success" now={uploadProgressBarLabel}
                                                     label={`${uploadProgressBarLabel.toFixed(1)}%`}/> :
            <>
              <Button size="sm" variant="outline-dark" className="rounded-pill" disabled={loadingButton} onClick={cancelUploadFile}>
                Cancel
              </Button>
              <Button size="sm" className="rounded-pill btn-group-sm btn-wr" disabled={loadingButton} onClick={() => onUploadFile(uploadedFile)}>
                {loadingButton ?
                  <Spinner style={{marginRight: "5px"}} as="span" size="sm" role="status" animation="border" variant="light"/> : ""} Upload
              </Button>
            </>
          }
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteSubFolder.show} onHide={() => setShowDeleteSubFolder({show: false})} centered className="fade modal-global">
        <Modal.Header>
          <Modal.Title>{showDeleteSubFolder.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>{showDeleteSubFolder.name}</b> folder is not empty, are you sure want to delete it?
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" style={{marginRight: "2px", width: "100px"}}
                  onClick={() => setShowDeleteSubFolder({show: false})}>Cancel</Button>
          <Button size="sm" className="rounded-pill btn-wr" style={{marginRight: "2px", width: "100px"}}
                  onClick={() => onDeleteSubFolder(showDeleteSubFolder)}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal centered className="fade modal-global" onHide={hideDeleteNodeModal} show={deleteNodeModalVisibility}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete ?</Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" onClick={hideDeleteNodeModal}>
            Cancel
          </Button>
          <Button size="sm" className="rounded-pill btn-group-sm btn-wr" onClick={onDeleteNode}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showShareDialog} onHide={() => setShowShareDialog(false)} centered className="fade modal-global">
        <Modal.Header className="p-2" style={{ position: "relative" }}>
          <h5 style={{ marginLeft: "10px", marginTop: "10px" }}>
            Share
          </h5>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Container fluid={true}>
            <p>Copy Link or Embed code, or direct share with an app:</p>
            <Row className="p-0">
              <Col className="right-col d-flex flex-nowrap overflow-auto py-3">
                <div className="float-div text-nowrap mx-3">
                  <Row className="justify-content-center text-center">
                    <ToggleButton type="checkbox" className="btn-circle btn-lg" variant="outline-secondary"
                                  checked={showLink} value="link" onClick={onClickShareButton}>
                      <BsLink45Deg/>
                    </ToggleButton>
                    <span>Link</span>
                  </Row>
                </div>
                <div className="float-div text-nowrap mx-3">
                  <Row className="justify-content-center text-center">
                    <ToggleButton type="checkbox" className="btn-circle btn-lg" checked={showEmbed}
                                  value="embed" onClick={onClickEmbedButton} variant="outline-secondary">
                      <ImEmbed2/>
                    </ToggleButton>
                    <span>Embed</span>
                  </Row>
                </div>
                <div className="float-div text-nowrap mx-3" style={{borderLeft: "1px solid black", height: "70%"}}>
                  <Row className="justify-content-center text-center"/>
                </div>
                <div className="float-div text-nowrap mx-3">
                  <Row className="justify-content-center text-center">
                    <Button className="btn-circle btn-lg" style={{ backgroundColor: "#55ACEE", borderColor: "#55ACEE" }} target="_blank"
                            href={`https://twitter.com/intent/tweet?url=${shareLink}&text=${contextSelectedItem.name}&via=we_render&related=JCube,Rendering,3DModel`}>
                      <BsTwitter/>
                    </Button>
                    <span>Twitter</span>
                  </Row>
                </div>
                <div className="float-div text-nowrap mx-3">
                  <Row className="justify-content-center text-center">
                    <Button className="btn-circle btn-lg" variant="secondary"
                            href={`mailto:?subject=${contextSelectedItem.name}&body=${shareLink}`}>
                      <MdOutlineEmail/>
                    </Button>
                    <span>Email</span>
                  </Row>
                </div>
                <div className="float-div text-nowrap mx-3">
                  <Row className="justify-content-center text-center">
                    <Button className="btn-circle btn-lg" style={{ backgroundColor: "#FF4500", borderColor: "#FF4500" }} target="_blank"
                            href={`https://reddit.com/submit?url=${shareLink}&title=${contextSelectedItem.name}`}>
                      <ImReddit/>
                    </Button>
                    <span>Reddit</span>
                  </Row>
                </div>
                <div className="float-div text-nowrap mx-4">
                  <Row className="justify-content-center text-center">
                    <Button className="btn-circle btn-lg" style={{ backgroundColor: "#4C75A3", borderColor: "#4C75A3" }} target="_blank"
                            href={`https://vkontakte.ru/share.php?url=${shareLink}`}>
                      <SlSocialVkontakte/>
                    </Button>
                    <span>VK</span>
                  </Row>
                </div>
                <div className="float-div text-nowrap mx-1">
                  <Row className="justify-content-center text-center">
                    <Button className="btn-circle btn-lg" style={{ backgroundColor: "#C61118", borderColor: "#C61118" }} target="_blank"
                            href={`https://pinterest.com/pin/create/button/?url=${shareLink}&is_video=false`}>
                      <BsPinterest/>
                    </Button>
                    <span>Pinterest</span>
                  </Row>
                </div>
                <div className="float-div text-nowrap mx-1">
                  <Row className="justify-content-center text-center">
                    <Button className="btn-circle btn-lg" style={{ backgroundColor: "#0082CA", borderColor: "#0082CA" }} target="_blank"
                            href={`https://www.linkedin.com/shareArticle?url=${shareLink}&title=${contextSelectedItem.name}&summary=${shareLink}&source=WeRender`}>
                      <BsLinkedin/>
                    </Button>
                    <span>LinkedIn</span>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>

          <br/>
          <p>{showLink ? "Use this URL for manual sharing:" : "Use this code snippet in your web app to embed a preview:"}</p>

          <Form style={{width: "100%"}}>
            <FormGroup style={{position: "relative"}}>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Text"
                value={copiedText}
                readOnly
              />
            </FormGroup>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-sm rounded-pill" variant="outline-dark" onClick={() => setShowShareDialog(false)}>Close</Button>
          <Button className="btn-wr btn-sm rounded-pill" onClick={copySharedText}>Copy</Button>
        </Modal.Footer>
      </Modal>

      <Row className="text-center justify-content-center" style={{display: showRetryButton ? "block" : "none"}}>
        <TbFaceIdError style={{height: "100px"}}/>
        <h6>Sorry, We are failed to get storage data, Click the Retry button to retry.</h6>
        <Button className="btn-wr btn-sm rounded-pill" style={{width: "100px"}} onClick={retry}>Retry</Button>
      </Row>

      <div style={{display: showRetryButton ? "none" : "block"}}>
        <Row className="px-2">
          <Col>
            <InputGroup id="search-filter">
              <FormControl className="rounded-pill"
                           id="search"
                           onKeyPress={onColumnNameFilterChange}
                           onChange={onColumnNameFilterChange}
                           placeholder="Search ..."
                           style={{ maxWidth: "auto" }}
              />
            </InputGroup>
          </Col>
          <Col>
            <ButtonToolbar
              className="justify-content-end"
              id="storage-widget-toolbar"
            >
              <ButtonGroup>
                <Button onClick={showCreateFolderModal} variant="outline-secondary" className="btn-outline border-none"
                        style={{fontSize: "1.5rem"}}>
                  <BsFolderPlus />
                </Button>
                <Button
                  variant="outline-secondary"
                  style={{fontSize: "1.5rem"}}
                  className="btn-outline border-none upload"
                >
                  <FormControl type="file" onChange={(e) => showFileDialog(e.target.files[0])}/>
                  <BsCloudUpload />
                </Button>
                <Button
                  disabled={downloadFileButtonDisabled}
                  onClick={onDownloadFile}
                  variant="outline-secondary"
                  style={{fontSize: "1.5rem"}}
                  className={downloadFileButtonDisabled ? "border-none" : "btn-outline border-none"}
                >
                  <BsCloudDownload />
                </Button>
                <Button
                  disabled={shareButtonDisabled}
                  onClick={startShareButtonHandler}
                  variant="outline-secondary"
                  style={{fontSize: "1.5rem"}}
                  className={shareButtonDisabled ? "border-none" : "btn-outline border-none"}
                >
                  <BsShare />
                </Button>
                <Button
                  disabled={deleteNodeButtonDisabled}
                  onClick={showDeleteNodeModal}
                  variant="outline-secondary"
                  style={{fontSize: "1.5rem"}}
                  className={deleteNodeButtonDisabled ? "border-none" : "btn-outline-danger border-none"}
                >
                  <BsTrash />
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>

        <br />

        <Breadcrumb className="breadcrumb breadcrumb-item">
          {subPaths.map((subPath, index) => (
            <Breadcrumb.Item
              active={index === subPaths.length - 1}
              key={subPath.name}
              onClick={() => {
                // Change path.
                setPath(subPath.path);
                getNodeByPathname(subPath.path);
              }}
              className="breadcrumb-item"
            >
              {subPath.name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <ControlledMenu {...showContextMenu} anchorPoint={anchorPoint}
                        onClose={() => setShowContextMenu(false)}>
          <MenuItem className="mc" onClick={() =>  setShowFileInfo(true)}>
            <BsInfoCircle/>&nbsp;&nbsp;File Info
          </MenuItem>
          <MenuItem className="mc" disabled={!contextSelectedItem.content_type} onClick={contextMenuDownloadFile}>
            <BsCloudDownload/>&nbsp;&nbsp;Download
          </MenuItem>
          <MenuItem className="mc"
            onClick={
              (e) => setThumbnailModal(true)
            } >
            <BiImageAdd/>&nbsp;&nbsp;Set Thumbnail
          </MenuItem>
          <MenuItem className="mc" disabled={contextSelectedItem.content_type ?
            !contextSelectedItem.content_type.startsWith("image/") : true } onClick={handleFilePreviewDialog}>
            <BsImage/>&nbsp;&nbsp;Preview
          </MenuItem>
          <MenuItem
            className="mc"
            disabled={contextSelectedItem.content_type ? !contextSelectedItem.content_type : true}
            onClick={() => startShareMenuButtonHandler()}>
            <FiLink/>&nbsp;&nbsp;{!shares.hasOwnProperty(contextSelectedItem.id) ? "Start Share" : "Share"}
          </MenuItem>
          <MenuItem
            className="mc"
            disabled={!shares.hasOwnProperty(contextSelectedItem.id)}
            onClick={() => stopShareMenuButtonHandler()}>
            <BsStopCircle/>&nbsp;&nbsp;Stop Share
          </MenuItem>
          <MenuItem className="mc" onClick={() => onDeleteNodeItem(contextSelectedItem)}>
            <BsTrash/>&nbsp;&nbsp;Delete
          </MenuItem>
          <MenuItem className="mc" onClick={showNodeReParentDialog}>
            <BsFolderSymlink/>&nbsp;&nbsp;Move
          </MenuItem>
          <MenuItem className="mc" onClick={() => setShowRenameModal(true)}>
            <MdOutlineDriveFileRenameOutline/>&nbsp;&nbsp;Rename
          </MenuItem>
          <MenuItem className="mc" disabled={!contextSelectedItem.content_type} onClick={handleCopyFilePath}>
            <BsCode/>&nbsp;&nbsp;Copy File Path
          </MenuItem>
          <MenuItem className="mc" disabled={contextSelectedItem.content_type ?
            !contextSelectedItem.content_type.startsWith("model/") : true } onClick={showModelHierarchyModal}>
            <VscTypeHierarchySub/>&nbsp;&nbsp;View Hierarchy
          </MenuItem>
          <MenuItem className="mc" disabled>
            <BsShuffle/>&nbsp;&nbsp;Convert & Download
          </MenuItem>
          <MenuItem className="mc" disabled={!parseRenderFromMetadata(contextSelectedItem.metadata)}
                    onClick={() => history.push(`/dashboard/compute?render_id=${parseRenderFromMetadata(contextSelectedItem.metadata)}`)}>
            <BsForward/>&nbsp;&nbsp;Go to Render
          </MenuItem>
        </ControlledMenu>

        <Tabs id="storage-tab" activeKey={tabKey} onSelect={(key) => tabHandler(key)}>
          <Tab eventKey="all" title="ALL">
            <BootstrapTable
              remote
              bootstrap5
              bordered={false}
              columns={columns}
              condensed
              data={data.folders.concat(data.files)}
              defaultSorted={defaultSorted}
              hover
              id="storage-all-tab"
              keyField="id"
              pagination={paginationFactory(tableOption)}
              ref={allTable}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              selectRow={selectRow}
              sort={onSort}
              onTableChange={function (type, {page, sizePerPage}) {
                const params = new URLSearchParams(history.location.search);

                if (type === "pagination") {
                  params.set("page", page);
                  params.set("size", sizePerPage);

                  setTableCurrentPage(page);
                  setSizePerPage(sizePerPage);
                  setSelectedFile([]);
                }

                history.push(`${history.location.pathname}?${params.toString()}`);
                const nextRefresh = refresh + 1;
                setRefresh(nextRefresh);
              }}
            />
          </Tab>
          <Tab eventKey="image" title="IMAGE">
            <BootstrapTable
              remote
              bootstrap5
              bordered={false}
              columns={columns}
              condensed
              data={data.files}
              defaultSorted={defaultSorted}
              hover
              id="storage-image-tab"
              keyField="id"
              pagination={paginationFactory(tableOption)}
              ref={allTable}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              selectRow={selectRow}
              sort={onSort}
              onTableChange={function (type, {page, sizePerPage}) {
                const params = new URLSearchParams(history.location.search);

                if (type === 'pagination') {
                  params.set("page", page);
                  params.set("filter", "image");
                  params.set("size", sizePerPage);

                  setTableCurrentPage(page);
                  setSizePerPage(sizePerPage);
                  setSelectedFile([]);
                }

                history.push(`${history.location.pathname}?${params.toString()}`);
                const nextRefresh = refresh + 1;
                setRefresh(nextRefresh);
              }}
            />
          </Tab>
          <Tab eventKey="model" title="MODEL">
            <BootstrapTable
              remote
              bootstrap5
              bordered={false}
              columns={columns}
              condensed
              data={data.files}
              defaultSorted={defaultSorted}
              hover
              id="storage-model-tab"
              keyField="id"
              pagination={paginationFactory(tableOption)}
              ref={allTable}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              selectRow={selectRow}
              sort={onSort}
              onTableChange={function (type, {page, sizePerPage}) {
                const params = new URLSearchParams(history.location.search);

                if (type === 'pagination') {
                  params.set("page", page);
                  params.set("filter", "model");
                  params.set("size", sizePerPage);

                  setTableCurrentPage(page);
                  setSizePerPage(sizePerPage);
                  setSelectedFile([]);
                }

                history.push(`${history.location.pathname}?${params.toString()}`);
                const nextRefresh = refresh + 1;
                setRefresh(nextRefresh);
              }}
            />
          </Tab>
          <Tab eventKey="video" title="VIDEO">
            <BootstrapTable
              remote
              bootstrap5
              bordered={false}
              columns={columns}
              condensed
              data={data.files}
              defaultSorted={defaultSorted}
              hover
              id="storage-video-tab"
              keyField="id"
              pagination={paginationFactory(tableOption)}
              ref={allTable}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              selectRow={selectRow}
              sort={onSort}
              onTableChange={function (type, {page, sizePerPage}) {
                const params = new URLSearchParams(history.location.search);
                if (type === 'pagination') {
                  params.set("page", page);
                  params.set("filter", "video");
                  params.set("size", sizePerPage);

                  setTableCurrentPage(page);
                  setSizePerPage(sizePerPage);
                  setSelectedFile([]);
                }

                history.push(`${history.location.pathname}?${params.toString()}`);
                const nextRefresh = refresh + 1;
                setRefresh(nextRefresh);
              }}
            />
          </Tab>
          <Tab eventKey="shared" title="SHARED">
            <BootstrapTable
              remote
              bootstrap5
              bordered={false}
              columns={columns}
              condensed
              data={data.files}
              defaultSorted={defaultSorted}
              hover
              id="storage-shared-tab"
              keyField="id"
              pagination={paginationFactory(tableOption)}
              ref={allTable}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              selectRow={selectRow}
              sort={onSort}
              onTableChange={function (type, {page, sizePerPage}) {
                const params = new URLSearchParams(history.location.search);
                if (type === 'pagination') {
                  params.set("page", page);
                  params.set("filter", "shared");
                  params.set("size", sizePerPage);

                  setTableCurrentPage(page);
                  setSizePerPage(sizePerPage);
                  setSelectedFile([]);
                }

                history.push(`${history.location.pathname}?${params.toString()}`);
                const nextRefresh = refresh + 1;
                setRefresh(nextRefresh);
              }}
            />
          </Tab>
        </Tabs>
      </div>

      {/*Context menu file info modal.*/}
      <Modal show={showFileInfo} onHide={() => setShowFileInfo(false)} centered className="fade modal-global">
        <Modal.Header closeButton>
          <Modal.Title className="text-warp">{contextSelectedItem.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3} style={{textAlign: "right"}}>
              Type:
            </Col>
            <Col xs={9} style={{textAlign: "left"}}>
              {contextSelectedItem.size ? "File" : "Folder"}
            </Col>
          </Row>
          {contextSelectedItem.size ? (
            <>
              <Row>
                <Col xs={3} style={{textAlign: "right"}}>
                  Kind:
                </Col>
                <Col xs={9} style={{textAlign: "left"}}>
                  {contextSelectedItem.content_type}
                </Col>
              </Row>
              <Row>
                <Col xs={3} style={{textAlign: "right"}}>
                  Size:
                </Col>
                <Col xs={9} style={{textAlign: "left"}}>
                  {parseStorageSize(contextSelectedItem.size)}
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          <Row>
            <Col xs={3} style={{textAlign: "right"}}>
              Where:
            </Col>
            <Col xs={9} style={{textAlign: "left"}} className="text-break">
              {subPaths.map((subPath, index) => (
                `${subPath.name} > `
              ))}
              {contextSelectedItem.name}
            </Col>
          </Row>
          <Row>
            <Col xs={3} style={{textAlign: "right"}}>
              Created:
            </Col>
            <Col xs={9} style={{textAlign: "left"}}>
              {contextSelectedItem.created_at ?
                dateFormat(new Date(contextSelectedItem.created_at * 1000), "dddd, dd mmmm yyyy HH:mm:ss") : ""}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" className="rounded-pill btn-wr" style={{marginRight: "2px", width: "100px"}}
                  onClick={() => setShowFileInfo(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/*Context menu file preview modal.*/}
      <Modal show={showFilePreview} onHide={() => setShowFilePreview(false)} centered className="fade modal-global" size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-warp">{contextSelectedItem.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image src={filePreSignedUrl} alt={contextSelectedItem.name} className="img-fluid"/>
        </Modal.Body>
      </Modal>

      {/*Context menu model hierarchy preview modal.*/}
      <Modal show={showModelHierarchy} onHide={() => setShowModelHierarchy(false)} centered size="xl" className="fade modal-global">
        <Modal.Header closeButton>
          <Modal.Title className="text-warp">{contextSelectedItem.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tree">
            <ul>
              <li className="parent_li"><span>{modelHierarchy.name}</span>
                <ul>
                  {modelHierarchy.children ? modelHierarchy.children.map((child) => (modelHierarchyParser(child))) : ""}
                </ul>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" className="rounded-pill btn-wr" style={{marginRight: "2px", width: "100px"}}
                  onClick={() => setShowModelHierarchy(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/*Context menu move node modal.*/}
      <Modal show={showFileMover} onHide={() => setShowFileMover(false)} centered className="fade modal-global" size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="text-warp">{contextSelectedItem.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-left storage">
          <div style={{margin: "10px"}}>
            <Row>
              <Col md={1}>
                From
              </Col>
              <Col md>
                <Breadcrumb className="breadcrumb breadcrumb-item">
                  {subPaths.map((subPath, index) => (
                    <Breadcrumb.Item
                      active
                      key={subPath.name}
                      className="breadcrumb-item">
                      {subPath.name}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
              <Col md={1}>
                To
              </Col>
              <Col md>
                <Breadcrumb className="breadcrumb breadcrumb-item">
                  {nodeReParentPath.map((subPath, index) => (
                    <Breadcrumb.Item
                      active={index === nodeReParentPath.length - 1}
                      key={subPath.name}
                      onClick={() => {
                        getStorageNodeByPath(subPath.path, 1)
                      }}
                      className="breadcrumb-item">
                      {subPath.name}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              </Col>
            </Row>
            <BootstrapTable
              remote
              bootstrap5
              bordered={false}
              columns={reParentDialogColumns}
              condensed
              data={reParentData.folders.concat(reParentData.files)}
              filter={filterFactory()}
              hover
              id="node-dialog"
              keyField="id"
              pagination={paginationFactory({
                sizePerPage: defaultPageLimit,
                totalSize: totalItemReParent,
                page: tableReParentCurrentPage,
                disablePageTitle: true,
                hideSizePerPage: true,
                pageButtonRenderer
              })}
              rowEvents={manageFileRowEvent}
              onTableChange={function (type, {page, sizePerPage}) {
                if (type === 'pagination') {
                  getStorageNodeByPath(nodeCurrentData.path, page);
                }
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" style={{marginRight: "2px", width: "100px"}}
                  onClick={() => setShowFileMover(false)}>Cancel</Button>
          <Button size="sm" className="rounded-pill btn-wr" style={{marginRight: "2px", width: "100px"}}
                  onClick={moveFileNodeHandler}>Move</Button>
        </Modal.Footer>
      </Modal>

      {/*Context menu rename modal.*/}
      <Modal centered className="fade modal-global" onHide={() => setShowRenameModal(false)} show={showRenameModal}>
        <Modal.Header closeButton>
          <Modal.Title>{contextSelectedItem.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <FormControl onChange={(event) => setRenameNodeName(event.target.value)} placeholder="New name" />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" onClick={() => setShowRenameModal(false)}>
            Cancel
          </Button>
          <Button size="sm" className="rounded-pill btn-group-sm btn-wr" onClick={() => renameNodeHandler()}>
            Rename
          </Button>
        </Modal.Footer>
      </Modal>

      {/*Context menu set thumbnail*/}
      <Modal centered className="fade modal-global" onHide={() => setThumbnailModal(false)} show={thumbnailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Set Thumbnail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <FormControl
              type="file"
              accept=".jpg,.jpeg,.png"
              ref={thumbnailInputRef}
              placeholder="File" />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" className="rounded-pill" onClick={() => setThumbnailModal(false)} >
            Cancel
          </Button>
          <Button
            size="sm"
            className="rounded-pill btn-group-sm btn-wr"
            onClick={() => onSetThumbnail(contextSelectedItem.id, thumbnailInputRef.current.files[0])} >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

StorageWidget.propTypes = {};

StorageWidget.defaultProps = {};

export default StorageWidget;
