import {Container} from "react-bootstrap";

import HomeNavbar from "../../components/HomeNavbar/HomeNavbar";
import HomeFooter from "../../components/HomeFooter/HomeFooter";

const PrivacyPage = (props) => {
  return (
    <div>
      <HomeNavbar/>

      <main className="py-5">
        <Container>
          <h2 className="mb-3">Privacy Policy</h2>
          <p className="lead">
            J Cube Inc. respects the privacy of all users of our products and services. The present privacy policy describes
            the ways and the terms under which J Cube and its subsidiaries process and use your personal data. We recommend
            you to read this privacy policy so that you understand our approach towards the processing and use of your
            personal data. By submitting your personal data to us, you give your permission for its processing and use by us
            as described in the present policy. Governing law applicable to this Privacy Policy is the Japanese law, and any
            and all disputes as regards our relationships under this Privacy Policy will be settled as provided for by the
            governing law.
          </p>

          <h6 className="text-uppercase">
            Personal Data
          </h6>
          <p>
            Your personal data may be collected in relation to the access and use of our website, the license acquisition
            by you for the use of our products, and in relation to the provision of services to you.
          </p>
          <p>
            Aiming to protect your consumer rights from harmful misinterpretation of your messages exchanged via our
            communication systems tools, and/or improper performance of your order(s), we set our systems to receive and
            retain a copy of any communication you might have with third parties while using our website and our communication
            system tools. We will handle any information collected thereby, with the due care and according to our
            obligations of a personal data controller.
          </p>

          <h6 className="text-uppercase">
            Purpose of Collection
          </h6>
          <p>
            We collect and use personal information (including, without limitation, name, adress, title, telephone number,
            email) to better provide our products, services, or information to you. We would therefore use your personal
            information in order to:
          </p>
          <ul>
            <li>respond to queries or requests submitted by you;</li>
            <li>process orders or applications submitted by you or for you;</li>
            <li>exercise or otherwise carry out our rights and obligations, related to our products or to contractual or
              extra-contractual relations between you and us;
            </li>
            <li>anticipate and resolve problems with any products or services supplied to you; and</li>
            <li>create products or services that may meet your needs.</li>
          </ul>
          <p>
            If you are a professional business customer, we may share your personal information with our channel partners
            and sales partners for operational purposes, such as order fulfillment and to confirm information about what
            applications you have access to. Note that you may receive marketing communications from our channel partners
            based on your relationship with them.
          </p>

          <h6 className="text-uppercase">
            Consent
          </h6>
          <p>
            You acknowledge and agree that looking for a better satisfaction of your consumer’s needs, you (and third
            parties acting on your behalf) may have to provide, and we and our resellers (and third parties acting on behalf
            of us and our resellers) may acquire, certain personal information and data with respect to you. By accepting
            our terms of use and the privacy policy hereof, you hereby consent to us processing such information and data.
            We take appropriate technical measures to protect our consumer personal information and data. Personal
            information and data provided to us on various occasions may be processed in Japan, or any other country in
            which we, or our subsidiaries, affiliates or resellers, operate and/or maintain facilities. By accepting our
            privacy policy, you consent to the transfer and processing of such information outside of your country. We will
            keep your personal information and data for as long as necessary to fulfil the above purposes, or as required by
            law. In any case, such personal information and data will be processed only for the purposes agreed hereby, and
            as long as there is a valid data subject’s consent in place. You may modify and correct incomplete or inaccurate
            data at any time by notifying us of any change to <a href="mailto:info@j-cube.jp">info@j-cube.jp</a>.
          </p>

          <h6 className="text-uppercase">
            Direct Marketing
          </h6>
          <p>
            To optimize our products and services, we may wish to use your personal data for direct marketing. As we respect
            your privacy, we will only use your personal data for this purpose when you are aware thereof and if required we
            will request your consent prior to using your personal data for direct marketing. If at any time you wish us to
            stop using your information for direct marketing, please contact us as set out below. We will stop the use of
            your information for such purposes as soon as it is reasonably possible to do so.
          </p>

          <h6 className="text-uppercase">
            Sharing Personal Data
          </h6>
          <p>
            We will share your personal information with your consent. We may share or disclose personal information to our
            related parties and to our distributors and agents. In such cases, these companies must abide by our data
            privacy requirements and are not allowed to use the information for any other purpose. We may also disclose
            personal information as part of a corporate transaction, such as a merger or sale of assets. We may access,
            disclose and preserve your personal information, when we have a good faith belief that doing so is necessary to:
          </p>
          <ul>
            <li>comply with applicable law or respond to valid legal process from competent authorities, including from law
              enforcement or other government agencies;
            </li>
            <li>protect our customers, for example to prevent spam or attempts to defraud users of the services;</li>
            <li>operate and maintain the security of our products and services, including to prevent or stop an attack on
              our computer systems or networks; or
            </li>
            <li>protect our rights and legitimate interests, including enforcing the terms governing the use of our products
              and services.
            </li>
          </ul>
          <p>
            Please note that some of our sites, products and services include links to sites, products or services of third
            parties that have privacy practices different from ours. If you submit personal information to any of those
            sites or services, your information is governed by the privacy statements of those third parties. In addition,
            the collected personal data may (occasionally) be transferred to selected third parties, which may be located
            outside Japan in relation to the services provided to you by us or by our agents and distributors. By way
            of example, this may happen if any of our servers are from time to time located in a country outside of Japan or
            one of our service providers is located in a country outside of Japan. These third parties will not use your
            personal information for any other purposes than what we have agreed with them. We request those third parties
            to implement adequate levels of protection in order to safeguard your personal information. We respect your
            personal information and therefore, we will take steps to ensure that your privacy rights continue to be
            protected if we transfer your information outside of Japan in this way.
          </p>

          <h6 className="text-uppercase">
            Disclosing Personal Data
          </h6>
          <p>
            Except as set out in this privacy policy, we will not disclose any personally identifiable information without
            your permission unless we are legally entitled or required to do so (for example, if required to do so by legal
            process or for the purposes of prevention of fraud or other crime), or if we believe that such action is
            necessary to protect and/or defend our rights, property or personal safety and those of our users/customers or
            other individuals.
          </p>

          <h6 className="text-uppercase">
            Non-Personal Data
          </h6>
          <p>
            We may automatically collect non-personal information about you such as your country, O/S, screen resolution,
            type of internet browser you are using and their versions, or the website from which you landed on our website
            and your interactions with the website such as clicks, scroll, etc. You cannot be identified from this
            information, and it is only used to assist us in providing an effective service on this website.
          </p>

          <h6 className="text-uppercase">
            Keeping Records Accurate
          </h6>
          <p>
            We aim to keep our information about you as accurate as possible. If you would like to review, change or delete
            the details you have supplied us with, please contact us as set out below.
          </p>

          <h6 className="text-uppercase">
            Security of Personal Data
          </h6>
          <p>
            We have implemented technology and policies with the objective of protecting your privacy from unauthorized
            access and improper use and will update these measures as new technology becomes available, as appropriate.
          </p>

          <h6 className="text-uppercase">
            Cookies Policy
          </h6>
          <p>
            We use the term “cookies” to refer to cookies and other similar technologies. Cookies are small data files that
            your browser places on your computer or device. Cookies help your browser navigate a website, however they
            cannot collect any information stored on your computer or your files. When a server uses a web browser to read
            cookies, they can help a website deliver a more user-friendly service. To protect your privacy, your browser only
            gives a website access to the cookies it has already sent to you.
          </p>
          <p>
            We do not use cookies directly. However, for some functions within our website we use third party
            suppliers, for example, when you visit a page with embedded videos or links to third party website. These
            videos or links (and any other content from third party suppliers) may contain third party cookies, and you may
            wish to consult the policies of these third party website for information regarding their use of cookies.
          </p>
          <p>
            Should you wish to do so, you can choose to reject or block the cookies for the website of any third party
            suppliers by changing your browser settings – see the help function within your browser for further details.
            Please note that most browsers automatically accept cookies, so if you do not wish cookies to be used you may
            need to actively delete or block the cookies. For information on the use of cookies in mobile phone browsers and
            for details on how to reject or delete such cookies, please refer to your handset manual. Note, however, that if
            you reject the use of cookies you will still be able to visit our website, but some of the functions may not work
            correctly.
          </p>

          <h6 className="text-uppercase">
            Changes to this Policy
          </h6>
          <p>
            From time to time, we may make changes to this privacy policy. If we make any substantial changes to this privacy
            policy and the way in which we use your personal data, we will post these changes on this page. Please check our
            privacy policy on a regular basis.
          </p>

          <h6 className="text-uppercase">
            How to Contact Us
          </h6>
          <p>
            If you would like to know more about our privacy policy please contact us via <a
            href="mailto:info@j-cube.jp">info@j-cube.jp</a>.
          </p>
        </Container>
      </main>

      <HomeFooter/>
    </div>
  );
}

PrivacyPage.propTypes = {};
PrivacyPage.defaultProps = {};

export default PrivacyPage;