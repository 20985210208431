import {useEffect, useState} from "react";
import {Button, Card, Col, Form, FormCheck, Modal, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import validator from "validator";
import {TbFaceIdError} from "react-icons/tb";
import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import {CgChevronDoubleDown, CgChevronDoubleUp, CgSelect} from "react-icons/cg";
import dateFormat from "dateformat";
import copy from "copy-to-clipboard";
import saveAs from "file-saver";
import {ControlledMenu, MenuItem, useMenuState} from "@szhsin/react-menu";
import {VscCopy} from "react-icons/vsc";

import {
  createKey,
  getKey,
  getUserPreference,
  updatePassword,
  updateUserAddress,
  updateUserPreference
} from "../../api/user";
import {getUserAndAddress} from "../../services/user";
import {createCustomerPortal, listPayment} from "../../api/stripe";
import {pageButtonRenderer, sizePerPageRenderer} from "../BoostrapTable/BootstrapTable";
import {getOffsetFromPageNumber} from "../../utils/pagination";
import {paginationOrderConverter} from "../../utils/parser";
import {getFilteredDate} from "../../utils/table";
import {defaultSizePerPage} from "../../constants/bootstrapTable";

import ErrorDialog from "../../components/ErrorWidget/ErrorWidget";

import "../../styles/Modal.css";
import "../../styles/styles.css";
import "./AccountWidget.css";

const AccountWidget = (props) => {
  const [refresh, setRefresh] = useState(0);
  const [changePasswordForm, setChangePasswordForm] = useState(false);
  const [user, setUser] = useState({});
  const [city, setCity] = useState("");
  const [country, setCountry] = useState(null);
  const [state, setState] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [apiKey, setApiKey] = useState({public_key: "", user_agent: ""});

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [showPasswordChecklist, setShowPasswordCheckList] = useState(false);

  // Context menu.
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useMenuState();
  const [contextSelectedItem, setContextSelectedItem] = useState({});

  const [currentPasswordRequired, setCurrentPasswordRequired] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [retypePasswordRequired, setRetypePasswordRequired] = useState(false);
  const [missMatchRetypePassword, setMissMatchRetypePassword] = useState(false);
  const [newsUpdate, setNewsUpdate] = useState(false);

  const [tabKey, setTabKey] = useState("all");
  const [sortTable, setSortTable] = useState("created_at");
  const [orderTable, setOrderTable] = useState("desc");
  const [sizePerPage, setSizePerPage] = useState(100);
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [data, setData] = useState([]);
  const [tableRefresh, setTableRefresh] = useState(0);
  const [retryTableReq, setRetryTableReq] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState({ show: false });
  const [loadingButton, setLoadingButton] = useState(false);
  const [showRetryButton, setShowRetryButton] = useState(false);

  const cancelUpdateUserAddress = () => {
    // Set the fields with original value
    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  };

  const closeChangePasswordForm = () => {
    setChangePasswordForm(false);
    setShowPasswordCheckList(false);

    // Clearing the error messages
    currentPasswordErrorClear();
    newPasswordErrorClear();
    retypePasswordErrorClear();

    setCurrentPassword("");
    setNewPassword("");
    setRetypePassword("");
  };
  const showChangePasswordForm = () => setChangePasswordForm(true);

  const currentPasswordErrorClear = () => {
    setCurrentPasswordRequired(false);
  };

  const newPasswordErrorClear = () => {
    setNewPasswordRequired(false);
  };

  const retypePasswordErrorClear = () => {
    setRetypePasswordRequired(false);
    setMissMatchRetypePassword(false);
  };

  const changePassword = () => {
    setLoadingButton(true);
    setShowPasswordCheckList(false);

    if (
      validator.isEmpty(currentPassword) ||
      validator.isEmpty(newPassword) ||
      validator.isEmpty(retypePassword)
    ) {
      setCurrentPasswordRequired(validator.isEmpty(currentPassword));
      setNewPasswordRequired(validator.isEmpty(newPassword));
      setRetypePasswordRequired(validator.isEmpty(retypePassword));

      setLoadingButton(false);

      // Skip the process
      return;
    }

    if (newPassword !== retypePassword) {
      setMissMatchRetypePassword(true);
      setLoadingButton(false);

      // Skip the process
      return;
    }

    updatePassword(newPassword, currentPassword).then(() => {
      setLoadingButton(false);
      closeChangePasswordForm();
    }).catch(err => {
      setLoadingButton(false);
      closeChangePasswordForm();
      setShowErrorMessage({
        show: true,
        title: "Error",
        message: `Failed to update user password.`
      });
    });
  };

  const onUpdateUserAddress = async () => {
    setLoadingButton(true);

    const payload = {
      city: city,
      company: company,
      country: country,
      name: name,
      state_or_province: state,
    };
    await updateUserAddress(payload)
      .then(() => {
        setLoadingButton(false);
      })
      .catch((err) => {
        setShowErrorMessage({
          show: true,
          title: "Error",
          message: err.message,
        });

        setLoadingButton(false);
      });
  };

  const onCreateNewKey = async () => {
    setLoadingButton(true);

    await createKey().then((res) => {
      setApiKey({public_key: res.public_key, user_agent: res.user_agent});

      // Save public and private keys as CSV, this might change in future.
      const blob = new Blob([res.public_key, ",", res.private_key], {type: "text/plain;charset=utf-8"});
      saveAs(blob, "werender.key")

      setLoadingButton(false);
    }).catch((err) => {
      setShowErrorMessage({
        show: true,
        title: "Error",
        message: err.message,
      });
      setLoadingButton(false);
      });
  };

  const onUpdateUserPreference = (checked) => {
    setNewsUpdate(checked);
    updateUserPreference({subscribe_to_mail: checked}).catch(err => {
      setShowErrorMessage({
        show: true,
        title: "Error",
        message: `Failed to update user preference.`
      });
    });
  }

  useEffect(() => {
    props.setProgressBar(true);

    const onLoadUserData = async () => {
      const [user, address] = await getUserAndAddress();
      setUser(user);
      setName(address.name);
      setCompany(address.company);
      setState(address.state_or_province);
      setCity(address.city);
      setCountry(address.country);

      await getUserPreference().then(res => {
        setNewsUpdate(res.subscribe_to_mail);
      });

      await getKey().then(res => {
        setApiKey({public_key: res.public_key, user_agent: res.user_agent});
      }).catch(() => {
        setApiKey({public_key: "", user_agent: ""});
      });
    };

    onLoadUserData().then(() => {
      props.setProgressBar(false);
      setShowRetryButton(false);
    }).catch(() => {
      props.setProgressBar(false);
      setShowRetryButton(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    let payload = {
      pagination_options: {
        column: sortTable,
        offset: getOffsetFromPageNumber(tableCurrentPage, sizePerPage),
        order: paginationOrderConverter(orderTable),
        size: sizePerPage
      }
    }

    if (sortTable === "type") {
      payload.pagination_options.column = "event_type";
    }

    const filteredDate = getFilteredDate(tabKey);
    if (filteredDate) {
      payload.created_after = filteredDate;
    }

    listPayment(payload).then(res => {
      const data = res.data ? res.data : [];

      setTotalSize(res.page.item_count);
      setTableCurrentPage(tableCurrentPage);
      setData(data);

      setRetryTableReq(false);
    }).catch(() => {
      setRetryTableReq(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRefresh]);

  const retryTable = () => {
    const nextRefresh = tableRefresh + 1;
    setTableRefresh(nextRefresh);
  }

  const retry = () => {
    const nextRefresh = refresh + 1;
    setRefresh(nextRefresh);
  }

  const getCustomerPortal = () => {
    const windowReference = window.open();
    createCustomerPortal().then(res => {
      windowReference.location = res.url;
    }).catch(() => {
      windowReference.location = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL;
    });
  }

  const tabHandler = (key) => {
    setTabKey(key);
    setTableCurrentPage(1);

    const nextRefresh = tableRefresh + 1;
    setTableRefresh(nextRefresh);
  }

  const sortCaretFormatter = (order, column) => {
    if (!order) {
      return (
        <span style={{verticalAlign: ".125em"}}>
          &nbsp;<CgSelect/>
        </span>
      );
    } else if (order === 'asc') {
      return (
        <span style={{verticalAlign: ".125em"}}>
          &nbsp;<CgChevronDoubleUp/>
        </span>
      );
    } else if (order === 'desc') {
      return (
        <span style={{verticalAlign: ".125em"}}>
          &nbsp;<CgChevronDoubleDown/>
        </span>
      );
    }
    return null;
  }

  const formatRenderId = (cell, row) => {
    return (
      <span data-toggle="tooltip" title={cell}>{cell}</span>
    );
  }

  const sortingHandler = (field, order) => {
    setSortTable(field);
    setOrderTable(order);
  }

  const formatTimestamp = (cell, row) => {
    const date = new Date(cell * 1000);
    return dateFormat(date, 'yyyy/mm/dd @ HH:mm:ss');
  };

  const supportedCurrencies =  {
    JPY: {
      zero_decimal: true
    },
    USD: {
      zero_decimal: false
    }
  }

  const currencyFormatter = (cell, row) => {
    if (cell) {
      const currency = supportedCurrencies[row.currency];
      if (!currency) {
        return `${row.currency} ${cell}`;
      }

      if (currency.zero_decimal) {
        return new Intl.NumberFormat("en-US",
          {
            style: "currency",
            currency: row.currency
          }).format(cell);
      }

      const cent = 100;
      const amount = cell / cent;
      return new Intl.NumberFormat("en-US",
        {
          style: "currency",
          currency: row.currency
        }).format(amount);
    }

    return `${row.currency} ${cell}`;
  }

  const typeColumnFormatter = (cell, row) => {
    if (cell === "customer.subscription.updated") {
      return "Subscription"
    }

    if (cell === "checkout.session.completed") {
      return "Credits purchase"
    }

    return cell
  }

  const tableOption = {
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    page: tableCurrentPage,
    disablePageTitle: true,
    pageButtonRenderer,
    sizePerPageRenderer,
    sizePerPageList: defaultSizePerPage
  }

  const columns = [
    {
      align: "left",
      dataField: "id",
      formatter: formatRenderId,
      style: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "5vh",
      },
      text: "ID",
    },
    {
      align: "left",
      dataField: "type",
      formatter: typeColumnFormatter,
      sort: true,
      style: {
        width: "15vh",
      },
      headerAlign: "left",
      text: "Type",
      onSort: sortingHandler,
      sortCaret: sortCaretFormatter,
    },
    {
      align: "right",
      dataField: "amount",
      formatter: currencyFormatter,
      sort: true,
      headerAlign: "right",
      style: {
        width: "15vh",
      },
      text: "Amount",
      onSort: sortingHandler,
      sortCaret: sortCaretFormatter,
    },
    {
      align: "center",
      dataField: "created_at",
      formatter: formatTimestamp,
      sort: true,
      style: {
        maxWidth: "10vh",
      },
      headerAlign: "center",
      text: "Date",
      onSort: sortingHandler,
      sortCaret: sortCaretFormatter,
    },
  ];

  const defaultSorted = [{
    dataField: "created_at",
    order: "desc",
  }, ];

  const onSort = {
    dataField: sortTable,
    order: orderTable
  }

  // Handle click for folder.
  const rowEvents = {
    onContextMenu: (e, row, rowIndex) => {
      e.preventDefault();
      setAnchorPoint({ x: e.clientX, y: e.clientY });
      setShowContextMenu(true);
      setContextSelectedItem(row);
    }
  };

  const selectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    style: { backgroundColor: 'rgba(32, 194, 175, 0.19)' },
  }

  const rowStyle = (row, rowIndex) => {
    let style = {};
    if ((row.id === contextSelectedItem.id) && (showContextMenu.state === "open")) {
      style.backgroundColor = 'rgb(222, 222, 222)';
    }
    return style;
  }

  const copyTransactionId = () => {
    copy(contextSelectedItem.id);
  }

  return (
    <div id="account-widget" className="g-5">
      <ErrorDialog
        showErrorMessage={showErrorMessage}
        setShowErrorMessage={setShowErrorMessage}
      />

      <Row className="text-center justify-content-center" style={{display: showRetryButton ? "block" : "none"}}>
        <TbFaceIdError style={{height: "100px"}}/>
        <h6>Sorry, We are failed to show account, Click the Retry button to retry.</h6>
        <Button className="btn-wr btn-sm rounded-pill" style={{width: "100px"}} onClick={retry}>Retry</Button>
      </Row>

      <div style={{display: showRetryButton ? "none" : "block"}} className="account">
        <h5 style={{ marginTop: "30px" }}>Login Information</h5>
        <Card>
          <Card.Body>
            <Row className="mb-3">
              <Col xs={3}>Email</Col>
              <Col xs={9}>{user.email}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>Password</Col>
              <Col xs={9}>
                <Button variant="link" style={{ color: "#20C2AF" }} onClick={showChangePasswordForm} className="p-0">
                  Change password
                </Button>
              </Col>
            </Row>
          </Card.Body>

          <Modal show={changePasswordForm} onHide={closeChangePasswordForm} centered className="fade modal-global">
            <Modal.Header className="p-1" style={{ position: "relative" }}>
              <h5 style={{ marginLeft: "40px", marginTop: "10px" }}>
                Change Password
              </h5>
            </Modal.Header>
            <Modal.Body className="p-0">
              <Form style={{ backgroundColor: "rgba(32, 194, 175, 0.12)" }}>
                <br />
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="password"
                        id="current-password"
                        defaultValue={currentPassword}
                        size="sm"
                        placeholder="Current Password"
                        style={{
                          border: currentPasswordRequired ? "2px solid red" : "",
                        }}
                        onClick={currentPasswordErrorClear}
                        onChange={(e) => {
                          setCurrentPassword(e.target.value);
                        }}
                      />
                      <Form.Label htmlFor="current-password">
                        Current Password
                      </Form.Label>
                      {currentPasswordRequired ? (
                        <Form.Text
                          className="text-danger"
                          style={{ color: "red" }}
                        >
                          The current password is required
                        </Form.Text>
                      ) : (
                        ""
                      )}
                    </Form.Floating>
                  </Col>
                </Row>
                <br />
              </Form>
              <br />
              <Form>
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="password"
                        id="new-password"
                        defaultValue={newPassword}
                        size="sm"
                        placeholder="New Password"
                        style={{
                          border: newPasswordRequired ? "2px solid red" : "",
                        }}
                        onClick={newPasswordErrorClear}
                        onSelect={() => setShowPasswordCheckList(true)}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                      />
                      <Form.Label htmlFor="new-password">New Password</Form.Label>
                      <PasswordStrengthBar password={newPassword} minLength={8} />
                    </Form.Floating>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="password"
                        id="retype-password"
                        defaultValue={retypePassword}
                        size="sm"
                        placeholder="Retype Password"
                        style={{
                          border:
                            retypePasswordRequired || missMatchRetypePassword
                              ? "2px solid red"
                              : "",
                        }}
                        onClick={retypePasswordErrorClear}
                        onChange={(e) => {
                          setRetypePassword(e.target.value);
                        }}
                      />
                      <Form.Label htmlFor="retype-password">Retype Password</Form.Label>
                      <PasswordChecklist style={{display: showPasswordChecklist ? "block" : "none"}}
                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                        minLength={8}
                        value={newPassword}
                        valueAgain={retypePassword}
                        onChange={(isValid) => {setIsValidPassword(isValid)}}
                      />
                    </Form.Floating>
                  </Col>
                </Row>
              </Form>
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="outline-dark" className="rounded-pill" style={{ marginRight: "2px", width: "100px" }}
                      onClick={closeChangePasswordForm}>
                Cancel
              </Button>
              <Button size="sm" className="btn-group-sm rounded-pill btn-wr" style={{ marginLeft: "2px", width: "100px" }}
                      onClick={changePassword} disabled={!(isValidPassword) || loadingButton}>
                {loadingButton ? <Spinner as="span" size="sm" role="status" animation="border" variant="light"/> : ""}Save
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>

        <h5 style={{ marginTop: "30px" }}>Notification</h5>
        <Card>
          <Card.Body>
            <Row className="mb-3">
              <Col xs={3}>Get update emails</Col>
              <Col xs={9}>
                <FormCheck className="form-check mb-1">
                  <FormCheck.Input
                    type="checkbox"
                    value="signup-news-update"
                    checked={newsUpdate}
                    onChange={(e) => onUpdateUserPreference(e.target.checked)}
                  />
                </FormCheck>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <h5 style={{ marginTop: "30px" }}>Current Plan</h5>
        <Card>
          <Card.Body>
            <Row className="mb-3">
              <Col>
                Your current plan is:{" "}
                <b style={{ textTransform: "uppercase" }}>{user.role}</b>
              </Col>
              <Col xs={12} sm={5} md={5} lg={4} xl={4} xxl={4} className="text-center">
                {user.role === "basic" ? (
                  <Button
                    as="a"
                    target="_blank"
                    size="sm"
                    href={process.env.REACT_APP_STRIPE_PRO_LICENSE_URL}
                    className="btn-wr rounded-pill w-100">
                    Upgrade to Pro
                  </Button>
                ) : (
                  <Button
                    as="a"
                    target="_blank"
                    size="sm"
                    href="mailto:sales@j-cube.jp"
                    className="btn-wr rounded-pill w-100">
                    Upgrade to Enterprise
                  </Button>
                )}
              </Col>
            </Row>
            {user.role === "basic" ? (
              <div/>
            ):(
              <Button
                as="a"
                size="sm"
                onClick={getCustomerPortal}
                className="btn-wr rounded-pill w-100">
                Manage Subscription
              </Button>
            )}
          </Card.Body>
        </Card>

        <h5 style={{ marginTop: "30px" }}>Optional Information</h5>
        <Card>
          <Card.Body>
            <Form>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Name
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Company
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="Company"
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  City
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  State/Province
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="State/Province"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Country
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Form>
            <br />
            <Row>
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" variant="outline-dark" className="rounded-pill" style={{ marginRight: "2px", width: "100px" }}
                        onClick={cancelUpdateUserAddress}>
                  Cancel
                </Button>
                <Button size="sm" className="btn-group-sm rounded-pill btn-wr" style={{ marginLeft: "2px", width: "100px" }}
                        onClick={onUpdateUserAddress} disabled={loadingButton}>
                  {loadingButton ? <Spinner as="span" size="sm" role="status" animation="border" variant="light"/> : ""}Save
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <h5 style={{ marginTop: "30px" }}>Additional WeRender Credits (WRC)</h5>
        <small className="text-muted">
          <small>
            <i>
              Additionally purchased credits don’t expire, as long as you are
              under subscription.
            </i>
          </small>
        </small>
        <Card>
          <Card.Body>
            <Row className="mb-3">
              <Col>
                Buy more WeRender Credits (WRC)
                <br />
                <small className="text-muted">
                  <small>10.000 WRC / 100 USD</small>
                </small>
              </Col>
              <Col xs={12} sm={3} className="text-center">
                <Button
                  as="a"
                  size="sm"
                  target="_blank"
                  href={process.env.REACT_APP_STRIPE_ADDITIONAL_CREDIT_URL}
                  className="btn-group-sm btn-wr rounded-pill w-100"
                >
                  Buy
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <h5 style={{ marginTop: "30px" }}>API Key</h5>
        <small className="text-muted">
          <small>
            <i>
              Authenticate with API KEY / TOKEN rather than with user / password.
            </i>
          </small>
        </small>
        <Card>
          <Card.Body>
            <Form>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Public Key
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Public Key"
                    value={apiKey.public_key}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  User Agent
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="User Agent"
                    value={apiKey.user_agent}
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Form>
            <br />
            <Row>
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" className="btn-group-sm rounded-pill btn-wr" style={{ marginLeft: "2px", width: "100px" }}
                        onClick={onCreateNewKey} disabled={loadingButton}>
                  {loadingButton ? <Spinner as="span" size="sm" role="status" animation="border" variant="light"/> : ""} Generate
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <h5 style={{ marginTop: "30px" }}>Transaction History</h5>
        <small className="text-muted">
          <small>
            <i>History of successful transactions.</i>
          </small>
        </small>
        <Card className="transaction-table">
          <Card.Body>
            <Row className="text-center justify-content-center" style={{display: retryTableReq ? "block" : "none"}}>
              <TbFaceIdError style={{height: "100px"}}/>
              <h6>Sorry, We are failed to show account, Click the Retry button to retry.</h6>
              <Button className="btn-wr btn-sm rounded-pill" style={{width: "100px"}} onClick={retryTable}>Retry</Button>
            </Row>

            <div style={{display: retryTableReq ? "none" : "block"}}>

              <ControlledMenu {...showContextMenu} anchorPoint={anchorPoint}
                              onClose={() => setShowContextMenu(false)}>
                <MenuItem className="mc" onClick={copyTransactionId}>
                  <VscCopy/>&nbsp;&nbsp;Copy Transaction ID</MenuItem>
              </ControlledMenu>

              <Tabs id="transaction-tab" activeKey={tabKey} onSelect={(key) => tabHandler(key)}>
                <Tab eventKey="all" title="ALL">
                  <BootstrapTable
                    remote
                    bootstrap5
                    bordered={false}
                    columns={columns}
                    condensed
                    data={data}
                    defaultSorted={defaultSorted}
                    hover
                    id="transaction-all-table"
                    keyField="id"
                    pagination={paginationFactory(tableOption)}
                    sort={onSort}
                    rowStyle={rowStyle}
                    selectRow={selectRow}
                    rowEvents={rowEvents}
                    onTableChange={function (type, {page, sizePerPage}) {
                      if (type === 'pagination') {
                        setTableCurrentPage(page);
                        setSizePerPage(sizePerPage);
                        setTabKey("all");
                      }

                      const nextRefresh = tableRefresh + 1;
                      setTableRefresh(nextRefresh);
                    }}
                  />
                </Tab>
                <Tab eventKey="month" title="THIS MONTH">
                  <BootstrapTable
                    remote
                    bootstrap5
                    bordered={false}
                    columns={columns}
                    condensed
                    data={data}
                    defaultSorted={defaultSorted}
                    hover
                    id="transaction-mont-table"
                    keyField="id"
                    pagination={paginationFactory(tableOption)}
                    sort={onSort}
                    rowStyle={rowStyle}
                    selectRow={selectRow}
                    rowEvents={rowEvents}
                    onTableChange={function (type, {page, sizePerPage}) {
                      if (type === 'pagination') {
                        setTableCurrentPage(page);
                        setSizePerPage(sizePerPage);
                        setTabKey("month");
                      }

                      const nextRefresh = tableRefresh + 1;
                      setTableRefresh(nextRefresh);
                    }}
                  />
                </Tab>
                <Tab eventKey="week" title="THIS WEEK">
                  <BootstrapTable
                    remote
                    bootstrap5
                    bordered={false}
                    columns={columns}
                    condensed
                    data={data}
                    defaultSorted={defaultSorted}
                    hover
                    id="transaction-week-table"
                    keyField="id"
                    pagination={paginationFactory(tableOption)}
                    sort={onSort}
                    rowStyle={rowStyle}
                    selectRow={selectRow}
                    rowEvents={rowEvents}
                    onTableChange={function (type, {page, sizePerPage}) {
                      if (type === 'pagination') {
                        setTableCurrentPage(page);
                        setSizePerPage(sizePerPage);
                        setTabKey("week");
                      }

                      const nextRefresh = tableRefresh + 1;
                      setTableRefresh(nextRefresh);
                    }}
                  />
                </Tab>
                <Tab eventKey="today" title="TODAY">
                  <BootstrapTable
                    remote
                    bootstrap5
                    bordered={false}
                    columns={columns}
                    condensed
                    data={data}
                    defaultSorted={defaultSorted}
                    hover
                    id="transaction-today-table"
                    keyField="id"
                    pagination={paginationFactory(tableOption)}
                    sort={onSort}
                    rowStyle={rowStyle}
                    selectRow={selectRow}
                    rowEvents={rowEvents}
                    onTableChange={function (type, {page, sizePerPage}) {
                      if (type === 'pagination') {
                        setTableCurrentPage(page);
                        setSizePerPage(sizePerPage);
                        setTabKey("today");
                      }

                      const nextRefresh = tableRefresh + 1;
                      setTableRefresh(nextRefresh);
                    }}
                  />
                </Tab>
              </Tabs>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

AccountWidget.propTypes = {};
AccountWidget.defaultProps = {};

export default AccountWidget;
