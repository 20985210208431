export const getFileContentType = (file) => {
  let ext = file.name.split(".").pop();
  switch (ext) {
    case "abc":
      return "model/abc";
    case "exr":
      return "image/exr";
    case "fbx":
      return "model/fbx";
    case "glb":
      return "model/glb";
    case "gltf":
      return "model/gltf";
    case "hdr":
      return "image/hdr";
    case "stl":
      return "model/stl";
    case "stp":
    case "step":
      return "model/step";
    case "usd":
    case "usda":
    case "usdc":
      return "model/usd";
    case "usdz":
      return "model/usdz";
    default:
      if (file.type) {
        return file.type;
      }
      return "application/octet-stream";
  }
};
