import {Button, Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import Styles from "./HomeWidget.module.css";

const SolutionLayout = () => {
  const { t } = useTranslation(["home.solution"]);

  return (
    <div className={Styles.BackgroundWhite}>
      <Container className="py-5 col-lg-8">
        <div className="mx-auto text-center px-5">
          <h6 className="display-6 fw-normal">{t('home.solution:title')}</h6>
          <p className="fs-6 text-muted">
            {t('home.solution:description')}
          </p>
        </div>
        <Row className="row-cols-3 g-3 pt-3 pb-5">
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-cpu`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:ai')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-palette`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:visual')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-box`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:manufacturing')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-truck`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:automotive')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-building`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:aec')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-share-fill`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:scientific')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-door-open`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:furniture')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-vector-pen`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:design')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-shop`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:advertising')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-eyedropper`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:healthcare')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-cart4`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:fashion')}
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={`${Styles.BI} ${Styles.MarketFontSize} bi-handbag`}/>
            </div>
            <div className="flex-grow-1 ms-3">
              {t('home.solution:ecommerce')}
            </div>
          </Col>
        </Row>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
          <Button as="a" variant="outline-success" className={`${Styles.ButtonOutlineWerender} px-4 rounded-pill`}
                  href="/explore">{t('home.solution:explore')}</Button>
        </div>
      </Container>
    </div>
  );
}

SolutionLayout.propTypes = {};
SolutionLayout.defaultProps = {};

export default SolutionLayout;