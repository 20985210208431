import AboutLayout from "./AboutLayout.js";
import APILayout from "./APILayout";
import AssetLayout from "./AssetLayout";
import FeatureLayout from "./FeatureLayout";
import PricingLayout from "./PricingLayout";
import SolutionLayout from "./SolutionLayout";
import IOLayout from "./IOLayout";
import WerenderLayout from "./WerenderLayout";

import Styles from "./HomeWidget.module.css";

const HomeWidget = () => {
  return (
    <div>
      {/*Add the about layout with Carousel on the home dashboard*/}
      <AboutLayout/>

      <div className={Styles.ThinColorBar}/>

      {/*Add the werender layout on the home dashboard*/}
      <WerenderLayout/>

      {/*Add the asset layout on the home dashboard*/}
      <AssetLayout/>

      {/*Add the feature layout on the home dashboard*/}
      <FeatureLayout/>

      {/*Add the solution layout on the home dashboard*/}
      <SolutionLayout/>

      {/*Add the I/O layout on the home dashboard*/}
      <IOLayout/>

      {/*Add the api layout on the home dashboard*/}
      <APILayout/>

      {/*Add the pricing layout on the home dashboard*/}
      <PricingLayout/>
    </div>
  );
};

HomeWidget.propTypes = {};
HomeWidget.defaultProps = {};

export default HomeWidget;
