import {Container} from "react-bootstrap";

import HomeNavbar from "../../components/HomeNavbar/HomeNavbar";
import HomeFooter from "../../components/HomeFooter/HomeFooter";

const TermsPage = (props) => {
  return (
    <div>
      <HomeNavbar/>

        <main className="py-5">
          <Container>
            <h2 className="mb-3">
              Website Terms
            </h2>
            <p className="lead">
              By accessing or using services available on the J Cube Inc website, you acknowledge that you have read,
              understood, and agree to be legally bound by these website Terms of Use, all applicable laws and
              regulations, and agree that you are responsible for compliance with any applicable local laws. If you do
              not agree with any of these terms, you are prohibited from using or accessing our website. You agree that
              you will not: use the website for any fraudulent or unlawful purpose; interfere with or disrupt the
              operation of the website or the servers or networks used to make the website available; or violate any
              requirements, procedures, policies, or regulations of such networks; restrict or inhibit any other person
              from using the website (including by hacking or defacing any portion of the website).
            </p>

            <h6 className="text-uppercase">
              Trademarks
            </h6>
            <p>
              J Cube, the J Cube logo, WeRender, Lightfront, Multiverse, Hyperspace and Maneki are trademarks or registered
              trademarks of J CUBE Inc in Japan and other countries. Other company and product names may be trademarks
              of the respective companies with which they are associated. The materials contained in our website are
              protected by applicable copyright and trademark law. You are prohibited from using any trademarks
              appearing on the website without the prior consent of the rights' holder. You are also prohibited from
              removing any copyright, trademark, or other proprietary rights notice from the website or content, software
              and other materials originating from the website.
            </p>

            <h6 className="text-uppercase">
              Disclaimer
            </h6>
            <p>
              The materials on our website are provided “as is”. We make no warranties, expressed or implied, and hereby
              disclaims and negates all other warranties, including without limitation, implied warranties or conditions
              of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or
              other violation of rights. Further, we do not warrant or make any representations concerning the accuracy,
              likely results, or reliability of the use of the materials on our website or otherwise relating to such
              materials or on any websites linked to our website. The materials appearing on our website could include
              technical, typographical, or photographic errors. We do not warrant that any of the materials on our
              website are accurate, complete, or current. We may make changes to the materials contained on our website
              at any time without notice. Likewise, we do not make any commitment to update the materials.
            </p>

            <h6 className="text-uppercase">
              Consent
            </h6>
            <p>
              We have not reviewed all the websites linked to our website, and we are not responsible for the content
              of any such linked site or for content received from third parties. The inclusion of any link does not
              imply endorsement by us of the site. Use of any such linked website is at the user’s own risk.
            </p>

            <h6 className="text-uppercase">
              Limitation of Liability
            </h6>
            <p>
              In no event shall we or our suppliers be liable for any damages (including, without limitation, damages
              for loss of data or profit, or due to business interruption) arising out of the use or inability to use
              the materials on our website, even if notified orally or in writing of the possibility of such damage.
              Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for
              consequential or incidental damages, these limitations may not apply to you.
            </p>

            <h6 className="text-uppercase">
              Terms of Use
            </h6>
            <p>
              We may revise these Terms of Use for our website at any time without notice. By using this website, you
              agree to be bound by the then current version of these Terms of Use.
            </p>

            <h6 className="text-uppercase">
              Governing Law
            </h6>
            <p>
              Any claim relating to our website shall be governed by the laws of Japan, Yokohama district court.
            </p>
          </Container>
        </main>

      <HomeFooter/>
    </div>
  );
}

TermsPage.propTypes = {};
TermsPage.defaultProps = {};

export default TermsPage;